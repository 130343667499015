import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import SelectInput from "../../../components/inputs/selectinputs";
import TextArea from "../../../components/inputs/textarea";
import Textinput from "../../../components/inputs/textinputs";

const EditGuess = () => {
  return (
    <div>
      <MainHeader text={"Fun N Learn"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          Edit Guess The Word
        </h2>
        <form action="" className="tw-mt-8">
          <div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
            <SelectInput label={"Choose Language"} name={"language"}>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
            </SelectInput>
            <SelectInput label={"Choose Category"} name={"language"}>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
            </SelectInput>
            <SelectInput label={"Choose Sub-Category"} name={"language"}>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
              <option value="english" className="">
                English
              </option>
            </SelectInput>
          </div>
          <div className="tw-mt-8">
            <TextArea label={"Question"} placeholder={"Write question"} />
          </div>
          <div className="tw-mt-6 tw-grid md:tw-grid-cols-2 tw-gap-10">
            <div className="tw-space-y-4">
              <Textinput
                type={"file"}
                label={"Title"}
                name={"option_a"}
                placeholder={"The Nation"}
              />
            </div>
            <div className="tw-space-y-4">
              <Textinput
                type={"text"}
                label={"Right Answer"}
                name={"option_d"}
                placeholder={"Type in option d"}
              />
            </div>
          </div>

          <div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
            <button className="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white">
              Submit
            </button>
          </div>
        </form>
      </MainContainer>
    </div>
  );
};

export default EditGuess;
