import React, { useEffect } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import { useState } from "react";
import Textinput from "../../../components/inputs/textinputs";
import AddBadge from "../../../components/modals/add-badge";
// import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { manageBadges } from "../../../data/Reducers/BadgeReducer";
import Button, { LoadingLoader } from "../../../components/buttons/button";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";

const BadgeSettings = () => {
	const [modal, setModal] = useState(),
		handleClose = () => {
			setModal("");
		};

	let { badges } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(badges?.data);
	}, [badges?.data]);

	useEffect(() => {
		dispatch(manageBadges("get", null));
	}, [dispatch]);

	if (!data && !badges?.data) return <LoadingLoader />;

	return (
		<div>
			<MainHeader text={"Badge Settings"} />
			<MainContainer>
				<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-y-0 tw-spacey-5 tw-justify-between tw-items-center">
					<h2 className="work tw-text-main tw-font-normal tw-text-xl">
						All Badges
					</h2>
					{/* <button
            onClick={() => setModal("add-new-badge")}
            className="tw-h-11 tw-w-40 tw-rounded btngradient tw-text-base tw-font-normal tw-text-white work"
          >
            Add new badge
          </button> */}
				</div>
				<form action="" className="tw-mt-10">
					<div className="tw-space-y-4">
						{data?.docs?.map((item, i) => (
							<MapBadges key={i} item={item} />
						))}
					</div>
					{/* <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-10">
						<button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
							Submit
						</button>
					</div> */}
				</form>
			</MainContainer>
			{modal === "add-new-badge" && <AddBadge handleClose={handleClose} />}
		</div>
	);
};

export default BadgeSettings;

const MapBadges = ({ item }) => {
	let [state, setState] = useState({}),
		inputs = [
			{
				label: "Choose Icon",
				type: "file",
				placeholder: "",
			},
			{
				label: "Description",
				type: "text",
				placeholder: "Description",
				value: item?.description || state?.description || "",
				name: "description",
			},
			{
				label: "Reward",
				type: "tel",
				placeholder: "10",
				value: item?.reward || state?.reward || "",
				name: "reward",
			},
			// {
			// 	label: "Counter",
			// 	type: "text",
			// 	placeholder: "Lorep Ipsum",
			// }
		],
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[image, setImage] = useState(null);

	useEffect(() => {
		if (item) {
			setState({ ...state, ...item });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.reward || !state?.description)
			return toast.info("At least, Reward/Description is required");
		var newState = {};
		if (state?.reward) newState.reward = Number(state?.reward);
		if (state?.description) newState.description = state?.description;
		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image, type: "image" },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
				newState.icon = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}

		await dispatch(manageBadges("put", newState, item?._id || null));
		setLoading(false);
	};

	return (
		<>
			<div className="">
				<h2 className="work tw-capitalize tw-text-main tw-font-normal tw-text-xl">
					<span className="tw-py-3">{item?.name} badge</span>
					{state?.icon?.url && (
						<>
							<img
								src={state?.icon?.url}
								alt=""
								className="tw-h-20 tw-rounded-full"
							/>
						</>
					)}
				</h2>
				<div className="tw-mt-5 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-4">
					{inputs?.map(list => (
						<div className="">
							<Textinput
								label={list.label}
								placeholder={list.placeholder}
								type={list.type}
								onChange={
									list?.type === "file" ? handleChangeImage : textChange
								}
								value={state?.[list?.name]}
								name={list?.name}
							/>
						</div>
					))}
				</div>
				{/* <div className="tw-flex tw-cursor-pointer tw-justify-end tw-mt-4">
					<AiFillDelete size={20} />
				</div> */}
			</div>
			<div className="tw-flex tw-gap-4 tw-justify-end tw-items-center tw-mt-10">
				{/* <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
					Submit
				</button> */}
				<Button
					loading={loading}
					onClick={handleSubmit}
					width="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white"
					title={"Submit"}
				/>
			</div>
		</>
	);
};
