import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
};

export const guessTheWordSlice = createSlice({
	name: "guessTheWord",
	initialState,
	reducers: {
		getSearchGuessTheWord: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetGuessTheWordSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getGuessTheWord: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllGuessTheWord: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		addGuessTheWord: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs ? 1 + state?.all?.totalDocs : 1,
			};
			state.status = "added";
		},
		addGuessTheWordBatch: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [...data, ...state?.data?.docs] : [...data],
				totalDocs: state?.data?.totalDocs
					? data?.length + state?.data?.totalDocs
					: data?.length,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [...data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs
					? data?.length + state?.all?.totalDocs
					: data?.length,
			};
			state.status = "added";
		},
		deleteGuessTheWord: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateGuessTheWord: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		guessTheWordFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutGuessTheWord: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});
export const {
	guessTheWordFail,
	getOrg,
	getGuessTheWord,
	deleteGuessTheWord,
	addGuessTheWord,
	getSearchGuessTheWord,
	getSearch,
	resetGuessTheWordSearch,
	updateGuessTheWord,
	logoutGuessTheWord,
	getAllGuessTheWord,
	addGuessTheWordBatch
} = guessTheWordSlice.actions;

export const manageGuessTheWord = (type, data, id) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/guessTheWord?_populate=category&_populate=subCategory&_populate=image&_populate=language${
					data?.limit ? `&_limit=${data?.limit}` : ""
				}`
			);
			dispatch(getGuessTheWord(res?.data));
			// if (!data) {
			// 	let res2 = await axios.get(
			// 		`/api/v1/guessTheWord?_populate=category&_populate=subCategory&_populate=image&_populate=language&_limit=0`
			// 	);
			// 	dispatch(getAllGuessTheWord(res2?.data));
			// }
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/guessTheWord`, { ...data });
      let res2 = await axios.get(
				`/api/v1/guessTheWord/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language`
			);
			dispatch(addGuessTheWord(res2?.data));
		}
		if (type === "batch") {
			res = await axios.post(`/api/v1/guessTheWord/batch`, { ...data });

			dispatch(addGuessTheWordBatch(res?.data));
		}
		if (type === "put") {
			let newData = data;
			delete newData?._id;
			delete newData?.id;
			res = await axios.put(
				`/api/v1/guessTheWord/${data?._id || data?.id || id}`,
				{
					...newData,
				}
			);
      let res2 = await axios.get(
				`/api/v1/guessTheWord/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language`
			);
			dispatch(updateGuessTheWord(res2?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/guessTheWord/${data?._id}`);
			dispatch(deleteGuessTheWord(data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(guessTheWordFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(guessTheWordFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
