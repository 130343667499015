import React from "react";
import Line from "../../../assets/more - vertical.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StatusModal from "../../modals/status-modal";
import moment from "moment";
import { useSelector } from "react-redux";

const MainTable = ({ data, headers, handleAction, img, admin }) => {
	const [modal, SetModal] = useState(false),
		{ auth } = useSelector(s => s),
		[actionModal, setActionModal] = useState(""),
		navigate = useNavigate(),
		handleClose = () => {
			setActionModal("");
		},
		[active, SetActive] = useState(null);
	const handleModal = i => {
		SetActive(i);
		SetModal(!modal);
	};

	return (
		<div className="tw-overflow-x-auto">
			<table className="tw-table-auto tw-min-w-full">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers?.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data?.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-p-3">
								<img
									src={item.image?.url || img}
									alt=""
									className="tw-h-8 tw-mx-auto"
								/>
							</td>
							{/* <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.id}
							</td> */}
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.profile?.firstName} {item?.profile?.lastName}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.createdAt).format("L")}
								{/* {item?.date_joined} */}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.email}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.phone}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.profile?.points}
							</td>
							<td className="tw-flex tw-justify-center tw-py-2">
								<button
									className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
										item?.status?.toLowerCase() === "active"
											? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
											: "tw-bg-red-400 tw-text-white"
									} `}>
									{item?.status}
								</button>
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										{!admin && (
											<>
												<h6
													onClick={() => setActionModal("status-modal")}
													className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
													edit status
												</h6>
												<h6
													onClick={
														admin
															? () => {}
															: () =>
																	navigate(`/leaderboard/${i}`, { state: item })
													}
													className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
													Leaderboards
												</h6>
												<h6
													onClick={
														admin
															? () => {}
															: () =>
																	navigate(`/activities/${i}`, { state: item })
													}
													className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
													user activity
												</h6>
												<h6
													onClick={
														admin
															? () => {}
															: () =>
																	navigate(`/payments/${i}`, { state: item })
													}
													className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
													user payments
												</h6>
											</>
										)}
										<h6
											onClick={
												admin
													? () => {
															if (auth?.user?.type === "admin") {
															} else {
																navigate(
																	`/${
																		admin ? "admin" : "users"
																	}/user-statistics`,
																	{
																		state: item,
																	}
																);
															}
													  }
													: () =>
															navigate(`/users/user-statistics`, {
																state: item,
															})
											}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											user statistics
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "status-modal" && (
				<StatusModal handleClose={handleClose} />
			)}
		</div>
	);
};

export default MainTable;
