import React from "react";
import { useState } from "react";
import Edit from "../../../assets/edit.svg";

const UserPaymentTable = ({ data, headers }) => {
  const [modal, SetModal] = useState(false),
    [active, SetActive] = useState(null);
  const handleModal = (i) => {
    SetActive(i);
    SetModal(!modal);
  };
  return (
    <div className='tw-overflow-x-auto'>
      <table className="tw-min-w-full tw-table-auto">
        <thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
          <tr>
            {headers.map((item, i) => (
              <th
                key={i}
                className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tw-divide-y-4">
          {data.map((item, i) => (
            <tr
              style={{
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                marginBottom: "10px !important",
              }}
              key={i}
              className="work tw-bg-white tw-mt-8"
            >
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.date}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.payment}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.amount}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.details}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.coins_used}
              </td>
              <td className="tw-flex tw-justify-center tw-py-2">
                <button
                  className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
                    item?.active
                      ? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
                      : "tw-bg-red-400 tw-text-white"
                  } `}
                >
                  {item?.active ? "Active" : "Inactive"}
                </button>
              </td>
              <td className="tw-relative">
                <img
                  onClick={() => handleModal(i)}
                  src={Edit}
                  alt=""
                  className="tw-mx-auto tw-cursor-pointer"
                />
                {active === i && modal && (
                  <div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white k2d">
                    <form action="" className="tw-space-y-2">
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <input
                          type="radio"
                          name="status"
                          id=""
                          value={"Option 2"}
                        />
                        <h6 className="tw-text-graay tw-font-semibold tw-text-sm">
                          Completed
                        </h6>
                      </div>
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <input
                          type="radio"
                          name="status"
                          id=""
                          value={"Option 2"}
                        />
                        <h6 className="tw-text-graay tw-font-semibold tw-text-sm">
                          Pending
                        </h6>
                      </div>
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <input
                          type="radio"
                          name="status"
                          id=""
                          value={"Option 2"}
                        />
                        <h6 className="tw-text-graay tw-font-semibold tw-text-sm">
                          Invalid
                        </h6>
                      </div>
                    </form>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserPaymentTable;
