import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { LoadingLoader } from "../components/buttons/button";
import { useSelector, useDispatch } from "react-redux";
import { manageCategory } from "../data/Reducers/CategoryReducer";
import Subico from "../assets/subico.svg";
import Manage from "../assets/manageico.svg";
import CreateCategory from "../components/modals/create-category";
import ConfirmDelete from "../components/modals/confirm-delete";
import DeletedModal from "../components/modals/deleted-modal";
import StatusModal from "../components/modals/status-modal";
import MainContainer from "../components/maincontainer";
import Line from "../assets/more - vertical.svg";
import { GlobalState } from "../data/Context";

const Category = () => {
	const [modal, setModal] = useState("");
	const handleClose = () => {
		setModal("");
	};

	let { category } = useSelector(s => s);

	const Arr = [
		{
			name: "manage Categories",
			img: Manage,
			value: category?.data?.totalDocs || 0,
			action: () => {},
		},
		{
			name: "Categories",
			img: Manage,
			value:
				category?.all?.docs?.filter(it => !it?.parentCategory)?.length ||
				category?.data?.docs?.filter(it => !it?.parentCategory)?.length,
			action: () => setModal("create-category"),
		},
		{
			name: "sub Categories",
			img: Subico,
			value:
				category?.all?.docs?.filter(it => it?.parentCategory)?.length ||
				category?.data?.docs?.filter(it => it?.parentCategory)?.length,
			action: () => setModal("create-sub-category"),
		},
	];

	let location = useLocation(),
		Headers = [
			"S/N",
			"Image",
			"Name",
			location?.state === "category" ? "" : "Category",
			"Type",
			"Count",
			"Actions",
		];

	let [data, setData] = useState(null),
		[datum, setDatum] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		dispatch(manageCategory("get", null));
	}, [dispatch]);

	useEffect(() => {
		setData(category?.all || category?.data);
	}, [category?.all, category?.data]);

	useEffect(() => {
		if (["updated"]?.includes(category?.status)) {
			setModal("");
			setDatum(null);
		}
	}, [category?.status]);

	if (!data && !category?.data) return <LoadingLoader />;

	return (
		<div>
			<MainContainer>
				<div className="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-5">
					{Arr.map((item, i) => (
						<div
							onClick={item?.action}
							key={i}
							className="myShadow2 tw-cursor-pointer tw-bg-white tw-h-24 tw-gap-3 tw-rounded-md tw-pl-3 tw-flex tw-items-center">
							<img src={item.img} alt={item.name} className="" />
							<div>
								<h2 className="sansation tw-text-main tw-capitalize tw-text-base tw-font-bold">
									{item.name}
								</h2>
								<span className="tw-text-[15px] tw-font-normal tw-text-main sansation">
									{item.value}
								</span>
							</div>
						</div>
					))}
				</div>
				<MainTable
					headers={Headers}
					type={location?.state}
					data={data?.docs}
					setDatum={setDatum}
					datum={datum}
					setModal={setModal}
					list={category?.list}
				/>
				{modal === "create-category" && (
					<CreateCategory handleClose={handleClose} datum={datum} />
				)}
				{modal === "create-sub-category" && (
					<CreateCategory
						handleClose={handleClose}
						type="sub category"
						datum={datum}
					/>
					// <CreateSubCategory handleClose={handleClose} />
				)}
			</MainContainer>
		</div>
	);
};

export default Category;

export const MainTable = ({
	data,
	headers,
	type,
	setModal,
	setDatum,
	datum,
}) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null);
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ category } = useSelector(s => s),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		if (["deleted"]?.includes(category?.status)) {
			setActionModal("deleted-modal");
			setDatum(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category?.status]);

	return (
		<section className="tw-my-10">
			<div>
				<table className="tw-w-full tw-table-auto">
					<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
						<tr>
							{headers
								?.filter(i => i)
								?.map((item, i) => (
									<th
										key={i}
										className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
										{item}
									</th>
								))}
						</tr>
					</thead>
					<tbody className="tw-divide-y-8">
						{data?.map((item, i) => (
							<tr
								style={{
									boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
									marginBottom: "10px !important",
								}}
								key={i}
								className="work tw-bg-white tw-mt-8">
								<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
									{i + 1}
								</td>
								<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
									<img
										src={item.image?.url || Manage}
										alt={item.name}
										className="tw-h-10 tw-w-10 tw-rounded-full"
									/>
								</td>
								<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
									{item?.name}
								</td>

								{!["category"]?.includes(type) && (
									<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
										{data?.find(it => it?._id === item?.parentCategory)?.name}
									</td>
								)}

								<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
									{item?.type}
								</td>
								<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
									{numberWithCommas(
										category?.list?.find(it => it?._id === item?._id)?.count ||
											null
									)}
									{numberWithCommas(
										category?.sublist?.find(it => it?._id === item?._id)
											?.count || null
									)}
								</td>
								<td className="tw-relative">
									<img
										onClick={() => handleModal(i)}
										src={Line}
										alt=""
										className="tw-mx-auto tw-cursor-pointer"
									/>
									{active === i && modal && (
										<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
											<h6
												onClick={() => {
													setDatum(item);
													setModal(
														item?.parentCategory
															? "create-sub-category"
															: "create-category"
													);
												}}
												className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
												edit category
											</h6>
											<h6
												onClick={() => {
													setDatum(item);
													setActionModal("delete-modal");
												}}
												className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
												delete category
											</h6>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{actionModal === "delete-modal" && (
					<ConfirmDelete
						text={"Category"}
						loading={loading}
						handleClose={handleCloseModal}
						handleNext={async e => {
							e?.preventDefault();
							if (!datum) return;
							setLoading(true);
							await dispatch(manageCategory("delete", datum));
							setLoading(false);
						}}
					/>
				)}
				{actionModal === "deleted-modal" && (
					<DeletedModal text={"Category"} handleClose={handleCloseModal} />
				)}
				{actionModal === "status-modal" && (
					<StatusModal handleClose={handleCloseModal} />
				)}
			</div>
		</section>
	);
};
