import React from "react";
import Line from "../../../assets/more - vertical.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../../../components/modals/confirm-delete";
import DeletedModal from "../../../components/modals/deleted-modal";
import StatusModal from "../../modals/status-modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { manageContestQuestions } from "../../../data/Reducers/ContestReducer";

const ContestTable = ({ data, headers, handleAction }) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null),
		navigate = useNavigate();
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	let [datum, setDatum] = useState(null);

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ contest } = useSelector(s => s);

	useEffect(() => {
		if (["deleted"]?.includes(contest?.status)) {
			setActionModal("deleted-modal");
			setDatum(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contest?.status]);

	return (
		<div>
			<table className="tw-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.name}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.startDate)
									.subtract(1, "hours")
									.format("DD/MM/YYYY hh:mm A")}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.endDate)
									.subtract(1, "hours")
									.format("DD/MM/YYYY hh:mm A")}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.entryFee}
							</td>

							<td className="tw-flex tw-justify-center tw-py-2">
								<button
									className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
										item?.active
											? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
											: "tw-bg-red-400 tw-text-white"
									} `}>
									{item?.active ? "Active" : "Inactive"}
								</button>
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.topUsers}
							</td>
							<td className="tw-flex tw-justify-center tw-py-2">
								{item?.winnersPrize?.toString()}
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6
											onClick={() =>
												navigate("/contest/create-contest", { state: item })
											}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											edit contest
										</h6>
										{/* <h6
											onClick={() => setActionModal("status-modal")}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											edit status
										</h6>
										<h6
											onClick={() => navigate("/contest/distribute-prize")}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											distribute price
										</h6>
										<h6
											onClick={() => navigate(`/contest/${i}`)}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											view users
										</h6> */}
										<h6
											onClick={() => {
												setDatum(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete contest
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Contest Type"}
					loading={loading}
					handleClose={handleCloseModal}
					handleNext={async e => {
						e?.preventDefault();
						if (!datum) return;
						setLoading(true);
						await dispatch(manageContestQuestions("delete", datum, "types"));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Contest Type"} handleClose={handleCloseModal} />
			)}
			{actionModal === "status-modal" && (
				<StatusModal handleClose={handleCloseModal} />
			)}
		</div>
	);
};

export default ContestTable;
