import React, { useState, useEffect } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import SelectInput from "../../../components/inputs/selectinputs";
import TextArea from "../../../components/inputs/textarea";
import Textinput from "../../../components/inputs/textinputs";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { manageQuizZone } from "../../../data/Reducers/QuizZoneReducer";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import { manageDailyQuiz } from "../../../data/Reducers/DailyQuizReducer";
import { manageAudioQuiz } from "../../../data/Reducers/AudioQuizReducer";
import { manageContestQuestions } from "../../../data/Reducers/ContestReducer";
import { manageFunAndLearnQuestion } from "../../../data/Reducers/FunNLearnReducer";
import { manageMathQuiz } from "../../../data/Reducers/MathQuizReducer";
import { manageGuessTheWord } from "../../../data/Reducers/GuessTheWordReducer";
import { manageExamQuestion } from "../../../data/Reducers/ExamReducer";
import { manageQuestionBank } from "../../../data/Reducers/QuestionBankReducer";
// import ModalBtn from "../../../components/buttons/modalbtn";

const DailyQuestion = () => <CreateQuestions type="quizZone" />;

export const CreateQuestions = ({ type, title, subTitle }) => {
	let {
			category,
			quizZone,
			dailyQuiz,
			audioQuiz,
			funAndLearn,
			contest,
			guessTheWord,
			mathQuiz,
			language,
			exam,
			questions,
		} = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {
			name: "",
			answerDescription: "",
		},
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		{ page } = useParams(),
		[image, setImage] = useState(null),
		[updater, setUpdater] = useState(null),
		location = useLocation();

	useEffect(() => {
		if (location?.state) {
			setUpdater(location?.state);
			let newD = {},
				st = location?.state;
console.log({ st });
if (st?.question) newD.question = st?.question;
if (st?.answer) newD.answer = st?.answer;
if (st?.language) newD.language = st?.language?._id || st?.language;
if (st?.category) newD.category = st?.category?._id || st?.category;
if (st?.subCategory) newD.subCategory = st?.subCategory?._id || st?.subCategory;
if (st?.level) newD.level = Number(st?.level);
newD.answerDescription = st?.answerDescription;
if (st?.typeID) newD.typeID = st?.typeID?._id || st?.typeID;
newD.right_answer =
	st?.correctOptionIndex === 4
		? "option_d"
		: st?.correctOptionIndex === 3
		? "option_c"
		: st?.correctOptionIndex === 2
		? "option_b"
		: "option_a";
if (st?.options?.[0])
	newD.option_a = st?.options?.[0]?.value?.toString() || st?.options?.[0];
if (st?.options?.[1])
	newD.option_b = st?.options?.[1]?.value?.toString() || st?.options?.[1];
if (st?.options?.[2])
	newD.option_c = st?.options?.[2]?.value?.toString() || st?.options?.[2];
if (st?.options?.[3])
	newD.option_d = st?.options?.[3]?.value?.toString() || st?.options?.[3];
			if (st?.options?.length === 2) newD.question_type = "check";
			setState({ ...state, ...newD });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state]);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes(type === "audioQuiz" ? "audio" : "image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.question && !image) return toast.info("Question is required");
		if (type !== "guessTheWord") {
			if (
				!state?.option_a ||
				!state?.option_b ||
				(state?.question_type !== "check" && !state?.option_c) ||
				(state?.question_type !== "check" && !state?.option_d)
			)
				return toast.info("All options are required");

			if (!state?.right_answer) return toast.info("Correct is required");
		}
		if (type === "guessTheWord") {
			if (!state?.answer) return toast.info("Answer is required");
		}
		var newState = {};
		if (state?.question) newState.question = state?.question;
		if (state?.language) newState.language = state?.language;
		if (state?.category) newState.category = state?.category;
		if (state?.subCategory) newState.subCategory = state?.subCategory;
		if (state?.level) newState.level = Number(state?.level);
		if (!["exam"]?.includes(type))
			if (state?.answerDescription)
				newState.answerDescription = state?.answerDescription;
		if (["guessTheWord"]?.includes(type)) {
			newState.answer = state?.answer?.toLowerCase();
			newState.optionType = "single";
		}
		if (["funAndLearn", "contest", "exam"]?.includes(type))
			if (state?.typeID) newState.typeID = state?.typeID;
		// if (type === "contest") newState.contestID = state?.typeID;
		// else
		// if (["exam"]?.includes(type))
		// if (state?.examID) newState.typeID = state?.examID;

		if (!["guessTheWord"]?.includes(type)) {
			let newOptions = [];
			newOptions?.push({ value: state?.option_a });
			newOptions?.push({ value: state?.option_b });
			if (state?.question_type !== "check") {
				newOptions?.push({ value: state?.option_c });
				newOptions?.push({ value: state?.option_d });
			}
			newState.optionType =
				state?.question_type === "check" ? "boolean" : "multiple";
			newState.options = newOptions;
			newState.correctOptionIndex =
				state?.right_answer === "option_d"
					? 4
					: state?.right_answer === "option_c"
					? 3
					: state?.right_answer === "option_b"
					? 2
					: 1;
		}
		// console.log({ state, newState });
		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image, type: type !== "audioQuiz" ? "image" : "audio" },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
				if (type === "audioQuiz")
					newState.audioFile = resImg?.data?.data?.filesId?.files?.[0];
				if (type !== "audioQuiz")
					newState.image = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}

		if (type === "quizZone")
			await dispatch(
				manageQuizZone(updater ? "put" : "post", newState, updater?._id || null)
			);
		if (type === "dailyQuiz")
			await dispatch(
				manageDailyQuiz(
					updater ? "put" : "post",
					newState,
					updater?._id || null
				)
			);
		if (type === "audioQuiz")
			await dispatch(
				manageAudioQuiz(
					updater ? "put" : "post",
					newState,
					updater?._id || null
				)
			);
		if (type === "contest")
			await dispatch(
				manageContestQuestions(
					updater ? "put" : "post",
					newState,
					null,
					updater?._id || null
				)
			);
		if (type === "exam")
			await dispatch(
				manageExamQuestion(
					updater ? "put" : "post",
					newState,
					null,
					updater?._id || null
				)
			);
		if (type === "funAndLearn")
			await dispatch(
				manageFunAndLearnQuestion(
					updater ? "put" : "post",
					newState,
					null,
					updater?._id || null
				)
			);
		if (type === "mathQuiz")
			await dispatch(
				manageMathQuiz(updater ? "put" : "post", newState, updater?._id || null)
			);
		if (type === "guessTheWord")
			await dispatch(
				manageGuessTheWord(
					updater ? "put" : "post",
					newState,
					updater?._id || null
				)
			);
		if (type === "questions")
			await dispatch(
				manageQuestionBank(
					updater ? "put" : "post",
					newState,
					updater?._id || null
				)
			);
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		setImage(null);
		navigate(`/${page}`);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(quizZone?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(dailyQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(audioQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(contest?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(funAndLearn?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(mathQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(guessTheWord?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(exam?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(questions?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		quizZone?.status,
		dailyQuiz?.status,
		audioQuiz?.status,
		contest?.status,
		funAndLearn?.status,
		mathQuiz?.status,
		guessTheWord?.status,
		exam?.status,
		questions?.status,
	]);
	// console.log({ state, location: location?.state });
	return (
		<div>
			<MainHeader text={title || "Create Questions"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					{subTitle || "Create Questions"}
				</h2>
				<form action="" className="tw-mt-8">
					<h3 className="tw-text-lg tw-text-main tw-font-normal segoe">
						{updater ? "Update" : "Create"} Questions
					</h3>
					{!["exam", "questions"]?.includes(type) && (
						<div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
							<SelectInput
								label={"Choose Language"}
								onChange={textChange}
								value={state?.language?._id || state?.language}
								name={"language"}>
								<option value="" className="">
									Select Language
								</option>
								{language?.all?.docs?.map((it, i) => (
									<option key={i} value={it?._id}>
										{it?.name}
									</option>
								))}
							</SelectInput>
							<SelectInput
								value={state?.category?._id || state?.category}
								name="category"
								onChange={textChange}
								label={"Choose a Category"}>
								{" "}
								<option value="">Select a Category</option>
								{category?.all?.docs
									?.filter(it => !it?.parentCategory && it?.type === type)
									?.map((it, i) => (
										<option key={i} value={it?._id}>
											{it?.name}
										</option>
									))}
							</SelectInput>
							{state?.category && (
								<SelectInput
									value={state?.subCategory?._id || state?.subCategory}
									name="subCategory"
									onChange={textChange}
									label={"Choose Sub Category"}>
									{" "}
									<option value="">Select a Sub Category</option>
									{category?.all?.docs
										?.filter(
											it =>
												it?.parentCategory &&
												state?.category === it?.parentCategory &&
												it?.type === type
										)
										?.map((it, i) => (
											<option key={i} value={it?._id}>
												{it?.name}
											</option>
										))}
								</SelectInput>
							)}
						</div>
					)}
					<div className="tw-mt-8">
						<TextArea
							label={"Question"}
							placeholder={"Write question"}
							name="question"
							onChange={textChange}
							value={state?.question}
						/>
					</div>
					<div className="tw-mt-6 tw-grid md:tw-grid-cols-2 tw-gap-10">
						<div className="tw-space-y-4">
							<Textinput
								type={"file"}
								label={
									type === "audioQuiz"
										? "Choose Audio (mp3)"
										: "Choose image (jpeg, png)"
								}
								name={"image"}
								accept={type === "audioQuiz" ? "audio/*" : "image/*"}
								onChange={handleChangeImage}
							/>
							{!["guessTheWord"]?.includes(type) ? (
								<>
									<Textinput
										type={"text"}
										label={"Option A"}
										name={"option_a"}
										placeholder={"Type in option a"}
										value={state?.option_a}
										onChange={textChange}
									/>
									{state?.question_type !== "check" && (
										<Textinput
											type={"text"}
											label={"Option C"}
											name={"option_c"}
											placeholder={"Type in option A"}
											value={state?.option_c}
											onChange={textChange}
										/>
									)}
									{/* <Textinput
								type={"text"}
								label={"Right Answer"}
								name={"right_answer"}
								placeholder={"Type in correct answer"}
							/> */}
									<SelectInput
										label={"Right Answer"}
										name={"right_answer"}
										value={state?.right_answer}
										onChange={textChange}>
										<option value="" className="">
											Select Right Answer
										</option>
										<option value="option_a" className="">
											Option A
										</option>
										<option value="option_b" className="">
											Option B
										</option>
										{state?.question_type !== "check" && (
											<>
												<option value="option_c" className="">
													Option C
												</option>
												<option value="option_d" className="">
													Option D
												</option>
											</>
										)}
									</SelectInput>
								</>
							) : null}
						</div>
						{!["guessTheWord"]?.includes(type) ? (
							<div className="tw-space-y-4">
								<SelectInput
									label={"Choose Question Type"}
									name={"question_type"}
									value={state?.question_type}
									onChange={textChange}>
									<option value="" className="">
										Select Options Type
									</option>
									<option value="options" className="">
										Options
									</option>
									<option value="check" className="">
										True/False
									</option>
								</SelectInput>
								<Textinput
									type={"text"}
									label={"Option B"}
									name={"option_b"}
									placeholder={"Type in option b"}
									value={state?.option_b}
									onChange={textChange}
								/>
								{state?.question_type !== "check" && (
									<Textinput
										type={"text"}
										label={"Option D"}
										name={"option_d"}
										placeholder={"Type in option d"}
										value={state?.option_d}
										onChange={textChange}
									/>
								)}
								<Textinput
									type={"tel"}
									label={"Level"}
									name={"level"}
									placeholder={"Type in level"}
									value={state?.level}
									onChange={textChange}
								/>
								{["funAndLearn"]?.includes(type) && (
									<SelectInput
										label={"Choose Type"}
										onChange={textChange}
										value={state?.typeID}
										name={"typeID"}>
										<option value="" className="">
											Select Fun and Learn Type
										</option>
										{funAndLearn?.allTypes?.docs?.map((it, i) => (
											<option key={i} value={it?._id}>
												{it?.title}
											</option>
										))}
									</SelectInput>
								)}
								{["contest"]?.includes(type) && (
									<SelectInput
										label={"Choose Type"}
										onChange={textChange}
										value={state?.typeID}
										name={"typeID"}>
										<option value="" className="">
											Select Contest Type
										</option>
										{contest?.allTypes?.docs?.map((it, i) => (
											<option key={i} value={it?._id}>
												{it?.name}
											</option>
										))}
									</SelectInput>
								)}
								{["exam"]?.includes(type) && (
									<SelectInput
										label={"Choose Type"}
										onChange={textChange}
										value={state?.typeID}
										name={"typeID"}>
										<option value="" className="">
											Select Exam Type
										</option>
										{exam?.allTypes?.docs?.map((it, i) => (
											<option key={i} value={it?._id}>
												{it?.title}
											</option>
										))}
									</SelectInput>
								)}
							</div>
						) : ["audioQuiz"]?.includes(type) ? (
							<Textinput
								type={"url"}
								label={"Audio Link"}
								name={"audioUrl"}
								placeholder={"Audio Link"}
								value={state?.audioUrl}
								onChange={textChange}
							/>
						) : (
							<Textinput
								type={"text"}
								label={"Right Answer"}
								name={"answer"}
								placeholder={"Type in option d"}
								value={state?.answer}
								onChange={textChange}
							/>
						)}
					</div>
					{!["exam"]?.includes(type) && (
						<div className="tw-mt-8">
							<TextArea
								label={"Answer Description"}
								placeholder={"Answer Description"}
								name="answerDescription"
								onChange={textChange}
								value={state?.answerDescription}
							/>
						</div>
					)}
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<Button
							loading={loading}
							onClick={handleSubmit}
							width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
							title={updater ? "Update" : "Submit"}
						/>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default DailyQuestion;
