import { CreateQuestions } from "../../quiz-zone/create-questions";

const CreateFunQuestion = () => (
	<CreateQuestions
		type="funAndLearn"
		title={"Fun And Learn"}
		subTitle="Create Fun And Learn"
	/>
);

export default CreateFunQuestion;
