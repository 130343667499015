import React, { useEffect, useState } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Textinput from "../../../components/inputs/textinputs";
import Button, { LoadingLoader } from "../../../components/buttons/button";
import { useDispatch, useSelector } from "react-redux";
import { manageSettings } from "../../../data/Reducers/SettingsReducer";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";

const IconSettings = () => {
	// const Arr = [
	// 	{
	// 		label: "Contest Icon",
	// 		name: "contest_icon",
	// 	},
	// 	{
	// 		label: "Exam Icon",
	// 		name: "exam_icon",
	// 	},
	// 	{
	// 		label: "Fun N Learn Icon",
	// 		name: "Fun N Learn_icon",
	// 	},
	// 	{
	// 		label: "Guess The Word Icon",
	// 		name: "Guess The Word_icon",
	// 	},
	// 	{
	// 		label: "Contest Icon",
	// 		name: "contest_icon",
	// 	},
	// 	{
	// 		label: "Contest Icon",
	// 		name: "contest_icon",
	// 	},
	// ];

	let { settings } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(settings?.quizSettings);
	}, [settings?.quizSettings]);

	useEffect(() => {
		dispatch(manageSettings("get", null));
	}, [dispatch]);

	if (!data && !settings?.quizSettings) return <LoadingLoader />;

	return (
		<div>
			<MainHeader text={"Icons"} />
			<MainContainer>
				<h2 className="work tw-text-main tw-font-normal tw-text-xl">
					Manage Icons
				</h2>
				<form action="" className="tw-mt-10">
					<div className="tw-grid lg:tw-grid-cols-3 tw-gap-5">
						{/* {Arr.map((item, i) => (
							<div key={i} className="">
								<Textinput label={item.label} type={"file"} name={item.name} />
							</div>
						))} */}
						{data?.docs?.map((item, i) => (
							<div key={i} className="">
								<MapQuizIcons
									item={item}
									label={item.label}
									type={"file"}
									name={item.name}
								/>
							</div>
						))}
					</div>
					<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-10">
						<button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
							Submit
						</button>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default IconSettings;

export const MapQuizIcons = ({
	item,
	caseType = "image",
	category = "quizSettings",
	mainKey = "",
}) => {
	let dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[image, setImage] = useState(null),
		[state, setState] = useState("");

	useEffect(() => {
		if (item) {
			if (caseType === "quizTime") setState(item?.quizTime);
			if (caseType === "entryPoint") setState(item?.entryPoint);
			if (mainKey) setState(item?.[mainKey]);
		}
	}, [caseType, item, mainKey]);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (caseType === "image") if (!image) return toast.info("Icon required");
		if (["quizTime", "entryPoint"]?.includes(caseType))
			if (!state) return toast.info(`${caseType} required`);
		var newState = {};
		if (["quizTime", "entryPoint"]?.includes(caseType)) {
			if (state) newState[mainKey || caseType] = Number(state);
		}
		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image, type: "image" },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
				newState.icon = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}

		await dispatch(
			manageSettings("put", newState, category, item?._id || null)
		);
		setLoading(false);
	};

	return (
		<>
			{caseType === "image" && (
				<div className="">
					<h2 className="work tw-capitalize tw-text-main tw-font-normal tw-text-xl">
						<span className="tw-py-3">
							{item?.name?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")} icon
						</span>
						{item?.icon?.url || image ? (
							<>
								<img
									src={image ? URL.createObjectURL(image) : item?.icon?.url}
									alt=""
									className="tw-h-20 tw-rounded-full"
								/>
							</>
						) : null}
					</h2>
					<Textinput
						// label={item?.name}
						placeholder={item?.name}
						type={"file"}
						onChange={handleChangeImage}
						// value={image}
						name={"file"}
						accept="image/*"
					/>
					{/* <div className="tw-flex tw-cursor-pointer tw-justify-end tw-mt-4">
					<AiFillDelete size={20} />
				</div> */}
				</div>
			)}
			{["quizTime", "entryPoint"]?.includes(caseType) && (
				<div>
					<div className="">
						<Textinput
							label={`${
								item?.name?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || ""
							} ${
								!mainKey
									? caseType?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || ""
									: ""
							}`}
							// placeholder={item.placeholder}
							type={"tel"}
							value={state}
							onChange={e => setState(e?.target?.value)}
						/>
					</div>
				</div>
			)}
			<div className="tw-flex tw-gap-4 tw-justify-end tw-items-center tw-mt-10">
				{/* <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
					Submit
				</button> */}
				<Button
					loading={loading}
					onClick={handleSubmit}
					width="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white"
					title={"Submit"}
				/>
			</div>
		</>
	);
};
