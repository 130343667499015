import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
};

export const languageSlice = createSlice({
	name: "language",
	initialState,
	reducers: {
		getSearchLangauge: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetLangaugeSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getLangauge: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllLangauge: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		addLangauge: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.status = "added";
		},
		deleteLangauge: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateLangauge: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		languageFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutLangauge: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});
export const {
	languageFail,
	getOrg,
	getLangauge,
	deleteLangauge,
	addLangauge,
	getSearchLangauge,
	getSearch,
	resetLangaugeSearch,
	updateLangauge,
	logoutLangauge,
	getAllLangauge,
} = languageSlice.actions;

export const manageLanguage = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/language${data?.limit ? `?_limit=${data?.limit}` : ""}`
			);
			dispatch(getLangauge(res?.data));
			if (!data) {
				let res2 = await axios.get(`/api/v1/language?_limit=0`);
				dispatch(getAllLangauge(res2?.data));
			}
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/language`, { ...data });

			dispatch(addLangauge(res?.data));
		}
		if (type === "put") {
			res = await axios.put(`/api/v1/language/${data?._id}`, {
				...data,
			});
			dispatch(updateLangauge(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/language/${data?._id}`);
			dispatch(deleteLangauge(data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(languageFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(languageFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
