import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { manageSettings } from "../../data/Reducers/SettingsReducer";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import TextArea from "../../components/inputs/textarea";
import Button from "../../components/buttons/button";

const AboutUs = () => <SettingsUpdate type={"about"} title="About Us" />;

export default AboutUs;

export const SettingsUpdate = ({ type, title }) => {
	let { settings } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {},
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		};

	useEffect(() => {
		if (settings?.settings?.docs?.length > 0) {
			let d = settings?.settings?.docs?.[0];

			if (d?.[type]) setState({ ...state, [type]: d?.[type] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings?.settings, type]);

	const handleSubmit = async e => {
		e?.preventDefault();
		console.log({ state });
		if (!state?.[type]) return toast.info(`${title} is required`);

		let newState = {};

		if (state?.[type]) newState[type] = state?.[type];
		setLoading(true);
		await dispatch(manageSettings("put", newState, "staticSettings"));
		setLoading(false);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(settings?.status)) {
			setState(init);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settings?.status]);
	// console.log({ state, location: location?.state });
	return (
		<div>
			<MainHeader text={title || "Create Questions"} />
			<MainContainer>
				<form action="" className="tw-mt-8">
					<div className="tw-mt-8">
						<TextArea
							label={title}
							placeholder={title}
							name={type}
							onChange={textChange}
							value={state?.[type]}
							setState={setState}
							usecase="editor"
							state={state}
						/>
					</div>

					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<Button
							loading={loading}
							onClick={handleSubmit}
							width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
							title={"Submit"}
						/>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};
