import React, { useEffect, useState } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const UserStatistics = () => {
	let [data, setData] = useState(),
		{ state } = useLocation(),
		navigate = useNavigate();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	useEffect(() => {
		if (state?._id) {
			let getStat = async () => {
				try {
					let res5 = await axios.get(`/api/v1/userStatistics/${state?._id}`);
					setData(res5?.data?.data);
					console.log({ userStatistics: res5?.data });
				} catch (error) {}
			};
			getStat();
		}
	}, [state]);

	const stats = [
		{
			title: "Question Answered",
			value: data?.totalQuestionsAnswered || "",
		},
		{
			title: "correct Answers",
			value: data?.totalCorrectQuestions || "",
		},
		{
			title: "Strong category",
			value:
				data?.strongestCategory?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || "",
		},
		{
			title: "weak category",
			value:
				data?.weakestCategory?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || "",
		},
		{
			title: "highest score",
			value: data?.highestScore || "",
		},
		{
			title: "lowest score",
			value: data?.lowestScore || "",
		},
		{
			title: "victories",
			value: data?.totalVictories || "",
		},
		{
			title: "losses",
			value: data?.totalLosses || "",
		},
	];
	return (
		<div>
			<MainHeader text={"User Statistics"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					{state?.profile?.firstName} {state?.profile?.lastName}'s Statistics
				</h2>
				<div className="tw-mt-10 tw-w-full tw-bg-white tw-rounded-lg tw-p-8">
					<h2 className="tw-text-xl tw-text-main tw-font-normal work">
						General Statistics
					</h2>
					<div className="tw-mt-8 tw-gap-6 md:tw-w-1/2 tw-grid md:tw-grid-cols-2">
						{stats.map((item, i) => (
							<div key={i} className="">
								<h4 className="tw-text-lg tw-capitalize segoe tw-font-normal tw-text-main">
									{item.title}:{" "}
									<span className="tw-text-lg tw-capitalize tw-font-normal textColor segoe">
										{item.value}
									</span>
								</h4>
							</div>
						))}
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default UserStatistics;
