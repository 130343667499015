import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";

const AboutUs = () => {
  return (
    <div>
      <MainHeader text={"Privacy Policy Settings"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          Manage Privacy & Policy Text
        </h2>

        <form action="" className="tw-mt-16">
          <textarea
            name=""
            className="tw-h-44 tw-pl-10 tw-pt-10 tw-w-5/6 tw-mx-auto tw-bg-white myShadow2 tw-border"
            id=""
            placeholder="Write About Us"
            cols="30"
            rows="10"
          ></textarea>
          <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
            <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
              Submit
            </button>
          </div>
        </form>
      </MainContainer>
    </div>
  );
};

export default AboutUs;
