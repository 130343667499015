import React, { useState, useEffect } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import SelectInput from "../../components/inputs/selectinputs";
import TextArea from "../../components/inputs/textarea";
import Textinput from "../../components/inputs/textinputs";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/buttons/button";
import { useNavigate, useLocation } from "react-router-dom";
import { manageQuizZone } from "../../data/Reducers/QuizZoneReducer";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { manageDailyQuiz } from "../../data/Reducers/DailyQuizReducer";
import { manageAudioQuiz } from "../../data/Reducers/AudioQuizReducer";
import { manageContestQuestions } from "../../data/Reducers/ContestReducer";
import { manageFunAndLearnQuestion } from "../../data/Reducers/FunNLearnReducer";
import { manageMathQuiz } from "../../data/Reducers/MathQuizReducer";
import { manageGuessTheWord } from "../../data/Reducers/GuessTheWordReducer";
import { manageExamQuestion } from "../../data/Reducers/ExamReducer";
import { manageQuestionBank } from "../../data/Reducers/QuestionBankReducer";
import Extractor from "../../data/useExtractor";
import { ModalContainer } from "../../components/modals/modalcontainer";
import { BiDownload, BiTrash } from "react-icons/bi";
// import ModalBtn from "../../components/buttons/modalbtn";
import RawSample from "../../assets/Bulk Upload Sample.csv";

const BulkUpload = () => <CreateBulkQuestions />;

export const CreateBulkQuestions = () => {
	let {
			category,
			quizZone,
			dailyQuiz,
			audioQuiz,
			funAndLearn,
			contest,
			guessTheWord,
			mathQuiz,
			language,
			exam,
			questions,
		} = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {
			name: "",
			answerDescription: "",
		},
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		location = useLocation()?.state,
		type = location?.type,
		subTitle = location?.subTitle,
		[pres, setPres] = useState([]),
		[itemForm, setItemForm] = useState([]),
		[bulkUpload, setBulkUpload] = useState(false),
		toggleUploadModal = () => {
			setPres([]);
			setBulkUpload(!bulkUpload);
		};

	let handleChangeImage = (e, index, field) => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes(type === "audioQuiz" ? "audio" : "image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			// setImage(file);
			setItemForm(prevRows => {
				const newRows = [...prevRows];
				newRows[index][field] = file;
				return newRows;
			});
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();

		let newQuestions = [],
			errArr = [],
			finalQuestions = [];
		for (let i = 0; i < itemForm?.length; i++) {
			let element = itemForm?.[i];
			if (type !== "audio")
				if (!element?.question && !element?.image)
					errArr?.push(`Question ${i + 1} is required`);
			if (type === "audio")
				if (!element?.question && !element?.audio)
					errArr?.push(`Question ${i + 1} is required`);

			if (type !== "guessTheWord") {
				if (
					!element?.option_a ||
					!element?.option_b ||
					(element?.question_type !== "check" && !element?.option_c) ||
					(element?.question_type !== "check" && !element?.option_d)
				)
					errArr?.push(`Question ${i + 1} all options are required`);

				if (!element?.right_answer)
					errArr?.push(`Question ${i + 1} right answer is required`);
			}
			if (type === "guessTheWord") {
				if (!element?.answer)
					errArr?.push(`Question ${i + 1} answer is required`);
			}
		}
		if (errArr?.length > 0) return errArr?.forEach(it => toast.info(it));

		for (let i = 0; i < itemForm?.length; i++) {
			let element = itemForm?.[i];

			let newState = {};
			if (element?.question) newState.question = element?.question;
			if (state?.language) newState.language = state?.language;
			if (state?.category) newState.category = state?.category;
			if (state?.subCategory) newState.subCategory = state?.subCategory;
			if (element?.level) newState.level = Number(element?.level);
			if (!["exam"]?.includes(type))
				if (element?.answerDescription)
					newState.answerDescription = element?.answerDescription;
			if (["guessTheWord"]?.includes(type)) {
				newState.answer = element?.answer?.toLowerCase();
				newState.optionType = "single";
			}
			if (["funAndLearn", "contest", "exam"]?.includes(type))
				if (element?.typeID) newState.typeID = element?.typeID;
			// if (type === "contest") newState.contestID = state?.typeID;
			// else
			// if (["exam"]?.includes(type))
			// if (state?.examID) newState.typeID = state?.examID;

			if (!["guessTheWord"]?.includes(type)) {
				let newOptions = [];
				newOptions?.push({ value: element?.option_a });
				newOptions?.push({ value: element?.option_b });
				if (element?.question_type !== "check") {
					newOptions?.push({ value: element?.option_c });
					newOptions?.push({ value: element?.option_d });
				}
				newState.optionType =
					element?.question_type === "check" ? "boolean" : "multiple";
				newState.options = newOptions;
				newState.correctOptionIndex =
					element?.right_answer === "option_d"
						? 4
						: element?.right_answer === "option_c"
						? 3
						: element?.right_answer === "option_b"
						? 2
						: 1;

				if (type !== "audioQuiz" && element?.image)
					newState.image = element?.image;
				if (type === "audioQuiz" && element?.audio)
					newState.audioFile = element?.audio;

				newQuestions?.push(newState);
			}
		}
		// console.log({ state, newState });
		setLoading(true);
		for (let n = 0; n < newQuestions?.length; n++) {
			let element = newQuestions?.[n],
				newImage;
			if (type === "audioQuiz")
				if (element?.audioFile) newImage = element?.audioFile;
			if (type !== "audioQuiz") if (element?.image) newImage = element?.image;

			if (newImage) {
				try {
					let resImg = await axios.post(
						`/api/v1/file`,
						{ image: newImage, type: type !== "audioQuiz" ? "image" : "audio" },
						{
							headers: {
								"Content-Type": "multipart/form-data",
							},
						}
					);
					if (type === "audioQuiz")
						element.audioFile = resImg?.data?.data?.filesId?.files?.[0];
					if (type !== "audioQuiz")
						element.image = resImg?.data?.data?.filesId?.files?.[0];
					finalQuestions?.push(element);
				} catch (err) {
					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);
					for (let f = 0; f < finalQuestions.length; f++) {
						const element = finalQuestions[f];

						if (element?.image || element?.audioFile) {
							await axios.delete(
								`/api/v1/file/${element?.image || element?.audioFile}`,
								{
									headers: {
										"Content-Type": "multipart/form-data",
									},
								}
							);
						}
					}

					let error = err.response?.data?.error;
					if (error) {
						setLoading(false);
						return dispatch(
							returnErrors({ error, status: err?.response?.status })
						);
					} else {
						setLoading(false);
						return toast.error(err?.response?.data?.message);
					}
				}
			}
		}

		if (type === "quizZone")
			await dispatch(manageQuizZone("batch", { questions: finalQuestions }));
		if (type === "dailyQuiz")
			await dispatch(manageDailyQuiz("batch", { questions: finalQuestions }));
		if (type === "audioQuiz")
			await dispatch(manageAudioQuiz("batch", { questions: finalQuestions }));
		if (type === "contest")
			await dispatch(
				manageContestQuestions("batch", { questions: finalQuestions }, null)
			);
		if (type === "exam")
			await dispatch(
				manageExamQuestion("batch", { questions: finalQuestions }, null)
			);
		if (type === "funAndLearn")
			await dispatch(
				manageFunAndLearnQuestion("batch", { questions: finalQuestions }, null)
			);
		if (type === "mathQuiz")
			await dispatch(manageMathQuiz("batch", { questions: finalQuestions }));
		if (type === "guessTheWord")
			await dispatch(
				manageGuessTheWord("batch", { questions: finalQuestions })
			);
		if (type === "questions")
			await dispatch(
				manageQuestionBank("batch", { questions: finalQuestions })
			);
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		setItemForm([]);
		setPres([]);
		navigate(-1);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(quizZone?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(dailyQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(audioQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(contest?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(funAndLearn?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(mathQuiz?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(guessTheWord?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(exam?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(questions?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		quizZone?.status,
		dailyQuiz?.status,
		audioQuiz?.status,
		contest?.status,
		funAndLearn?.status,
		mathQuiz?.status,
		guessTheWord?.status,
		exam?.status,
		questions?.status,
	]);

	const onSubmit2 = async () => {
		// console.log({ data });
		if (pres?.length === 0) return toast.info("Bulk Upload' details required");
		try {
			let newItemForm = pres;
			let newPres = [];
			for (let p = 0; p < newItemForm?.length; p++) {
				const element = newItemForm?.[p];
				newPres?.push(toCamel(element));
			}
			let newItem = [];
			for (let n = 0; n < newPres?.length; n++) {
				const element = newPres?.[n];
				newItem = [...newItem, element];
			}
			setItemForm([...itemForm, ...newItem]);
			console.log({ newItem });
			// setPres(null);
			toggleUploadModal();
			// navigate(`?step=${route}`);
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						err?.message
				);
			}
		}
	};

	const handleInputChangeForMutipleItem = (event, index, field) => {
		const { value } = event.target;
		let itemValue = value;

		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleDeleteRowForMutipleItem = index => {
		setItemForm(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};

	// console.log({ state, location: location?.state });
	return (
		<div>
			<MainHeader text={"Bulk Questions"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					{subTitle || "Create Questions"}
				</h2>
				<div className="tw-flex tw-tems-center tw-justify-end tw-gap-4">
					<Button
						buttonType={"secondary"}
						title={"Upload"}
						css={"tw-h-8"}
						onClick={() => toggleUploadModal()}
					/>
				</div>
				<form action="" className="tw-mt-8">
					<h3 className="tw-text-lg tw-text-main tw-font-normal segoe">
						{"Create"} Questions
					</h3>
					{!["exam", "questions"]?.includes(type) && (
						<div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
							<SelectInput
								label={"Choose Language"}
								onChange={textChange}
								value={state?.language?._id || state?.language}
								name={"language"}>
								<option value="" className="">
									Select Language
								</option>
								{language?.all?.docs?.map((it, i) => (
									<option key={i} value={it?._id}>
										{it?.name}
									</option>
								))}
							</SelectInput>
							<SelectInput
								value={state?.category?._id || state?.category}
								name="category"
								onChange={textChange}
								label={"Choose a Category"}>
								{" "}
								<option value="">Select a Category</option>
								{category?.all?.docs
									?.filter(it => !it?.parentCategory && it?.type === type)
									?.map((it, i) => (
										<option key={i} value={it?._id}>
											{it?.name}
										</option>
									))}
							</SelectInput>
							{state?.category && (
								<SelectInput
									value={state?.subCategory?._id || state?.subCategory}
									name="subCategory"
									onChange={textChange}
									label={"Choose Sub Category"}>
									{" "}
									<option value="">Select a Sub Category</option>
									{category?.all?.docs
										?.filter(
											it =>
												it?.parentCategory &&
												state?.category === it?.parentCategory &&
												it?.type === type
										)
										?.map((it, i) => (
											<option key={i} value={it?._id}>
												{it?.name}
											</option>
										))}
								</SelectInput>
							)}
						</div>
					)}
					{itemForm?.length > 0 && (
						<>
							{itemForm?.map((item, index) => (
								<div key={index}>
									<div className="tw-mt-8">
										<TextArea
											label={`Question ${index + 1}`}
											placeholder={"Write question"}
											name="question"
											value={item?.question}
											onChange={event =>
												handleInputChangeForMutipleItem(
													event,
													index,
													"question"
												)
											}
										/>
									</div>
									<div className="tw-mt-6 tw-grid md:tw-grid-cols-2 tw-gap-10">
										<div className="tw-space-y-4">
											<Textinput
												type={"file"}
												label={
													type === "audioQuiz"
														? "Choose Audio (mp3)"
														: "Choose image (jpeg, png)"
												}
												name={"image"}
												accept={type === "audioQuiz" ? "audio/*" : "image/*"}
												// onChange={handleChangeImage}
												onChange={event =>
													handleChangeImage(
														event,
														index,
														type === "audioQuiz" ? "audio" : "image"
													)
												}
											/>
											{!["guessTheWord"]?.includes(type) ? (
												<>
													<Textinput
														type={"text"}
														label={"Option A"}
														name={"option_a"}
														placeholder={"Type in option a"}
														value={item?.option_a}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"option_a"
															)
														}
													/>
													{item?.question_type !== "check" && (
														<Textinput
															type={"text"}
															label={"Option C"}
															name={"option_c"}
															placeholder={"Type in option A"}
															value={item?.option_c}
															onChange={event =>
																handleInputChangeForMutipleItem(
																	event,
																	index,
																	"option_c"
																)
															}
														/>
													)}
													{/* <Textinput
								type={"text"}
								label={"Right Answer"}
								name={"right_answer"}
								placeholder={"Type in correct answer"}
							/> */}
													<SelectInput
														label={"Right Answer"}
														name={"right_answer"}
														value={item?.right_answer}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"right_answer"
															)
														}>
														<option value="" className="">
															Select Right Answer
														</option>
														<option value="option_a" className="">
															Option A
														</option>
														<option value="option_b" className="">
															Option B
														</option>
														{item?.question_type !== "check" && (
															<>
																<option value="option_c" className="">
																	Option C
																</option>
																<option value="option_d" className="">
																	Option D
																</option>
															</>
														)}
													</SelectInput>
												</>
											) : null}
										</div>
										{!["guessTheWord"]?.includes(type) ? (
											<div className="tw-space-y-4">
												<SelectInput
													label={"Choose Question Type"}
													name={"question_type"}
													value={item?.question_type}
													// onChange={textChange}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"question_type"
														)
													}>
													<option value="" className="">
														Select Options Type
													</option>
													<option value="options" className="">
														Options
													</option>
													<option value="check" className="">
														True/False
													</option>
												</SelectInput>
												<Textinput
													type={"text"}
													label={"Option B"}
													name={"option_b"}
													placeholder={"Type in option b"}
													value={item?.option_b}
													// onChange={textChange}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"option_b"
														)
													}
												/>
												{item?.question_type !== "check" && (
													<Textinput
														type={"text"}
														label={"Option D"}
														name={"option_d"}
														placeholder={"Type in option d"}
														value={item?.option_d}
														// onChange={textChange}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"option_d"
															)
														}
													/>
												)}
												<Textinput
													type={"tel"}
													label={"Level"}
													name={"level"}
													placeholder={"Type in level"}
													value={item?.level}
													// onChange={textChange}
													onChange={event =>
														handleInputChangeForMutipleItem(
															event,
															index,
															"level"
														)
													}
												/>
												{["funAndLearn"]?.includes(type) && (
													<SelectInput
														label={"Choose Type"}
														// onChange={textChange}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"typeID"
															)
														}
														value={item?.typeID}
														name={"typeID"}>
														<option value="" className="">
															Select Fun and Learn Type
														</option>
														{funAndLearn?.allTypes?.docs?.map((it, i) => (
															<option key={i} value={it?._id}>
																{it?.title}
															</option>
														))}
													</SelectInput>
												)}
												{["contest"]?.includes(type) && (
													<SelectInput
														label={"Choose Type"}
														// onChange={textChange}
														value={item?.typeID}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"typeID"
															)
														}
														name={"typeID"}>
														<option value="" className="">
															Select Contest Type
														</option>
														{contest?.allTypes?.docs?.map((it, i) => (
															<option key={i} value={it?._id}>
																{it?.name}
															</option>
														))}
													</SelectInput>
												)}
												{["exam"]?.includes(type) && (
													<SelectInput
														label={"Choose Type"}
														// onChange={textChange}
														value={item?.typeID}
														onChange={event =>
															handleInputChangeForMutipleItem(
																event,
																index,
																"typeID"
															)
														}
														name={"typeID"}>
														<option value="" className="">
															Select Exam Type
														</option>
														{exam?.allTypes?.docs?.map((it, i) => (
															<option key={i} value={it?._id}>
																{it?.title}
															</option>
														))}
													</SelectInput>
												)}
											</div>
										) : ["audioQuiz"]?.includes(type) ? (
											<Textinput
												type={"url"}
												label={"Audio Link"}
												name={"audioUrl"}
												placeholder={"Audio Link"}
												value={item?.audioUrl}
												// onChange={textChange}
												onChange={event =>
													handleInputChangeForMutipleItem(
														event,
														index,
														"audioUrl"
													)
												}
											/>
										) : (
											<Textinput
												type={"text"}
												label={"Right Answer"}
												name={"answer"}
												placeholder={"Type in the right sequence"}
												value={item?.answer}
												// onChange={textChange}
												onChange={event =>
													handleInputChangeForMutipleItem(
														event,
														index,
														"answer"
													)
												}
											/>
										)}
									</div>
									{!["exam"]?.includes(type) && (
										<div className="tw-mt-8">
											<TextArea
												label={"Answer Description"}
												placeholder={"Answer Description"}
												name="answerDescription"
												// onChange={textChange}
												value={item?.answerDescription}
												onChange={event =>
													handleInputChangeForMutipleItem(
														event,
														index,
														"answerDescription"
													)
												}
											/>
										</div>
									)}
									<div
										className="tw-flex tw-justify-end tw-items-center tw-cursor-pointer"
										onClick={() => handleDeleteRowForMutipleItem(index)}>
										Delete Question {index + 1}{" "}
										<BiTrash size={24} className="" />
									</div>
								</div>
							))}

							<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
								<Button
									loading={loading}
									onClick={handleSubmit}
									width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
									title={"Submit"}
								/>
							</div>
						</>
					)}
				</form>
			</MainContainer>
			{bulkUpload && (
				<ModalContainer
					handleClose={() => {}}
					title={"New Bulk Upload"}
					subtitle={
						"Add new dataset from an excel or csv file. Download sample file"
					}>
					<div className="lg:tw-w-1/2 tw-mx-auto myShadow tw-bg-white tw-rounded-xl tw-p-8">
						<Extractor pres={pres} setPres={setPres} />
						<div className="tw-flex tw-items-center tw-gap-1 tw-justify-between tw-mx-auto tw-my-3">
							<a
								href={RawSample}
								download={RawSample?.name}
								target="_blank"
								rel="noreferrer"
								className="tw-text-main tw-flex tw-items-center tw-gap-1">
								<span>
									<BiDownload />
								</span>
								<p className="tw-text-xs">Download sample file</p>
							</a>
							{/* <CSVLink
						filename="Rolodex Raw Data Sample.csv"
						className="tw-text-main tw-flex tw-items-center tw-gap-1"
						data={newSample}>
						<span>
							<BiDownload />
						</span>
						<p className="tw-text-xs">Download sample file</p>
					</CSVLink> */}
							<div className="tw-flex tw-items-center tw-gap-4">
								<Button
									buttonType={"secondary"}
									title={"Cancel"}
									css={"tw-h-8"}
									onClick={() => toggleUploadModal()}
								/>
								<Button
									buttonType={"primary"}
									title={"Import"}
									css={"tw-h-8"}
									// loading={loading2}
									type="submit"
									onClick={onSubmit2}
								/>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</div>
	);
};

export default BulkUpload;

export const toCamel = o => {
	var newO, origKey, newKey, value;
	if (o instanceof Array) {
		return o.map(function (value) {
			if (typeof value === "object") {
				value = toCamel(value);
			}
			return value;
		});
	} else {
		newO = {};
		for (origKey in o) {
			if (o.hasOwnProperty(origKey)) {
				newKey = (
					origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
				).toString();
				newKey = newKey
					?.split(" ")
					// eslint-disable-next-line no-loop-func
					?.map((it, i) => {
						let newIt =
							i === 0
								? (it.charAt(0).toLowerCase() + it.slice(1) || it).toString()
								: (
										(origKey?.toLowerCase()?.startsWith("option") ||
										origKey?.toLowerCase()?.startsWith("right")
											? it.charAt(0).toLowerCase()
											: it.charAt(0).toUpperCase()) + it.slice(1) || it
								  ).toString();
						return newIt;
					})
					?.join(
						origKey?.toLowerCase()?.startsWith("option") ||
							origKey?.toLowerCase()?.startsWith("right")
							? "_"
							: ""
					);
				value = origKey?.toLowerCase()?.startsWith("right answer")
					? o[origKey]?.toLowerCase()?.trim()?.split(" ")?.join("_")
					: o[origKey];
				if (
					value instanceof Array ||
					(value !== null && value.constructor === Object)
				) {
					value = toCamel(value);
				}
				newO[newKey] = typeof value === "string" ? value?.trim() : value;
			}
		}
	}
	return newO;
};
