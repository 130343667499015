import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";

const DistributePrize = () => {
  return (
    <div>
      <MainHeader text={"Distribute Prize"} />
      <MainContainer> </MainContainer>
    </div>
  );
};

export default DistributePrize;
