import React from "react";

const Radioinput = ({ name, value, onChange, label }) => {
  return (
    <div>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <input
          onChange={onChange}
          type="radio"
          name={name}
          id=""
          className="tw-h-4 tw-w-4"
        />
        <h6 className="tw-text-sm poppins tw-text-main tw-font-normal">
          {label}
        </h6>
      </div>
    </div>
  );
};

export default Radioinput;
