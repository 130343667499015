import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import { useState } from "react";
import AddNewUsers from "../../../components/modals/add-users";

const UsersRight = () => {
  const [modal, setModal] = useState(),
    handleClose = () => {
      setModal("");
    };
  const Arr = [
    {
      title: "Permissions",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Manage Contest",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Maths Questions",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Import Questions",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Categories",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Sub-categories",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Fun N learn",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Exam Modules",
      value: ["View", "Create", "Edit", "Delete"],
    },
    {
      title: "Languages",
      value: ["View", "Create", "Edit", "Delete"],
    },

    {
      title: "Category Order",
      value: ["View", "Create", "Edit", "Delete"],
    },
  ];
  return (
    <div>
      <MainHeader text={"Users Right"} />
      <MainContainer>
        <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-y-0 tw-spacey-5 tw-justify-between tw-items-center">
          <h2 className="work tw-text-main tw-font-normal tw-text-xl">
            All Badges
          </h2>
          <button
            onClick={() => setModal("add-new-user")}
            className="tw-h-11 tw-w-40 tw-rounded btngradient tw-text-base tw-font-normal tw-text-white work"
          >
            Add new user
          </button>
        </div>
        <div className="tw-py-5 tw-mt-10 tw-w-full tw-px-5 tw-bg-[#e7e7f6]">
          <h4 className="tw-text-base nunito tw-font-normal tw-text-main">
            Permissions
          </h4>
        </div>
        <div className="tw-w-full tw-px-10 tw-mx-auto tw-bg-white">
          {Arr.map((item, i) => (
            <div
              key={i}
              className="tw-bg-white tw-py-4 tw-items-center tw-flex tw-justify-between tw-w-1/2"
            >
              <h5 className="tw-text-sm nunito tw-text-black tw-font-normal">
                {item.title}
              </h5>
              <div className="tw-flex tw-items-center tw-gap-6">
                {item.value.map((item) => (
                  <div className="tw-flex tw-gap-2 tw-items-center">
                    <input type="checkbox" name="" id="" />
                    <h6 className="tw-text-black nunito tw-font-normal tw-text-sm">
                      {item}
                    </h6>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </MainContainer>
      {modal === "add-new-user" && <AddNewUsers handleClose={handleClose} />}
    </div>
  );
};

export default UsersRight;
