import ReactApexChart from "react-apexcharts";

const BarChart = () => {
  const daysOfWeek = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];
  const engagementsContests = [30, 45, 60, 40, 75, 90, 55];
  const engagementsQuizzes = [20, 35, 50, 30, 65, 80, 45];
  const engagementsExams = [15, 25, 40, 20, 45, 60, 35];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: daysOfWeek,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB", "#00E396", "#FEB019"],
    fill: {
      type: "solid",
    },
    tooltip: {
      theme: "dark",
    },
  };

  const series = [
    {
      name: "Contests",
      data: engagementsContests,
    },
    {
      name: "Quizzes",
      data: engagementsQuizzes,
    },
    {
      name: "Exams",
      data: engagementsExams,
    },
  ];

  return (
    <div className="bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;
