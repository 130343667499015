import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
	types: null,
	allTypes: null,
};

export const examQuestionSlice = createSlice({
	name: "examQuestion",
	initialState,
	reducers: {
		getSearchExamQuestion: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetExamQuestionSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getExamQuestion: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllExamQuestion: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		getExam: (state, { payload }) => {
			state.types = payload?.data || payload;
		},
		getAllExam: (state, { payload }) => {
			state.allTypes = payload?.data || payload;
		},
		addExamQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs ? 1 + state?.all?.totalDocs : 1,
			};
			state.status = "added";
		},
		addExamQuestionBatch: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [...data, ...state?.data?.docs] : [...data],
				totalDocs: state?.data?.totalDocs
					? data?.length + state?.data?.totalDocs
					: data?.length,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [...data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs
					? data?.length + state?.all?.totalDocs
					: data?.length,
			};
			state.status = "added";
		},
		addExam: (state, { payload }) => {
			let data = payload?.data || payload;
			state.types = {
				...state?.types,
				docs: state?.types?.docs ? [data, ...state?.types?.docs] : [data],
				totalDocs: state?.types?.totalDocs ? 1 + state?.types?.totalDocs : 1,
			};
			state.status = "added";
		},
		deleteExamQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		deleteExam: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.types = {
				...state?.types,
				docs: DeleteData(state?.types?.docs, data),
				totalDocs: state?.types?.totalDocs ? state?.types?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateExamQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		updateExam: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.types = {
				...state?.types,
				docs: EditData(state?.types?.docs, data),
			};
		},
		examQuestionFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutExamQuestion: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
			state.allTypes = null;
			state.types = null;
		},
	},
});
export const {
	examQuestionFail,
	getOrg,
	getExamQuestion,
	deleteExamQuestion,
	addExamQuestion,
	getSearchExamQuestion,
	getSearch,
	resetExamQuestionSearch,
	updateExamQuestion,
	logoutExamQuestion,
	getAllExamQuestion,
	getAllExam,
	getExam,
	addExam,
	updateExam,
	deleteExam,
	addExamQuestionBatch
} = examQuestionSlice.actions;

export const manageExamQuestion = (type, data, third, id) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (!third) {
			if (type === "get") {
				res = await axios.get(
					`/api/v1/examQuestion?_populate=image&_populate=examID${
						data?.limit ? `&_limit=${data?.limit}` : ""
					}`
				);
				dispatch(getExamQuestion(res?.data));
				// if (!data) {
				// 	let res2 = await axios.get(
				// 		`/api/v1/examQuestion?_populate=image&_populate=examID&_limit=0`
				// 	);
				// 	dispatch(getAllExamQuestion(res2?.data));
				// }
			}
			if (type === "post") {
				res = await axios.post(`/api/v1/examQuestion`, { ...data });
				let res2 = await axios.get(
					`/api/v1/examQuestion/${res?.data?.data?._id}?_populate=image&_populate=examID`
				);
				dispatch(addExamQuestion(res2?.data));
			}
			if (type === "batch") {
				res = await axios.post(`/api/v1/examQuestion/batch`, { ...data });

				dispatch(addExamQuestionBatch(res?.data));
			}
			if (type === "put") {
				let newData = data;
				delete newData?._id;
				delete newData?.id;
				res = await axios.put(
					`/api/v1/examQuestion/${data?._id || data?.id || id}`,
					{
						...newData,
					}
				);
				let res2 = await axios.get(
					`/api/v1/examQuestion/${res?.data?.data?._id}?_populate=image&_populate=examID`
				);
				dispatch(updateExamQuestion(res2?.data));
			}
			if (type === "delete") {
				res = await axios.delete(`/api/v1/examQuestion/${data?._id}`);
				dispatch(deleteExamQuestion(data));
			}
		} else {
			if (type === "get") {
				res = await axios.get(
					`/api/v1/exam${data?.limit ? `?_limit=${data?.limit}` : ""}`
				);
				dispatch(getExam(res?.data));
				// if (!data) {
				// 	let res2 = await axios.get(`/api/v1/exam?_limit=0`);
				// 	dispatch(getAllExam(res2?.data));
				// }
			}
			if (type === "post") {
				res = await axios.post(`/api/v1/exam`, { ...data });
				dispatch(addExam(res?.data));
			}
			if (type === "put") {
				let newData = data;
				delete newData?._id;
				delete newData?.id;
				res = await axios.put(`/api/v1/exam/${data?._id || data?.id || id}`, {
					...newData,
				});
				dispatch(updateExam(res?.data));
			}
			if (type === "delete") {
				res = await axios.delete(`/api/v1/exam/${data?._id}`);
				dispatch(deleteExam(data));
			}
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(examQuestionFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(examQuestionFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
