import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
	types: null,
	allTypes: null,
};

export const contestQuestionsSlice = createSlice({
	name: "contestQuestions",
	initialState,
	reducers: {
		getSearchContestQuestions: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetContestQuestionsSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getContestQuestions: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllContestQuestions: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		getContests: (state, { payload }) => {
			state.types = payload?.data || payload;
		},
		getAllContests: (state, { payload }) => {
			state.allTypes = payload?.data || payload;
		},
		addContestQuestions: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs ? 1 + state?.all?.totalDocs : 1,
			};
			state.status = "added";
		},
		addContests: (state, { payload }) => {
			let data = payload?.data || payload;
			state.types = {
				...state?.types,
				docs: state?.types?.docs ? [data, ...state?.types?.docs] : [data],
				totalDocs: state?.types?.totalDocs ? 1 + state?.types?.totalDocs : 1,
			};
			state.status = "added";
		},
		addContestQuestionBatch: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [...data, ...state?.data?.docs] : [...data],
				totalDocs: state?.data?.totalDocs
					? data?.length + state?.data?.totalDocs
					: data?.length,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [...data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs
					? data?.length + state?.all?.totalDocs
					: data?.length,
			};
			state.status = "added";
		},
		deleteContestQuestions: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		deleteContests: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.types = {
				...state?.types,
				docs: DeleteData(state?.types?.docs, data),
				totalDocs: state?.types?.totalDocs ? state?.types?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateContestQuestions: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		updateContests: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.types = {
				...state?.types,
				docs: EditData(state?.types?.docs, data),
			};
		},
		contestQuestionsFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutContestQuestions: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
			state.allTypes = null;
			state.types = null;
		},
	},
});
export const {
	contestQuestionsFail,
	getOrg,
	getContestQuestions,
	deleteContestQuestions,
	addContestQuestions,
	getSearchContestQuestions,
	getSearch,
	resetContestQuestionsSearch,
	updateContestQuestions,
	logoutContestQuestions,
	getAllContestQuestions,
	getAllContests,
	getContests,
	addContests,
	updateContests,
	deleteContests,
	addContestQuestionBatch,
} = contestQuestionsSlice.actions;

export const manageContestQuestions =
	(type, data, third, id) => async dispatch => {
		dispatch(clearErrors());
		try {
			let res;

			if (!third) {
				if (type === "get") {
					res = await axios.get(
						`/api/v1/contestQuestions?_populate=category&_populate=subCategory&_populate=image&_populate=language${
							data?.limit ? `&_limit=${data?.limit}` : ""
						}`
					);
					dispatch(getContestQuestions(res?.data));
					// if (!data) {
					// 	let res2 = await axios.get(
					// 		`/api/v1/contestQuestions?_populate=category&_populate=subCategory&_populate=image&_populate=language&_limit=0`
					// 	);
					// 	dispatch(getAllContestQuestions(res2?.data));
					// }
				}
				if (type === "post") {
					res = await axios.post(`/api/v1/contestQuestions`, { ...data });
					let res2 = await axios.get(
						`/api/v1/contestQuestions/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language`
					);
					dispatch(addContestQuestions(res2?.data));
				}
				if (type === "batch") {
					res = await axios.post(`/api/v1/contestQuestions/batch`, { ...data });

					dispatch(addContestQuestionBatch(res?.data));
				}
				if (type === "put") {
					let newData = data;
					delete newData?._id;
					delete newData?.id;
					res = await axios.put(
						`/api/v1/contestQuestions/${data?._id || data?.id || id}`,
						{
							...newData,
						}
					);
					let res2 = await axios.get(
						`/api/v1/contestQuestions/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language`
					);
					dispatch(updateContestQuestions(res2?.data));
				}
				if (type === "delete") {
					res = await axios.delete(`/api/v1/contestQuestions/${data?._id}`);
					dispatch(deleteContestQuestions(data));
				}
			} else {
				if (type === "get") {
					res = await axios.get(
						`/api/v1/contest${data?.limit ? `?_limit=${data?.limit}` : ""}`
					);
					dispatch(getContests(res?.data));
					// if (!data) {
					// 	let res2 = await axios.get(`/api/v1/contest?_limit=0`);
					// 	dispatch(getAllContests(res2?.data));
					// }
				}
				if (type === "post") {
					res = await axios.post(`/api/v1/contest`, { ...data });
					dispatch(addContests(res?.data));
				}
				if (type === "put") {
					let newData = data;
					delete newData?._id;
					delete newData?.id;
					res = await axios.put(
						`/api/v1/contest/${data?._id || data?.id || id}`,
						{
							...newData,
						}
					);
					dispatch(updateContests(res?.data));
				}
				if (type === "delete") {
					res = await axios.delete(`/api/v1/contest/${data?._id}`);
					dispatch(deleteContests(data));
				}
			}
			if (type !== "get") {
				toast.success(res?.data?.message);
				setTimeout(() => {
					dispatch(contestQuestionsFail());
				}, 1000);
			}
		} catch (err) {
			if (err) console.log({ error: err.response?.data, err });
			if (err?.response?.status === 429) toast.error(err?.response?.data);
			dispatch(contestQuestionsFail());
			if (type && type !== "get") {
				let error = err.response?.data?.error;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(err?.response?.data?.message);
				}
			}
		}
	};
