import React, { useEffect, useState, useContext } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GlobalState } from "../../../data/Context";
import Button, { MainPaginate } from "../../../components/buttons/button";
import Textinput from "../../../components/inputs/textinputs";
import { ModalContainer } from "../../../components/modals/modalcontainer";
import { AiFillCloseCircle } from "react-icons/ai";
import Line from "../../../assets/more - vertical.svg";
import { toast } from "react-toastify";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Img from "../../../assets/totalico.svg";

const UserStatistics = () => {
	let [data, setData] = useState(),
		{ state } = useLocation(),
		{ numberWithCommas } = useContext(GlobalState),
		[modal, setModal] = useState(""),
		navigate = useNavigate(),
		[loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ category, auth } = useSelector(s => s),
		[datum, setDatum] = useState(null),
		[datumAdmin, setDatumAdmin] = useState(null),
		textChange = e => {
			let { name, value } = e?.target;
			setDatum(prev => {
				let prevData = { ...prev };
				prevData[name] = value;
				return prevData;
			});
		},
		handleSubmit = async e => {
			try {
				e?.preventDefault();
				if (Object.entries(datum)?.length !== 2) return;
				setLoading(true);
				let res5 = await axios.get(
					`/api/v1/user/admin/stats?id=${state?._id}&startDate=${datum?.startDate}&endDate=${datum?.endDate}`
				);
				setDatumAdmin(res5?.data?.data?.[0]);
				console.log({ adminStatistics: res5?.data });
				setLoading(false);
				setDatum({});
				setModal("");
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		};

	useEffect(() => {
		if (!state) navigate(-1);
		if (auth?.user?.createdBy?.type === "admin") navigate(-1);
	}, [state, navigate, auth?.user?.createdBy?.type]);

	useEffect(() => {
		if (state?._id) {
			let getStat = async () => {
				try {
					let res5 = await axios.get(`/api/v1/userStatistics/${state?._id}`);
					setData(res5?.data?.data);
					console.log({ userStatistics: res5?.data });
				} catch (error) {}
			};
			getStat();
		}
	}, [state]);

	const stats = [
		{
			title: "Question Answered",
			value: numberWithCommas(data?.totalQuestionsAnswered || ""),
		},
		{
			title: "correct Answers",
			value: numberWithCommas(data?.totalCorrectQuestions || ""),
		},
		{
			title: "Strong category",
			value:
				data?.strongestCategory?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || "",
		},
		{
			title: "weak category",
			value:
				data?.weakestCategory?.replace(/([a-z0-9])([A-Z])/g, "$1 $2") || "",
		},
		{
			title: "highest score",
			value: numberWithCommas(data?.highestScore || ""),
		},
		{
			title: "lowest score",
			value: numberWithCommas(data?.lowestScore || ""),
		},
		{
			title: "victories",
			value: numberWithCommas(data?.totalVictories || ""),
		},
		{
			title: "losses",
			value: numberWithCommas(data?.totalLosses || ""),
		},
	];

	const Headers = [
		"Image",
		"Question",
		"category",
		"sub-category",
		"Answer",
		"Type",
	];

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	const currentItems = datumAdmin?.questions?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datumAdmin?.questions?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datumAdmin?.questions?.length;
		setItemOffset(newOffset);
	};

	return (
		<div>
			<MainHeader text={"Admin Statistics"} />
			<MainContainer>
				<div className="tw-flex tw-justify-between tw-items-center">
					<h2 className="tw-text-xl tw-text-main tw-font-normal work">
						{state?.profile?.firstName} {state?.profile?.lastName}'s Statistics
					</h2>
					<Button
						buttonType={"secondary"}
						title={"Admin Stat"}
						css={"tw-h-8"}
						onClick={() => setModal("stat")}
					/>
				</div>
				<div className="tw-mt-10 tw-w-full tw-bg-white tw-rounded-lg tw-p-8">
					<h2 className="tw-text-xl tw-text-main tw-font-normal work">
						General Statistics
					</h2>
					<div className="tw-mt-8 tw-gap-6 md:tw-w-1/2 tw-grid md:tw-grid-cols-2">
						{stats.map((item, i) => (
							<div key={i} className="">
								<h4 className="tw-text-lg tw-capitalize segoe tw-font-normal tw-text-main">
									{item.title}:{" "}
									<span className="tw-text-lg tw-capitalize tw-font-normal textColor segoe">
										{item.value}
									</span>
								</h4>
							</div>
						))}
					</div>
					{datumAdmin && (
						<>
							<div className="tw-mt-10">
								<h2 className="tw-text-xl tw-text-main tw-font-normal work">
									Question Statistics:{" "}
									{numberWithCommas(datumAdmin?.count || 0)}
								</h2>
								<table className="tw-w-full tw-table-auto">
									<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
										<tr>
											{Headers?.map((item, i) => (
												<th
													key={i}
													className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
													{item}
												</th>
											))}
										</tr>
									</thead>
									<tbody className="tw-divide-y-4">
										{currentItems?.map((item, i) => (
											<tr
												style={{
													boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
													marginBottom: "10px !important",
												}}
												key={i}
												className="work tw-bg-white tw-mt-8">
												<td className="tw-p-3">
													<img
														src={item?.image?.url || Img}
														alt=""
														className="tw-h-8 tw-mx-auto tw-rounded-full"
													/>
												</td>
												<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
													{item?.question}
												</td>
												<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
													{
														category?.all?.docs?.find(
															it => it?._id === item?.category
														)?.name
													}
												</td>
												<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
													{
														category?.all?.docs?.find(
															it => it?._id === item?.subCategory
														)?.name
													}
												</td>
												<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
													{item?.options?.[item?.correctOptionIndex - 1]?.value}
												</td>
												<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
													{item?.type?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")}
												</td>
											</tr>
										))}
									</tbody>
								</table>
								<div className="tw-mt-4 tw-flex tw-justify-center">
									{/* <Pagination /> */}
									<MainPaginate
										pageCount={pageCount}
										handlePageClick={handlePageClick}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</MainContainer>
			{modal === "stat" && (
				<>
					<ModalContainer>
						<div className="lg:tw-w-1/3 tw-mx-auto myShadow tw-bg-white tw-rounded-xl tw-p-8">
							<AiFillCloseCircle
								size={20}
								className="tw-cursor-pointer"
								onClick={() => setModal("")}
							/>
							<div className="tw-flex tw-justify-between tw-mt-4">
								<h2 className="segoe tw-text-xl tw-font-bold tw-text-text-main tw-capitalize">
									Stat Range
								</h2>
								<img src={Line} alt="line" className="tw-cursor-pointer" />
							</div>
							<form className="tw-mt-5">
								<div className="tw-space-y-4">
									<Textinput
										type={"date"}
										label={`Start Date`}
										name={"startDate"}
										placeholder={"General Knowledge"}
										value={state?.startDate}
										onChange={textChange}
										max={moment().format("YYYY-MM-DD")}
									/>
									{datum?.startDate && (
										<Textinput
											type={"date"}
											label={`End Date`}
											name={"endDate"}
											placeholder={"General Knowledge"}
											value={state?.endDate}
											onChange={textChange}
											max={moment().format("YYYY-MM-DD")}
											min={moment(datum?.startDate).format("YYYY-MM-DD")}
										/>
									)}
								</div>
								<div className="tw-mt-6">
									<Button
										width={
											"tw-w-full btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
										}
										title={"Send"}
										loading={loading}
										onClick={handleSubmit}
										type="submit"
									/>
								</div>
							</form>
						</div>
					</ModalContainer>
				</>
			)}
		</div>
	);
};

export default UserStatistics;
