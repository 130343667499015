import React, { useState, useEffect } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Textinput from "../../../components/inputs/textinputs";
import Button from "../../../components/buttons/button";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import { manageExamQuestion } from "../../../data/Reducers/ExamReducer";

const CreateExam = () => {
	let { exam } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {},
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		{ page } = useParams(),
		[image, setImage] = useState(null);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title || !state?.key || !state?.duration || !state?.date)
			return toast.info("Name/Key/Duration/Date is required");

		var newState = {};
		if (state?.title) newState.title = state?.title;
		if (state?.duration) newState.duration = Number(state?.duration);
		if (state?.date) newState.date = state?.date;
		if (state?.key) newState.key = state?.key;
		if (state?.description) newState.description = state?.description;

		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);

				newState.image = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		newState.canChangeAnswer = true;
		await dispatch(manageExamQuestion("post", newState, "types"));
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		setImage(null);
		navigate(`/${page}`);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(exam?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exam?.status]);

	return (
		<div>
			<MainHeader text={"Create Exam"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Create Exam
				</h2>
				<form action="" className="tw-mt-10">
					<div className="tw-grid md:tw-grid-cols-2 tw-gap-10">
						<div className="tw-space-y-4">
							<Textinput
								type={"file"}
								label={"Choose image (jpeg, png)"}
								name={"image"}
								accept={"image/*"}
								onChange={handleChangeImage}
							/>
							<Textinput
								label={"Exam Date"}
								type={"date"}
								name="date"
								value={state?.date}
								onChange={textChange}
								min={moment().format("YYYY-MM-DD")}
							/>
							<Textinput
								label={"Duration(in mins)"}
								type={"tel"}
								placeholder={"100"}
								name="duration"
								value={state?.duration}
								onChange={textChange}
							/>
						</div>
						<div className="tw-space-y-4">
							<Textinput
								label={"Exam Name"}
								type={"text"}
								placeholder={"Enter exam name"}
								name="title"
								value={state?.title}
								onChange={textChange}
							/>
							<Textinput
								label={"Exam Key"}
								type={"number"}
								name="key"
								value={state?.key}
								onChange={textChange}
							/>
						</div>
					</div>
					{/* <div className="tw-mt-6">
						<TextArea
							label={"Descriptions"}
							name="description"
							value={state?.description}
							onChange={textChange}
						/>
					</div> */}
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<Button
							loading={loading}
							onClick={handleSubmit}
							width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
							title={"Submit"}
						/>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default CreateExam;
