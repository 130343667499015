import React, { useEffect } from "react";
import MainHeader from "../../../components/mainheader/";
import MainContainer from "../../../components/maincontainer";
import { FaqTable } from "../../../components/tables/sliders-table";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { manageFaqs } from "../../../data/Reducers/FaqReducer";
import { LoadingLoader } from "../../../components/buttons/button";
import AddFaq from "../../../components/modals/add-faq";

const Faqs = () => {
	const [modal, setModal] = useState(""),
		handleClose = () => {
			setModal("");
		};
	const Headers = ["Question", "Answer", "actions"];
	const tableData = [
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
	];

	let { faq } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(faq?.data);
	}, [faq?.data]);

	useEffect(() => {
		dispatch(manageFaqs("get", null));
	}, [dispatch]);

	if (!data && !faq?.data) return <LoadingLoader />;

	return (
		<div>
			<MainHeader text={"Faqs"} />
			<MainContainer>
				<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-y-0 tw-spacey-5 tw-justify-between tw-items-center">
					<h2 className="work tw-text-main tw-font-normal tw-text-xl">
						Manage Faqs
					</h2>
					<button
						onClick={() => setModal("add-new-faq")}
						className="tw-h-11 tw-w-40 tw-rounded btngradient tw-text-base tw-font-normal tw-text-white work">
						Add new faq
					</button>
				</div>
				<div className="tw-mt-10">
					<FaqTable data={data?.docs || tableData} headers={Headers} />
				</div>
			</MainContainer>
			{modal === "add-new-faq" && <AddFaq handleClose={handleClose} />}
		</div>
	);
};

export default Faqs;
