import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { manageContestQuestions } from "../../data/Reducers/ContestReducer";
import ConfirmDelete from "../../components/modals/confirm-delete";
import DeletedModal from "../../components/modals/deleted-modal";
import { useNavigate } from "react-router-dom";
import Line from "../../assets/more - vertical.svg";

const Questions = () => {
	const Headers = ["ID", "type", "language", "questions", "status", "actions"];
	const tableData = [
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
	];

	let { contest } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(contest?.data);
	}, [contest?.data]);

	useEffect(() => {
		dispatch(manageContestQuestions("get", null));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !contest?.data) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageContestQuestions("get", datum));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Contest"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">Contest</h2>
				<div className="tw-mt-8">
					<ContestTable headers={Headers} data={currentItems || tableData} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Questions;

const ContestTable = ({ headers, data }) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null),
		navigate = useNavigate();
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	let [datum, setDatum] = useState(null);

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ contest } = useSelector(s => s);

	useEffect(() => {
		if (["deleted"]?.includes(contest?.status)) {
			setActionModal("deleted-modal");
			setDatum(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contest?.status]);

	return (
		<div>
			<table className="tw-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{i + 1}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.typeID?.title}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.language?.name}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.question}
							</td>
							<td className="tw-flex tw-justify-center tw-py-2">
								<button
									className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
										item?.active
											? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
											: "tw-bg-red-400 tw-text-white"
									} `}>
									{item?.active ? "Active" : "Inactive"}
								</button>
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6
											onClick={() =>
												navigate("/contest/create-question", { state: item })
											}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											EDIT Contest
										</h6>
										<h6
											onClick={() => {
												setDatum(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete Contest
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Contest"}
					loading={loading}
					handleClose={handleCloseModal}
					handleNext={async e => {
						e?.preventDefault();
						if (!datum) return;
						setLoading(true);
						await dispatch(manageContestQuestions("delete", datum));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Contest"} handleClose={handleCloseModal} />
			)}
		</div>
	);
};