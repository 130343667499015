import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Line from "../../../assets/more - vertical.svg";
import Img from "../../../assets/totalico.svg";
// import ConfirmDelete from "../../modals/confirm-delete";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDelete from "../../modals/confirm-delete";
import { manageQuizZone } from "../../../data/Reducers/QuizZoneReducer";
import DeletedModal from "../../modals/deleted-modal";

const QuizZoneTable = ({ data, headers }) => {
  const [modal, SetModal] = useState(false),
    [active, SetActive] = useState(null);
  const handleModal = (i) => {
      SetActive(i);
      SetModal(!modal);
    },
    navigate = useNavigate();

  let [datum, setDatum] = useState(null),
    [actionModal, setActionModal] = useState(""),
    handleCloseModal = () => {
      setActionModal("");
    };

  let [loading, setLoading] = useState(null),
    dispatch = useDispatch(),
    { quizZone } = useSelector((s) => s);

  useEffect(() => {
    if (["deleted"]?.includes(quizZone?.status)) {
      setActionModal("deleted-modal");
      setDatum(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizZone?.status]);

  return (
		<div>
			<table className="tw-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-p-3">
								<img
									src={item?.image?.url || Img}
									alt=""
									className="tw-h-8 tw-mx-auto tw-rounded-full"
								/>
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.question}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
								{item?.category?.name}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center tw-capitalize">
								{item?.subCategory?.name}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.options?.[item?.correctOptionIndex - 1]?.value}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.timeline}
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6
											onClick={() =>
												navigate("/quiz-zone/create-questions", { state: item })
											}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											EDIT Quiz Zone
										</h6>
										<h6
											onClick={() => {
												setDatum(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete Quiz Zone
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Quiz Zone"}
					loading={loading}
					handleClose={handleCloseModal}
					handleNext={async e => {
						e?.preventDefault();
						if (!datum) return;
						setLoading(true);
						await dispatch(manageQuizZone("delete", datum));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Quiz Zone"} handleClose={handleCloseModal} />
			)}
		</div>
	);
};

export default QuizZoneTable;
