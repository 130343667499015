import React, { useState, useEffect } from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillCloseCircle } from "react-icons/ai";
import Line from "../../assets/more - vertical.svg";
import SelectInput from "../inputs/selectinputs";
import Textinput from "../inputs/textinputs";
// import ModalBtn from "../buttons/modalbtn";
import { useSelector, useDispatch } from "react-redux";
import { manageCategory } from "../../data/Reducers/CategoryReducer";
import Button from "../buttons/button";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";

const CreateCategory = ({
	handleClose,
	type = "category",
	datum,
	subType = "",
}) => {
	let { category } = useSelector(state => state),
		[loading, setLoading] = useState(false),
		init = {
			name: "",
		},
		[state, setState] = useState(init),
		[image, setImage] = useState(null),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		};

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes(type === "audioQuiz" ? "audio" : "image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	useEffect(() => {
		if (subType) setState({ ...state, type: subType });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subType]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.name) return;
		let newState = {};
		newState.type = state?.type;
		if (type === "sub category") {
			let findType = category?.data?.docs?.find(
				it => it?._id === state?.parentCategory
			);
			if (findType && findType?.type) newState.type = findType?.type;
			if (state?.parentCategory)
				newState.parentCategory = state?.parentCategory;
		}
		newState.name = state?.name;
		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image, type: "image" },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
				newState.image = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		await dispatch(
			manageCategory(
				datum?._id ? "put" : "post",
				datum?._id
					? {
							...newState,
							_id: datum?._id || state?._id,
							id: datum?._id || state?._id,
					  }
					: newState,
				datum?._id || state?._id
			)
		);
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		setImage(null);
		handleClose();
	};

	useEffect(() => {
		if (datum) setState({ ...datum });
	}, [datum]);

	useEffect(() => {
		if (["added", "updated"]?.includes(category?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [category?.status]);

	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-1/3 tw-mx-auto myShadow tw-bg-white tw-rounded-xl tw-p-8">
					<AiFillCloseCircle
						size={20}
						className="tw-cursor-pointer"
						onClick={handleClose}
					/>
					<div className="tw-flex tw-justify-between tw-mt-4">
						<h2 className="segoe tw-text-xl tw-font-bold tw-text-text-main tw-capitalize">
							{datum?._id ? "Update" : `Create`} {type}
						</h2>
						<img src={Line} alt="line" className="tw-cursor-pointer" />
					</div>
					<form className="tw-mt-5">
						<div className="tw-space-y-4">
							<Textinput
								type={"file"}
								label={"Choose image (jpeg, png)"}
								name={"image"}
								accept={"image/*"}
								onChange={handleChangeImage}
							/>
							{type === "sub category" ? (
								<SelectInput
									value={state?.parentCategory}
									name="parentCategory"
									onChange={textChange}
									label={"Choose a Category"}>
									{" "}
									<option value="">Select a Category</option>
									{category?.all?.docs
										?.filter(it => {
											return !subType
												? !it?.parentCategory
												: !it?.parentCategory && it?.type === subType;
										})
										?.map((it, i) => (
											<option value={it?._id}>{it?.name}</option>
										))}
								</SelectInput>
							) : (
								<SelectInput
									value={state?.type}
									name="type"
									onChange={textChange}
									label={"Choose a Quiz Type"}>
									{" "}
									<option value="">Select Quiz Type</option>
									<option value="quizZone">Quiz Zone</option>
									<option value="dailyQuiz">Daily Quiz</option>
									<option value="funAndLearn">Fun and Learn</option>
									<option value="mathQuiz">Math Quiz</option>
									<option value="audioQuiz">Audio Quiz</option>
									<option value="contest">Contest</option>
									<option value="guessTheWord">Guess The Word</option>
								</SelectInput>
							)}
							<Textinput
								type={"text"}
								label={`${type} name`}
								name={"name"}
								placeholder={"General Knowledge"}
								value={state?.name}
								onChange={textChange}
							/>
							{/* <Textinput
                type={"file"}
                label={"Category image"}
                name={"category_image"}
                placeholder={"General Knowledge"}
              /> */}
						</div>
						<div className="tw-mt-6">
							{/* <ModalBtn text={"Create"} /> */}
							<Button
								width={
									"tw-w-full btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
								}
								title={datum?._id ? "Update" : "Create"}
								loading={loading}
								onClick={handleSubmit}
								type="submit"
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default CreateCategory;
