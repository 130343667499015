import React, { useState, useEffect } from "react";
import moment from "moment";

const LeaderboardTable = ({ data, headers }) => {
	// console.log({ data });
	return (
		<div className="tw-overflow-x-auto noScroll">
			<table className="tw-min-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data?.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-h-12 tw-mt-8">
							{/* <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.id}
							</td> */}
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.user?.[0]?.lastName} {item?.user?.[0]?.firstName}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.updatedAt).format("DD/MM/YYYY")}
							</td>

							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.user?.[0]?.createdAt).format("DD/MM/YYYY")}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.user?.[0]?.email}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.totalPoints}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								<RankManager score={i} />
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default LeaderboardTable;

export const RankManager = ({ score }) => {
	let [grader, setGrader] = useState("");

	let hostEndsWith = (host, ends) => {
		let value = false;
		value = ends?.some(element => {
			return host?.toString()?.endsWith(element?.toString());
		});
		return value;
	};
	useEffect(() => {
		if (score) {
			let finder = score + 1,
				finder2 = finder?.toString();
			if (hostEndsWith(finder2, ["11", "12", "13"])) {
				setGrader("th");
			} else if (finder2?.endsWith("1")) setGrader("st");
			else if (finder2?.endsWith("2")) setGrader("nd");
			else if (finder2?.endsWith("3")) setGrader("rd");
			else setGrader("th");
		} else if (score === 0) setGrader("st");
	}, [score]);

	return (
		<>
			{score + 1}
			{grader}
		</>
	);
};
