import React from "react";
import ModalContainer from "./modalcontainer";
import Gif from "../../assets/done.gif";

const DoneModal = () => {
  return (
    <div>
      <section className="tw-fixed tw-z-[1000] tw-bg-opacity-30 tw-flex tw-justify-center tw-items-center  tw-backdrop-blur-sm tw-inset-0 tw-bg-black ">
        <img src={Gif} alt="" className="tw-mx-auto" />
        <h6 className="tw-pt-4 tw-text-base tw-text-main tw-text-opacity-90 tw-font-normal sansation">
          Done!
        </h6>
      </section>
    </div>
  );
};

export default DoneModal;
