import React, { useEffect, useState } from "react";
import MainHeader from "../../components/mainheader";
import SelectInput from "../../components/inputs/selectinputs";
import Img from "../../assets/tableimg.png";
import MainTable from "../../components/tables/maintable";
import { addAdmin, managePlayer } from "../../data/Reducers/PlayerReducer";
import Button, {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { toast } from "react-toastify";
import Textinput from "../../components/inputs/textinputs";
import { ModalContainer } from "../../components/modals/modalcontainer";
import { AiFillCloseCircle } from "react-icons/ai";

const Users = () => <MainUsers />;

export const MainUsers = ({ hide, admin }) => {
	const Headers = [
		"Image",
		// "ID",
		"Name",
		"Date joined",
		"Email address",
		"Phone",
		"Coins",
		"status",
		"Actions",
	];
	const tableData = [
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: true,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: true,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: true,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: false,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: true,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: false,
		},
		{
			img: Img,
			id: "25",
			name: "username",
			date_joined: "26/12/2008",
			email: "username@example.com",
			phone: "+2349036228765",
			coins: "100",
			active: true,
		},
	];

	let { player, auth } = useSelector(s => s),
		[state, setState] = useState(null),
		dispatch = useDispatch(),
		navigate = useNavigate();

	useEffect(() => {
		setState(admin ? player?.admins : player?.data);
	}, [player?.data, admin, player?.admins]);

	useEffect(() => {
		dispatch(managePlayer("get", admin ? { admin: admin?.url } : null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (!auth?.isAuth) navigate(1);
	}, [auth?.isAuth, navigate]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null),
		[modal, setModal] = useState(""),
		[datum, setDatum] = useState(null),
		textChange = e => {
			let { name, value } = e?.target;
			setDatum(prev => {
				let prevData = { ...prev };
				prevData[name] = value;
				return prevData;
			});
		},
		handleSubmit = async e => {
			try {
				e?.preventDefault();
				if (Object.entries(datum)?.length === 0) return;
				setLoading(true);
				let res5 = await axios.post(`/api/v1/user/admin`, { ...datum });
				addAdmin(res5?.data?.data);
				toast.success(res5?.data?.message);
				console.log({ adminStatistics: res5?.data });
				setLoading(false);
				setDatum(null);
				setModal("");
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}
		};

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!state && !player?.data) return <LoadingLoader />;

	const currentItems = state?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(state?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % state?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: state?.limit * state?.nextPage };
			if (admin) datum.admin = "/admin";
			await dispatch(managePlayer("get", datum));
			setLoading(false);
		};

	return (
		<div>
			{!hide && <MainHeader text={admin?.name || "Users"} />}
			<div className="tw-w-full tw-bg-[#F7F8FC] tw-p-8">
				<h2 className="tw-text-xl tw-text-main tw-font-normal work tw-hidden">
					Lore’s Leaderboard Activity
				</h2>
				{admin && auth?.user?.type !== "admin" && (
					<div className="tw-flex tw-tems-center tw-justify-end tw-gap-4">
						<Button
							buttonType={"secondary"}
							title={"Add Admin"}
							css={"tw-h-8"}
							onClick={() => setModal("admin")}
						/>
					</div>
				)}
				<div className="tw-w-72 tw-mt-4 tw-hidden">
					<SelectInput name={"Choose Category"}>
						<option value="english">English</option>
						<option value="french">French</option>
						<option value="english">English</option>
					</SelectInput>
				</div>
				<div className="tw-mt-8">
					<MainTable
						headers={Headers}
						data={currentItems || tableData}
						img={tableData?.[0]?.img}
						admin={admin}
					/>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && state?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</div>
			{modal === "admin" && (
				<>
					<ModalContainer>
						<div className="lg:tw-w-1/3 tw-mx-auto myShadow tw-bg-white tw-rounded-xl tw-p-8">
							<AiFillCloseCircle
								size={20}
								className="tw-cursor-pointer"
								onClick={() => setModal("")}
							/>
							<div className="tw-flex tw-justify-between tw-mt-4">
								<h2 className="segoe tw-text-xl tw-font-bold tw-text-text-main tw-capitalize">
									Admin Details
								</h2>
							</div>
							<form className="tw-mt-5">
								<div className="tw-space-y-4">
									<Textinput
										type={"text"}
										label={`First Name`}
										name={"firstName"}
										placeholder={"John"}
										value={state?.firstName}
										onChange={textChange}
									/>
									<Textinput
										type={"text"}
										label={`Last Name`}
										name={"lastName"}
										placeholder={"Doe"}
										value={state?.lastName}
										onChange={textChange}
									/>
									<Textinput
										type={"tel"}
										label={`Mobile Number`}
										name={"phone"}
										placeholder={"08*********"}
										value={state?.phone}
										onChange={textChange}
									/>
									<Textinput
										type={"email"}
										label={`Email`}
										name={"email"}
										placeholder={"example@mail.xyz"}
										value={state?.email}
										onChange={textChange}
									/>
									<Textinput
										type={"password"}
										label={`Password`}
										name={"password"}
										placeholder={"Password"}
										value={state?.password}
										onChange={textChange}
									/>
								</div>
								<div className="tw-mt-6">
									<Button
										width={
											"tw-w-full btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
										}
										title={"Send"}
										loading={loading}
										onClick={handleSubmit}
										type="submit"
									/>
								</div>
							</form>
						</div>
					</ModalContainer>
				</>
			)}
		</div>
	);
};

export default Users;
