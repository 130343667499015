import React, { useEffect } from "react";
import Line from "../../../assets/more - vertical.svg";
import Img from "../../../assets/sliderimg.png";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../../../components/modals/confirm-delete";
import DeletedModal from "../../../components/modals/deleted-modal";
// import AddSlider from "../../modals/add-slider";
// import EditSlider from "../../modals/edit-slider.jsx";
import AddSlider from "../../modals/add-slider.jsx";
import { useDispatch, useSelector } from "react-redux";
import { manageSliders } from "../../../data/Reducers/SliderReducer.js";
import AddFaq from "../../modals/add-faq.jsx";
import { manageFaqs } from "../../../data/Reducers/FaqReducer.js";

const SliderTable = ({ data, headers }) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null),
		[item, setItem] = useState(null),
		{ slider } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(null);
	// navigate = useNavigate();
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	useEffect(() => {
		if (["deleted"]?.includes(slider?.status)) {
			setActionModal("deleted-modal");
			setItem(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slider?.status]);

	return (
		<div className="tw-overflow-x-auto">
			<table className="tw-min-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers?.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data?.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-p-3">
								<img
									src={item?.image?.url || Img}
									alt=""
									className="tw-h-8 tw-mx-auto"
								/>
							</td>
							{/* <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.id}
              </td> */}
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.language?.name}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.title}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.description}
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6
											onClick={() => {
												setItem(item);
												setActionModal("add-new-slider");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											edit slider
										</h6>
										<h6
											onClick={() => {
												setItem(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete slider
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "add-new-slider" && (
				<AddSlider item={item} handleClose={handleCloseModal} />
			)}
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Slider"}
					handleClose={handleCloseModal}
					loading={loading}
					handleNext={async e => {
						e?.preventDefault();
						if (!item) return;
						setLoading(true);
						await dispatch(manageSliders("delete", item));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Slider"} handleClose={handleCloseModal} />
			)}
		</div>
	);
};

export default SliderTable;

export const FaqTable = ({ data, headers }) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null),
		[item, setItem] = useState(null),
		{ faq } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(null);
	// navigate = useNavigate();
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	useEffect(() => {
		if (["deleted"]?.includes(faq?.status)) {
			setActionModal("deleted-modal");
			setItem(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [faq?.status]);

	return (
		<div className="tw-overflow-x-auto">
			<table className="tw-min-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers?.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data?.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.question}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.answer}
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6
											onClick={() => {
												setItem(item);
												setActionModal("add-new-faq");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											edit faq
										</h6>
										<h6
											onClick={() => {
												setItem(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete faq
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "add-new-faq" && (
				<AddFaq item={item} handleClose={handleCloseModal} />
			)}
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Faq"}
					handleClose={handleCloseModal}
					loading={loading}
					handleNext={async e => {
						e?.preventDefault();
						if (!item) return;
						setLoading(true);
						await dispatch(manageFaqs("delete", item));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Faq"} handleClose={handleCloseModal} />
			)}
		</div>
	);
};