import React from "react";
import { ModalContainer } from "./modalcontainer";
import Radioinput from "../inputs/radioinputs";
import { AiFillLeftCircle } from "react-icons/ai";

const StatusModal = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer>
        <div className="tw-bg-white tw-p-5  tw-mx-auto tw-rounded-md myShadow">
          <AiFillLeftCircle
            className="tw-cursor-pointer"
            size={20}
            onClick={handleClose}
          />
          <div className="tw-space-y-3 tw-mt-4 tw-mx-4">
            <p className="tw-text-sm nunito tw-font-normal tw-text-main tw-text-opacity-80">
              Choose Status
            </p>
            <Radioinput label={"Active"} />
            <Radioinput label={"Inactive"} />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default StatusModal;
