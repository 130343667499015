import React, { useState, useEffect } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import Manage from "../../assets/manage.svg";
import Import from "../../assets/import.svg";
import Create from "../../assets/create.svg";
// import CreateSubCategory from "../../components/modals/create-sub-category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
import { manageQuestionBank } from "../../data/Reducers/QuestionBankReducer";
import { QuestionBankTable } from "../../components/tables/questions-table";

const QuizZone = () => {
	const navigate = useNavigate();

	const Arr = [
		{
			title: "Question Report",
			img: Manage,
			bg: "#F4FFF1",
			action: () => navigate("/question-bank/question-report"),
		},
		{
			title: "import questions",
			img: Import,
			bg: "#F7F8FF",
			action: () => navigate("/question-bank/import-questions"),
		},
		{
			title: "create questions",
			img: Create,
			bg: "#FFF2F9",
			action: () => navigate("/question-bank/create-question"),
		},
	];
	const Headers = ["ID", "image", "language", "questions", "answer", "actions"];
	const tableData = [
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
	];

	let { questions } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(questions?.data);
	}, [questions?.data]);

	useEffect(() => {
		dispatch(manageQuestionBank("get", null));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !questions?.data) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageQuestionBank("get", datum));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Math Quiz"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Math Quiz
				</h2>
				<div className="tw-mt-8 tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-6">
					{Arr.map((item, i) => (
						<div
							key={i}
							onClick={item.action}
							style={{
								background: item.bg,
							}}
							className={`myShadow2 tw-rounded-md tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-h-28`}>
							<div>
								<img src={item?.img} alt={item?.title} className="tw-mx-auto" />
								<h4 className="tw-text-base sansation tw-mt-2 tw-font-bold tw-text-center tw-text-main tw-capitalize">
									{item.title}
								</h4>
							</div>
						</div>
					))}
				</div>
				<div className="tw-mt-10">
					<QuestionBankTable
						data={currentItems || tableData}
						headers={Headers}
					/>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default QuizZone;
