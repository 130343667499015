import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Textinput from "../../../components/inputs/textinputs";

const ImportQuestions = () => {
  return (
    <div>
      <MainHeader text={"Import Questions"} />
      <MainContainer>
        <h2 className="tw-text-xl segoe tw-font-normal tw-text-main">
          Import Questions
        </h2>
        <div className="tw-mt-10 tw-w-72">
          <Textinput label={"Choose file (.csv)"} type={"file"} name={"file"} />
          <div className="tw-flex tw-justify-center tw-mt-2">
            <button className="tw-w-32 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
              Submit
            </button>
          </div>
        </div>
        <div className="tw-mt-10 tw-bg-white tw-p-8">
          <h3 className="tw-text-xl tw-font-normal segoe textColor tw-uppercase">
            instructions
          </h3>
          <p className="tw-text-base tw-text-main tw-text-opacity-80 tw-font-normal tw-pt-6">
            Lorem ipsum dolor sit amet consectetur. Duis bibendum vel morbi
            dolor ante amet auctor in. Nibh nibh lectus cras ornare est egestas
            erat sem. Blandit lorem nibh eleifend iaculis nibh sociis enim
            malesuada viverra. Sed porta pretium arcu ullamcorper sed dolor
            massa nulla eu. Proin diam id fringilla aenean urna non. In odio eu
            parturient mi elementum consectetur tincidunt mattis vel. In nunc
            sit lectus neque tempor quis. Urna laoreet id dictum nulla. Nullam
            amet neque dictum cras vitae ornare mattis. Turpis aliquet arcu
            lacinia phasellus vitae facilisis sapien nisi. Dui vestibulum morbi
            cursus elementum malesuada. Risus feugiat faucibus et lorem amet
            vitae ac. Nulla quam pellentesque mauris ultrices amet id enim non.
            Nec quis pellentesque in vestibulum metus augue lectus. Tellus
            phasellus ut amet diam nunc sed. Lacus leo eget ultricies enim.
          </p>
        </div>
      </MainContainer>
    </div>
  );
};

export default ImportQuestions;
