import { ClipLoader } from "react-spinners";
import "./loading.css";
import ReactPaginate from "react-paginate";

const BUTTON_TYPES = {
	primary: "tw-bg-black tw-text-white",
	primaryOutline: "tw-border-2 tw-border-[#2A72A8] tw-text-main",
	secondary: "tw-bg-[#F72585] tw-text-white",
	tetiary: "tw-border-2 tw-border-main tw-text-main",
};

const Button = ({
	children,
	loading,
	buttonType,
	width,
	style,
	title,
	loadCss,
	disabled,
	icon,
	type,
	...restProps
}) => {
	return (
		<div>
			<button
				type={type || "button"}
				disabled={loading || disabled}
				className={`tw-text-sm tw-p-2 tw-px-8 tw-rounded-lg tw-flex tw-items-center tw-gap-2 tw-justify-center ${
					width || "tw-w-52"
				} ${style || ""} ${BUTTON_TYPES[buttonType]}`}
				{...restProps}>
				{icon && <span>{icon}</span>}
				{children}
				<span className={loading ? "tw-me-2" : ""}>
					{title ? title : "log in"}
				</span>
				{loading && (
					<ClipLoader color={loadCss ? loadCss : "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export default Button;

export const LoadingLoader = () => {
	return (
		<div className="loader">
			<div className="hexagon" aria-label="Animated hexagonal ripples">
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
			</div>
			<div className="tw-flex tw-items-center tw-justify-center">
				<p aria-label="Loading" className="text-center">
					Loading
				</p>
			</div>
		</div>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<Button
					onClick={handleLoadMore}
					title={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="tw-mx-auto"
					type={"button"}
					buttonType={"primary"}
					width={"w"}
				/>
			)}
		</>
	);
};

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<ReactPaginate
		breakLabel="..."
		nextLabel=">"
		onPageChange={handlePageClick}
		pageRangeDisplayed={5}
		pageCount={pageCount}
		previousLabel="<"
		renderOnZeroPageCount={null}
		className="tw-flex tw-items-center tw-justify-center tw-p-3"
		pageClassName="tw-mx-1 tw-p-2 tw-border tw-rounded tw-text-capitalize tw-no-underline tw-px-4 tw-h-10 tw-leading-tight tw-text-gray-500 tw-bg-white tw-border tw-border-gray-300 hover:tw-bg-gray-100 hover:tw-text-gray-700"
		previousClassName="tw-no-underline tw-px-4 tw-h-10 tw-leading-tight tw-text-gray-500 tw-bg-white tw-border tw-border-gray-300 hover:tw-bg-gray-100 hover:tw-text-gray-700 tw-flex tw-items-center tw-justify-center tw-p-2"
		nextClassName="tw-no-underline tw-px-4 tw-h-10 tw-leading-tight tw-text-gray-500 tw-bg-white tw-border tw-border-gray-300 hover:tw-bg-gray-100 hover:tw-text-gray-700 tw-flex tw-items-center tw-justify-center"
		activeClassName="tw-bg-[#1b1b1b] tw-p-2"
	/>
);