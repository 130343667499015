import React from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import Mail from "../../assets/mail.svg";
import Sms from "../../assets/sms.svg";
import NotificationTable from "../../components/tables/notification-table";
import { useState } from "react";
import SendMail from "../../components/modals/sendemail";
import SendSms from "../../components/modals/sendsms";

const Notifications = () => {
  const [modal, setModal] = useState();
  const handleClose = () => {
    setModal("");
  };
  const Headers = ["Message title", "date sent", "type", "recipient", "status"];
  const tableData = [
    {
      message_title: "Lorep ipsum",
      date_sent: "25/12/2023",
      type: "SMS",
      recipient: "General",
      sent: true,
    },
    {
      message_title: "Lorep ipsum",
      date_sent: "25/12/2023",
      type: "SMS",
      recipient: "General",
      sent: true,
    },
    {
      message_title: "Lorep ipsum",
      date_sent: "25/12/2023",
      type: "SMS",
      recipient: "General",
      sent: true,
    },
    {
      message_title: "Lorep ipsum",
      date_sent: "25/12/2023",
      type: "SMS",
      recipient: "General",
      sent: true,
    },
    {
      message_title: "Lorep ipsum",
      date_sent: "25/12/2023",
      type: "SMS",
      recipient: "General",
      sent: true,
    },
  ];
  return (
    <div>
      <MainHeader text={"Notifications"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-bold work">
          All Notifications
        </h2>
        <div className="tw-mt-10 lg:tw-w-2/3 tw-grid md:tw-grid-cols-2 tw-gap-8">
          <div
            onClick={() => setModal("send-email")}
            className="tw-h-64  tw-cursor-pointer tw-rounded-xl myShadow2 tw-bg-[#ecdaffcc] tw-flex tw-items-center tw-justify-center"
          >
            <div>
              <img src={Mail} alt="" className="tw-mx-auto" />
              <h4 className="tw-text-xl tw-pt-3 tw-text-center nunito tw-font-medium tw-text-main">
                Create Email Notification
              </h4>
            </div>
          </div>
          <div
            onClick={() => setModal("send-sms")}
            className="tw-h-64 tw-cursor-pointer tw-rounded-xl myShadow2 tw-bg-[#fcdaffcc] tw-flex tw-items-center tw-justify-center"
          >
            <div>
              <img src={Sms} alt="" className="tw-mx-auto" />
              <h4 className="tw-text-xl tw-pt-3 tw-text-center nunito tw-font-medium tw-text-main">
                Create Email Notification
              </h4>
            </div>
          </div>
        </div>
        <div className="tw-mt-8">
          <h5 className="tw-text-black work tw-text-base tw-font-normal">
            All Messages
          </h5>
          <div className="tw-mt-8">
            <NotificationTable data={tableData} headers={Headers} />
          </div>
        </div>
      </MainContainer>
      {modal === "send-email" && <SendMail handleClose={handleClose} />}
      {modal === "send-sms" && <SendSms handleClose={handleClose} />}
    </div>
  );
};

export default Notifications;
