import { CreateQuestions } from "../../quiz-zone/create-questions";

const CreateQuestionBank = () => (
	<CreateQuestions
		type="questions"
		title={"Question Bank"}
		subTitle="Create Question Bank"
	/>
);

export default CreateQuestionBank;
