import React from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import TextArea from "../inputs/textarea";
import Textinput from "../inputs/textinputs";

const SendMail = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer>
        <div className="lg:tw-w-1/3 tw-mx-auto tw-p-8 tw-bg-[#FDFDFF] tw-rounded-xl">
          <div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
            <AiFillLeftCircle
              className="tw-cursor-pointer"
              size={20}
              onClick={handleClose}
            />
            <h2 className="tw-text-base work tw-text-main tw-font-medium">
              New Message
            </h2>
          </div>
          <form action="" className="tw-mt-6">
            <div className="tw-space-y-4">
              <TextArea
                name={"recipients"}
                placeholder={"To: user@example.com"}
                label={"Recipient"}
              />
              <Textinput
                label={"Message Title"}
                placeholder={"Lorep ipsum"}
                name={"message_title"}
              />
              <TextArea
                name={"Message Body"}
                placeholder={"Message Body"}
                label={"Message Body"}
              />
            </div>
            <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
              <button
                onClick={handleClose}
                className="tw-w-32 tw-h-8 tw-border tw-bg-white tw-border-[#0966AB] tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-[#0966AB]"
              >
                Cancel
              </button>
              <button className="tw-w-32 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
                Send
              </button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default SendMail;
