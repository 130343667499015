import React from "react";

const NotificationTable = ({ headers, data }) => {
  return (
    <div className='tw-overflow-x-auto'>
      <table className="tw-min-w-full tw-table-auto">
        <thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
          <tr>
            {headers.map((item, i) => (
              <th
                key={i}
                className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tw-divide-y-4">
          {data.map((item, i) => (
            <tr
              style={{
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                marginBottom: "10px !important",
              }}
              key={i}
              className="work tw-bg-white tw-mt-8"
            >
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.message_title}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.date_sent}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.type}
              </td>

              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.recipient}
              </td>
              <td className="tw-flex tw-justify-center tw-py-2">
                <button
                  className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
                    item?.sent
                      ? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
                      : "tw-bg-red-400 tw-text-white"
                  } `}
                >
                  {item?.sent ? "Sent" : "Pending"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NotificationTable;
