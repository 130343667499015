import React, { useEffect } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import Main from "../../assets/main.svg";
import SubCat from "../../assets/subcat.svg";
import Catorder from "../../assets/catord.svg";
import Import from "../../assets/import.svg";
import Manage from "../../assets/manage.svg";
import GuessTable from "../../components/tables/guess-table";
import { useState } from "react";
import CreateCategory from "../../components/modals/create-category";
// import CreateSubCategory from "../../components/modals/create-sub-category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
import { manageGuessTheWord } from "../../data/Reducers/GuessTheWordReducer";

const Fun = () => {
	const [modal, setModal] = useState(""),
		navigate = useNavigate();
	const handleClose = () => {
		setModal("");
	};
	const Arr = [
		{
			title: "Main category",
			img: Main,
			bg: "#F7F8FF",
			action: () => setModal("create-category"),
		},
		{
			title: "sub category",
			img: SubCat,
			bg: "#F7FFF7",
			action: () => setModal("create-sub-category"),
		},
		{
			title: "category order",
			img: Catorder,
			bg: "#FCEEFF",
			action: () => navigate("/category-order"),
		},
		{
			title: "Create guess the word",
			img: Manage,
			bg: "#F4FFF1",
			action: () => navigate("create-guess-the-word"),
		},
		{
			title: "import questions",
			img: Import,
			bg: "#F7F8FF",
			action: () =>
				navigate("/bulk-upload", {
					state: {
						type: "guessTheWord",
						title: "Guess The Word Quiz",
						subTitle: "Create Guess The Word Quiz",
					},
				}),
		},
	];
	const Headers = ["ID", "image", "language", "questions", "answer", "actions"];
	const tableData = [
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			questions: "Lorem ipsum dolor sit amet",
			answer: "Microsoft",
		},
	];

	let { guessTheWord } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(guessTheWord?.data);
	}, [guessTheWord?.data]);

	useEffect(() => {
		dispatch(manageGuessTheWord("get", null));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !guessTheWord?.data) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageGuessTheWord("get", datum));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Guess The Word"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Guess The Word
				</h2>
				<div className="tw-mt-8 tw-grid tw-grid-cols-4 tw-gap-6">
					{Arr.map((item, i) => (
						<div
							key={i}
							onClick={item.action}
							style={{
								background: item.bg,
							}}
							className={`myShadow2 tw-rounded-md tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-h-28`}>
							<div>
								<img src={item?.img} alt={item?.title} className="tw-mx-auto" />
								<h4 className="tw-text-base sansation tw-mt-2 tw-font-bold tw-text-center tw-text-main tw-capitalize">
									{item.title}
								</h4>
							</div>
						</div>
					))}
				</div>
				<div className="tw-mt-10 tw-pb-10">
					<GuessTable data={currentItems || tableData} headers={Headers} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
			{modal === "create-category" && (
				<CreateCategory handleClose={handleClose} subType="guessTheWord" />
			)}
			{modal === "create-sub-category" && (
				<CreateCategory
					handleClose={handleClose}
					type="sub category"
					subType="guessTheWord"
				/>
				// <CreateSubCategory handleClose={handleClose} />
			)}
		</div>
	);
};

export default Fun;
