import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
import FunTable from "../../components/tables/fun-table";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { manageFunAndLearnQuestion } from "../../data/Reducers/FunNLearnReducer";

const Questions = () => {
	const Headers = ["ID", "type", "language", "questions", "status", "actions"];
	const tableData = [
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
	];

	let { funAndLearn } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(funAndLearn?.data);
	}, [funAndLearn?.data]);

	useEffect(() => {
		dispatch(manageFunAndLearnQuestion("get", null));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !funAndLearn?.data) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageFunAndLearnQuestion("get", datum));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Fun N Learn"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Fun N Learn
				</h2>
				<div className="tw-mt-8">
					<FunTable headers={Headers} data={currentItems || tableData} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Questions;
