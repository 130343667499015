import React from "react";
import { useState } from "react";
import Line from "../../../assets/more - vertical.svg";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../../modals/confirm-delete";
import DeletedModal from "../../modals/deleted-modal";
import StatusModal from "../../modals/status-modal";

const LanguageTable = ({ headers, data }) => {
  const [modal, SetModal] = useState(false),
    [actionModal, setActionModal] = useState(),
    navigate = useNavigate(),
    handleCloseModal = () => {
      setActionModal("");
    },
    [active, SetActive] = useState(null);
  const handleModal = (i) => {
    SetActive(i);
    SetModal(!modal);
  };
  return (
		<div>
			<table className="tw-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{i + 1}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.name}
							</td>

							{/* <td className="tw-flex tw-justify-center tw-py-2">
								<button
									className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
										item?.enable
											? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
											: "tw-bg-red-400 tw-text-white"
									} `}>
									{item?.enable ? "Enabled" : "Disabled"}
								</button>
							</td> */}
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white k2d">
										<h6
											onClick={() => setActionModal("status-modal")}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											edit language
										</h6>
										<h6
											onClick={() => setActionModal("delete-modal")}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete language
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Language"}
					handleClose={handleCloseModal}
					handleNext={() => setActionModal("deleted-modal")}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Language"} handleClose={handleCloseModal} />
			)}
			{actionModal === "status-modal" && (
				<StatusModal handleClose={handleCloseModal} />
			)}
		</div>
	);
};

export default LanguageTable;
