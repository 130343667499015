import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Textinput from "../../../components/inputs/textinputs";
import TextArea from "../../../components/inputs/textarea";

const EditContext = () => {
  return (
    <div>
      <MainHeader text={"Edit Context"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          Edit Context
        </h2>
        <form action="" className="tw-mt-10">
          <div className="tw-grid md:tw-grid-cols-2 tw-gap-10">
            <div className="tw-space-y-4">
              <Textinput label={"Choose file (jpg, png)"} type={"file"} />
              <Textinput
                label={"Entry Fee"}
                type={"text"}
                placeholder={"100"}
              />
              <Textinput label={"Start Date"} type={"date"} />
            </div>
            <div className="tw-space-y-4">
              <Textinput
                label={"Contest Name"}
                type={"text"}
                placeholder={"Enter contest name"}
              />
              <Textinput
                label={"Prize for winners"}
                type={"text"}
                placeholder={"Enter prize winner"}
              />
              <Textinput label={"End Date"} type={"date"} />
            </div>
          </div>
          <div className="tw-mt-6">
            <TextArea label={"Descriptions"} />
          </div>
          <div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
            <button className="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white">
              Submit
            </button>
          </div>
        </form>
      </MainContainer>
    </div>
  );
};

export default EditContext;
