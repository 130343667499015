import React from "react";

const MainContainer = ({ children }) => {
  return (
    <div className="tw-w-full tw-bg-[#F7F8FC] md:tw-p-10 tw-p-3 tw-min-h-screen">
      {children}
    </div>
  );
};

export default MainContainer;
