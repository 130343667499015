import React from "react";
import { ModalContainer } from "./modalcontainer";
import Gif from "../../assets/delete3.gif";
import Button from "../buttons/button";

const ConfirmDelete = ({ handleClose, handleNext, text, loading }) => {
	return (
		<div>
			<ModalContainer>
				<div className="tw-bg-white tw-p-6 lg:tw-w-72 tw-rounded-lg">
					<img src={Gif} alt="" className="tw-mx-auto" />
					<p className="tw-text-base tw-pt-2 tw-text-center work tw-font-normal tw-text-main">
						Are you sure you want to delete {text}? Action cannot be undone.
					</p>
					<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
						<button
							onClick={handleClose}
							className="tw-w-20 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
							No
						</button>
						{/* <button
							onClick={handleNext}
							className="tw-w-20 tw-h-8 tw-border tw-bg-white tw-border-[#0966AB] tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-[#0966AB]">
							Yes
						</button> */}
						<Button
							loading={loading}
							onClick={handleNext}
							width="tw-w-20 tw-h-8 tw-border tw-bg-white tw-border-[#0966AB] tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-[#0966AB]"
							title={"Yes"}
							loadCss={"#000"}
						/>
					</div>
				</div>
			</ModalContainer>
		</div>
	);
};

export default ConfirmDelete;
