import React, { useEffect, useState } from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import TextArea from "../inputs/textarea";
import Textinput from "../inputs/textinputs";
import SelectInput from "../inputs/selectinputs";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Button from "../buttons/button";
import { manageSliders } from "../../data/Reducers/SliderReducer";

const AddSlider = ({ handleClose, item }) => {
	let [state, setState] = useState({}),
		{ language, slider } = useSelector(s => s),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[image, setImage] = useState(null);

	useEffect(() => {
		if (item) {
			setState({ ...state, ...item });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title || !state?.description)
			return toast.info("At least, Title/Description is required");
		var newState = {};
		if (state?.title) newState.title = state?.title;
		if (state?.language) newState.language = state?.language;
		if (state?.description) newState.description = state?.description;
		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image, type: "image" },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);
				newState.image = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}

		await dispatch(
			manageSliders(item?._id ? "put" : "post", newState, item?._id || null)
		);
		setLoading(false);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(slider?.status)) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slider?.status]);

	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-1/3 tw-mx-auto tw-p-8 tw-bg-[#FDFDFF] tw-rounded-xl">
					<div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
						<AiFillLeftCircle
							className="tw-cursor-pointer"
							size={20}
							onClick={handleClose}
						/>
						<h2 className="tw-text-base work tw-text-main tw-font-medium">
							{item ? "Update" : "Create"} new slider
						</h2>
					</div>
					<form action="" className="tw-mt-6">
						<div className="tw-space-y-4">
							<SelectInput
								label={"Choose Language"}
								onChange={textChange}
								value={state?.language?._id || state?.language}
								name={"language"}>
								<option value="" className="">
									Select Language
								</option>
								{language?.all?.docs?.map((it, i) => (
									<option key={i} value={it?._id}>
										{it?.name}
									</option>
								))}
							</SelectInput>
							<Textinput
								label={"Title"}
								placeholder={"Lorep ipsum"}
								name={"title"}
								value={state?.title}
								onChange={textChange}
							/>
							<Textinput
								label={"Image"}
								type={"file"}
								name={"image"}
								onChange={handleChangeImage}
							/>
							<TextArea
								placeholder={"Message Body"}
								label={"Message Body"}
								name={"description"}
								value={state?.description}
								onChange={textChange}
							/>
						</div>
						<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
							<Button
								loading={loading}
								onClick={handleSubmit}
								width="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white"
								title={item ? "Update Slider" : "Create Slider"}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default AddSlider;
