import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
};

export const slidersSlice = createSlice({
	name: "sliders",
	initialState,
	reducers: {
		getSearchSliders: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetSlidersSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getSliders: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllSliders: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		addSliders: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.status = "added";
		},
		deleteSliders: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateSliders: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		slidersFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutSliders: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});
export const {
	slidersFail,
	getOrg,
	getSliders,
	deleteSliders,
	addSliders,
	getSearchSliders,
	getSearch,
	resetSlidersSearch,
	updateSliders,
	logoutSliders,
	getAllSliders,
} = slidersSlice.actions;

export const manageSliders = (type, data, id) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/sliders?_populate=image&_populate=language${
					data?.limit ? `&_limit=${data?.limit}` : ""
				}`
			);
			dispatch(getSliders(res?.data));
			if (!data) {
				let res2 = await axios.get(
					`/api/v1/sliders?_populate=image&_limit=0&_populate=language`
				);
				dispatch(getAllSliders(res2?.data));
			}
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/sliders`, { ...data });
			let res2 = await axios.get(
				`/api/v1/sliders/${res?.data?.data?._id}?_populate=image&_populate=language`
			);
			dispatch(addSliders(res2?.data));
		}
		if (type === "put") {
			let newData = data;
			delete newData?._id;
			delete newData?.id;
			res = await axios.put(`/api/v1/sliders/${data?._id || data?.id || id}`, {
				...newData,
			});
			let res2 = await axios.get(
				`/api/v1/sliders/${res?.data?.data?._id}?_populate=image&_populate=language`
			);
			dispatch(updateSliders(res2?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/sliders/${data?._id}`);
			dispatch(deleteSliders(data));
		}

		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(slidersFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(slidersFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
