import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
};

export const categorySlice = createSlice({
	name: "category",
	initialState,
	reducers: {
		getSearchCategory: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetCategorySearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getCategory: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getCategoryList: (state, { payload }) => {
			state.list = payload?.data || payload;
		},
		getSubCategoryList: (state, { payload }) => {
			state.sublist = payload?.data || payload;
		},
		getAllCategory: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		addCategory: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs ? 1 + state?.all?.totalDocs : 1,
			};
			state.status = "added";
		},
		deleteCategory: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateCategory: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		categoryFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutCategory: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});
export const {
	categoryFail,
	getOrg,
	getCategory,
	deleteCategory,
	addCategory,
	getSearchCategory,
	getSearch,
	resetCategorySearch,
	updateCategory,
	logoutCategory,
	getAllCategory,
	getCategoryList,
	getSubCategoryList,
} = categorySlice.actions;

export const manageCategory = (type, data, id) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/category?_populate=image${
					data?.limit ? `&_limit=${data?.limit}` : ""
				}`
			);
			dispatch(getCategory(res?.data));
			if (!data) {
				let res3 = await axios.get(`/api/v1/questions/stat/category?_limit=0`);
				dispatch(getCategoryList(res3?.data));
				let res4 = await axios.get(
					`/api/v1/questions/stat/subcategory?_limit=0`
				);
				dispatch(getSubCategoryList(res4?.data));

				let res2 = await axios.get(`/api/v1/category?_populate=image&_limit=0`);
				dispatch(getAllCategory(res2?.data));
			}
		}
		if (type === "post") {
			res = await axios.post(`/api/v1/category`, { ...data });
			dispatch(addCategory(res?.data));
		}
		if (type === "put") {
			let newData = data;
			delete newData?._id;
			delete newData?.id;
			res = await axios.put(`/api/v1/category/${data?._id || data?.id || id}`, {
				...newData,
			});
			dispatch(updateCategory(res?.data));
		}
		if (type === "delete") {
			res = await axios.delete(`/api/v1/category/${data?._id}`);
			dispatch(deleteCategory(data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(categoryFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(categoryFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
