import React from "react";

const FileInputs = ({ label, value, name, onChange }) => {
  return (
    <div>
      <h3 className="tw-text-sm segoe tw-text-main tw-text-opacity-90">
        {label}
      </h3>
    </div>
  );
};

export default FileInputs;
