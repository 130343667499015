import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	daily: null,
	monthly: null,
};

export const leaderboardSlice = createSlice({
	name: "leaderboard",
	initialState,
	reducers: {
		getSearchLeaderboard: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetLeaderboardSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getLeaderboard: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getDailyLeaderboard: (state, { payload }) => {
			state.daily = payload?.data || payload;
		},
		getMonthlyLeaderboard: (state, { payload }) => {
			state.monthly = payload?.data || payload;
		},
		leaderboardFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutLeaderboard: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});
export const {
	leaderboardFail,
	getOrg,
	getLeaderboard,
	getSearchLeaderboard,
	getSearch,
	resetLeaderboardSearch,
	logoutLeaderboard,
	getDailyLeaderboard,
	getMonthlyLeaderboard,
} = leaderboardSlice.actions;

export const manageLeaderboard = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/leaderboard${
					data?.type && ["monthly", "daily"]?.includes(data?.type)
						? `/${data?.type}`
						: ""
				}?_populate=profile${data?.limit ? `&_limit=${data?.limit}` : ""}`
			);
			dispatch(
				data?.type === "daily"
					? getDailyLeaderboard(res?.data)
					: data?.type === "monthly"
					? getMonthlyLeaderboard(res?.data)
					: getLeaderboard(res?.data)
			);
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(leaderboardFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(leaderboardFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
