import React from "react";

const UserLeaderBoardTable = ({ headers, data }) => {
  return (
    <div className='tw-overflow-x-auto'>
      <table className="tw-min-w-full tw-table-auto">
        <thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
          <tr>
            {headers.map((item, i) => (
              <th
                key={i}
                className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tw-divide-y-4">
          {data.map((item, i) => (
            <tr
              style={{
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                marginBottom: "10px !important",
              }}
              key={i}
              className="work tw-bg-white tw-h-12 tw-mt-8"
            >
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.last_updated}
              </td>

              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.date_created}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.email}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.score}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.rank}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserLeaderBoardTable;
