import React, { useEffect, useState } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import Main from "../../assets/main.svg";
import SubCat from "../../assets/subcat.svg";
import Catorder from "../../assets/catord.svg";
import Import from "../../assets/import.svg";
import Manage from "../../assets/manage.svg";
import { FunTypesTable } from "../../components/tables/fun-table";
import { useNavigate } from "react-router-dom";
import CreateCategory from "../../components/modals/create-category";
import { useSelector, useDispatch } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";
// import CreateSubCategory from "../../components/modals/create-sub-category";
import View from "../../assets/view.svg";
import { manageFunAndLearnQuestion } from "../../data/Reducers/FunNLearnReducer";

const Fun = () => {
	const [modal, setModal] = useState(""),
		navigate = useNavigate();
	const handleClose = () => {
		setModal("");
	};
	const Arr = [
		{
			title: "Main category",
			img: Main,
			bg: "#F7F8FF",
			action: () => setModal("create-category"),
		},
		{
			title: "sub category",
			img: SubCat,
			bg: "#F7FFF7",
			action: () => setModal("create-sub-category"),
		},
		{
			title: "Create Fun N Learn",
			img: Catorder,
			bg: "#FCEEFF",
			action: () => navigate("/fun/create-fun"),
		},
		{
			title: "Create Question",
			img: Manage,
			bg: "#F4FFF1",
			action: () => navigate("/fun/create-question"),
		},
		{
			title: "View Questions",
			img: View,
			bg: "#FFFFF7;",
			action: () => navigate("/fun/questions"),
		},
		{
			title: "import questions",
			img: Import,
			bg: "#F7F8FF",
			action: () =>
				navigate("/bulk-upload", {
					state: {
						type: "funAndLearn",
						title: "Fun And Learn Quiz",
						subTitle: "Create Fun And Learn Quiz",
					},
				}),
		},
	];

	const Headers = [
		"ID",
		"title",
		"language",
		"category",
		"sub category",
		"status",
		"actions",
	];

	const tableData = [
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			title: "The Nation",
			language: "English",
			questions: "20",
			active: true,
		},
	];

	let { funAndLearn } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(funAndLearn?.types);
	}, [funAndLearn?.types]);

	useEffect(() => {
		dispatch(manageFunAndLearnQuestion("get", null, "types"));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !funAndLearn?.types) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageFunAndLearnQuestion("get", datum, "types"));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Fun N Learn"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Fun N Learn
				</h2>
				<div className="tw-mt-8 tw-grid tw-grid-cols-4 tw-gap-6">
					{Arr.map((item, i) => (
						<div
							onClick={item.action}
							key={i}
							style={{
								background: item.bg,
							}}
							className={`myShadow2 tw-rounded-md tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-h-28`}>
							<div>
								<img src={item?.img} alt={item?.title} className="tw-mx-auto" />
								<h4 className="tw-text-base sansation tw-mt-2 tw-font-bold tw-text-center tw-text-main tw-capitalize">
									{item.title}
								</h4>
							</div>
						</div>
					))}
				</div>
				<div className="tw-mt-8">
					<FunTypesTable headers={Headers} data={currentItems || tableData} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
			{modal === "create-category" && (
				<CreateCategory handleClose={handleClose} subType="funAndLearn" />
			)}
			{modal === "create-sub-category" && (
				<CreateCategory
					handleClose={handleClose}
					type="sub category"
					subType="funAndLearn"
				/>
				// <CreateSubCategory handleClose={handleClose} />
			)}
		</div>
	);
};

export default Fun;
