import React, { useState, useEffect } from "react";
import Logo from "../../components/Logo";
import Img from "../../assets/loginimg.png";
import Bg2 from "../../assets/loginbg2.png";
import { LiaEyeSolid, LiaEyeSlash } from "react-icons/lia";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, login, setUserFail } from "../../data/Reducers/UserReducer";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import Button from "../../components/buttons/button";

const Login = () => {
	const [show, setShow] = useState(false),
		navigate = useNavigate(),
		init = {
			username: "",
			password: "",
		},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		{ auth } = useSelector(state => state),
		dispatch = useDispatch();

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
	}, [auth, submit, navigate]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.username || !state?.password) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/auth/login`, { ...state });
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			dispatch(login(res?.data?.data));
			dispatch(loadUser());
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			dispatch(setUserFail());
		}
		setLoading(false);
		setSubmit(true);
	};

	return (
		<div className="tw-h-screen tw-w-full tw-grid tw-grid-cols-2 tw-relative">
			<div className="loginBg1 tw-h-full tw-w-full tw-flex tw-pl-24 tw-items-center">
				<div className="tw-flex tw-justify-center tw-bg-white">
					<Logo />
				</div>
			</div>
			<div
				style={{
					background: `url(${Bg2})`,
				}}
				className="tw-h-full tw-relative">
				<div className="tw-bottom-0 tw-right-0 tw-absolute">
					<img src={Img} alt="" className="" />
				</div>
			</div>
			<div className="tw-absolute tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
				<div
					style={{
						boxShadow: "0px 4px 10px -10px rgba(0, 0, 0, 0.25)",
					}}
					className="lg:tw-w-1/3   tw-w-w-full tw-bg-white tw-mx-auto tw-px-8 tw-py-6">
					<div className="tw-flex tw-justify-center">
						<Logo />
					</div>
					<h2 className="lg:tw-text-2xl md:tw-text-xl tw-text-base tw-font-medium tw-pt-4 tw-text-[#252733] mulish tw-text-center">
						Log In to Your <br />
						Admin Dashboard
					</h2>
					<form action="" className="tw-mt-8">
						<div>
							<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[ #3E4347] mulish tw-uppercase">
								Telephone
							</span>
							<input
								type="text"
								placeholder="00000000000"
								name="username"
								onChange={textChange}
								className="tw-h-14 tw-pl-6 tw-w-full tw-bg-[#FCFDFE] tw-rounded-lg tw-border tw-border-[#CDCDCD] tw-text-sm mulish tw-font-medium tw-text-black placeholder:tw-text-opacity-40"
							/>
						</div>
						<div className="tw-mt-6">
							<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[ #3E4347] mulish tw-uppercase">
								Password
							</span>
							<div className="tw-relative tw-flex tw-h-14 tw-w-full tw-bg-[#FCFDFE] tw-rounded-lg tw-border tw-border-[#CDCDCD]">
								<input
									type={show ? "text" : "password"}
									placeholder="Password"
									name="password"
									onChange={textChange}
									className="tw-h-full tw-pl-6 tw-w-full focus:tw-border focus:tw-border-[#fcfdfe] tw-bg-[#FCFDFE] tw-text-sm mulish tw-font-medium tw-text-black placeholder:tw-text-opacity-40"
								/>
								<div
									onClick={() => setShow(!show)}
									className="tw-absolute tw-flex tw-h-full tw-items-center tw-right-2">
									{" "}
									{show ? (
										<IconContext.Provider value={{ color: "#000" }}>
											<LiaEyeSlash size={20} />
										</IconContext.Provider>
									) : (
										<IconContext.Provider value={{ color: "#000" }}>
											<LiaEyeSolid size={20} />
										</IconContext.Provider>
									)}
								</div>
							</div>
						</div>
						<h6 className="tw-font-normal tw-cursor-pointer tw-mt-4 mulish tw-text-base tw-text-[#9FA2B4] tw-text-center">
							Forgot password?
						</h6>
						<div className="tw-flex tw-mt-2 tw-justify-center">
							<Button
								onClick={handleSubmit}
								loading={loading}
								title={"Log In"}
								width={
									"tw-w-80 tw-bg-[#F7631B] tw-h-12 tw-rounded-lg tw-text-white tw-font-semibold mulish tw-text-sm"
								}
								style={{
									boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
								}}
							/>
						</div>
						<h6 className="tw-text-sm mulish tw-text-center tw-pt-3 tw-text-[#9FA2B4] tw-font-normal">
							Don’t have an account?{" "}
							<span className="tw-text-[#3751FF] tw-text-sm mulish tw-font-normal">
								Sign up
							</span>
						</h6>
					</form>
				</div>
			</div>
		</div>
	);
	// return (
	// 	<div className="tw-h-screen tw-w-full tw-grid tw-grid-cols-2 tw-relative">
	// 		<div className="loginBg1 tw-h-full tw-w-full tw-flex tw-pl-24 tw-items-center">
	// 			<Logo />
	// 		</div>
	// 		<div
	// 			style={{
	// 				background: `url(${Bg2})`,
	// 			}}
	// 			className="tw-h-full tw-relative">
	// 			<div className="tw-bottom-0 tw-right-0 tw-absolute">
	// 				<img src={Img} alt="" className="" />
	// 			</div>
	// 		</div>
	// 		<div className="tw-absolute tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
	// 			<div
	// 				style={{
	// 					boxShadow: "0px 4px 10px -10px rgba(0, 0, 0, 0.25)",
	// 				}}
	// 				className="lg:tw-w-1/3   tw-w-w-full tw-bg-white tw-mx-auto tw-px-8 tw-py-6">
	// 				<div className="tw-flex tw-justify-center">
	// 					<Logo2 />
	// 				</div>
	// 				<h2 className="lg:tw-text-2xl md:tw-text-xl tw-text-base tw-font-medium tw-pt-4 tw-text-[#252733] mulish tw-text-center">
	// 					Log In to Your <br />
	// 					Admin Dashboard
	// 				</h2>
	// 				<form action="" className="tw-mt-8">
	// 					<div>
	// 						<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[ #3E4347] mulish tw-uppercase">
	// 							email
	// 						</span>
	// 						<input
	// 							type="text"
	// 							placeholder="Email"
	// 							name="username"
	// 							onChange={textChange}
	// 							className="tw-h-14 tw-pl-6 tw-w-full tw-bg-[#FCFDFE] tw-rounded-lg tw-border tw-border-[#CDCDCD] tw-text-sm mulish tw-font-medium tw-text-black placeholder:tw-text-opacity-40"
	// 						/>
	// 					</div>
	// 					<div className="tw-mt-6">
	// 						<span className="lg:tw-text-base tw-text-sm tw-font-medium tw-text-[ #3E4347] mulish tw-uppercase">
	// 							Password
	// 						</span>
	// 						<div className="tw-relative tw-flex tw-h-14 tw-w-full tw-bg-[#FCFDFE] tw-rounded-lg tw-border tw-border-[#CDCDCD]">
	// 							<input
	// 								type={show ? "text" : "password"}
	// 								placeholder="Password"
	// 								name="password"
	// 								onChange={textChange}
	// 								className="tw-h-full tw-pl-6 tw-w-full focus:tw-border focus:tw-border-[#fcfdfe] tw-bg-[#FCFDFE] tw-text-sm mulish tw-font-medium tw-text-black placeholder:tw-text-opacity-40"
	// 							/>
	// 							<div
	// 								onClick={() => setShow(!show)}
	// 								className="tw-absolute tw-flex tw-h-full tw-items-center tw-right-2">
	// 								{" "}
	// 								{show ? (
	// 									<IconContext.Provider value={{ color: "#000" }}>
	// 										<LiaEyeSlash size={20} />
	// 									</IconContext.Provider>
	// 								) : (
	// 									<IconContext.Provider value={{ color: "#000" }}>
	// 										<LiaEyeSolid size={20} />
	// 									</IconContext.Provider>
	// 								)}
	// 							</div>
	// 						</div>
	// 					</div>
	// 					<h6 className="tw-font-normal tw-cursor-pointer tw-mt-4 mulish tw-text-base tw-text-[#9FA2B4] tw-text-center">
	// 						Forgot password?
	// 					</h6>
	// 					<div className="tw-flex tw-mt-2 tw-justify-center">
	// 						<Button
	// 							onClick={handleSubmit}
	// 							loading={loading}
	// 							title={"Log In"}
	// 							width={
	// 								"tw-w-80 tw-bg-[#F7631B] tw-h-12 tw-rounded-lg tw-text-white tw-font-semibold mulish tw-text-sm"
	// 							}
	// 							style={{
	// 								boxShadow: "0px 4px 12px 0px rgba(55, 81, 255, 0.24)",
	// 							}}
	// 						/>
	// 					</div>
	// 					<h6 className="tw-text-sm mulish tw-text-center tw-pt-3 tw-text-[#9FA2B4] tw-font-normal">
	// 						Don’t have an account?{" "}
	// 						<span className="tw-text-[#3751FF] tw-text-sm mulish tw-font-normal">
	// 							Sign up
	// 						</span>
	// 					</h6>
	// 				</form>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
};
export default Login;
