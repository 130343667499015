import React from "react";
import SelectInput from "../../components/inputs/selectinputs";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import UserLeaderBoardTable from "../../components/tables/user-lead-table";

const UserLeaderboard = () => {
  const Headers = ["last Updated", "date created", "email", "score", "rank"];
  const tableData = [
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
    {
      last_updated: "26/12/2008",
      date_created: "26/12/2008",
      email: "user@example.com",
      score: "50",
      rank: "1",
    },
  ];
  return (
    <div>
      <MainHeader text={"Leaderboard"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          Kaytwo's Leaderboard Activity
        </h2>
        <div className="tw-mt-5 tw-w-80">
          <SelectInput label={"Choose Category"}>
            <option value="monthly">Monthly</option>
            <option value="monthly">Monthly</option>
            <option value="monthly">Monthly</option>
          </SelectInput>
        </div>
        <div className="tw-mt-10">
          <UserLeaderBoardTable data={tableData} headers={Headers} />
        </div>
      </MainContainer>
    </div>
  );
};

export default UserLeaderboard;
