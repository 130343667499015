import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";

// import Textinput from "../../../components/inputs/textinputs";
import { useSelector } from "react-redux";
import { MapQuizIcons } from "../icons-settings";

const SystemUtilities = () => {
	// const Arr = [
	// 	{
	// 		title: "General Settings",
	// 		inputs: [
	// 			{
	// 				label: "Maximum winning coins",
	// 				type: "text",
	// 				placeholder: "10",
	// 			},
	// 			{
	// 				label: "maximum winning percentage",
	// 				type: "text",
	// 				placeholder: "10",
	// 			},
	// 			{
	// 				label: "maximum winning score",
	// 				type: "text",
	// 				placeholder: "10",
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Coin Settings",
	// 		inputs: [
	// 			{
	// 				label: "welcome bonus",
	// 				type: "text",
	// 				placeholder: "10 coins",
	// 			},
	// 			{
	// 				label: "life line",
	// 				type: "text",
	// 				placeholder: "10 coins",
	// 			},
	// 			{
	// 				label: "review answers",
	// 				type: "text",
	// 				placeholder: "10 coins",
	// 			},
	// 		],
	// 	},
	// ];

	let { settings } = useSelector(s => s);
	return (
		<div>
			<MainHeader text={"System Utilities"} />
			<MainContainer>
				<form action="" className="">
					<div className="tw-space-y-4">
						{/* {Arr.map(item => (
							<div className="">
								<h2 className="work tw-capitalize tw-text-main tw-font-normal tw-text-xl">
                {item.title}
								</h2>
								<div className="tw-mt-5 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-4">
									{item.inputs.map(item => (
										<div className="">
											<Textinput
												label={item.label}
												placeholder={item.placeholder}
												type={item.type}
											/>
										</div>
									))}
                  </div>
                  </div>
                ))} */}
						<h2 className="work tw-capitalize tw-text-main tw-font-normal tw-text-xl">
							Coin Settings
						</h2>
						<div className="tw-mt-5 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-4">
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Welcome Bonus",
									}}
									caseType="entryPoint"
									mainKey="welcomeBonus"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Skip Question",
									}}
									caseType="entryPoint"
									mainKey="skipQuestion"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Audience Poll",
									}}
									caseType="entryPoint"
									mainKey="audiencePoll"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Reset Time",
									}}
									caseType="entryPoint"
									mainKey="resetTime"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Review Coin",
									}}
									caseType="entryPoint"
									mainKey="reviewCoin"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Question Per Level",
									}}
									caseType="entryPoint"
									mainKey="questionPerLevel"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Hint Coin",
									}}
									caseType="entryPoint"
									mainKey="hint"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Reward Coin",
									}}
									caseType="entryPoint"
									mainKey="rewardCoin"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Earn Coin",
									}}
									caseType="entryPoint"
									mainKey="earnCoin"
									category="systemConfig"
								/>
							</div>
							<div className="">
								<MapQuizIcons
									item={{
										...settings?.configuration?.docs?.[0],
										name: "Referral Coin",
									}}
									caseType="entryPoint"
									mainKey="referralCoin"
									category="systemConfig"
								/>
							</div>
							{settings?.quizSettings?.docs?.map((item, i) => (
								<div key={i} className="">
									<MapQuizIcons item={item} caseType="entryPoint" />
								</div>
							))}
						</div>
						<div className="">
							<h2 className="work tw-capitalize tw-text-main tw-font-normal tw-text-xl">
								Time Settings
							</h2>
							<div className="tw-mt-5 tw-grid lg:tw-grid-cols-3 md:tw-grid-cols-2 tw-gap-4">
								{settings?.quizSettings?.docs?.map((item, i) => (
									<div key={i} className="">
										<MapQuizIcons item={item} caseType="quizTime" />
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-10">
						<button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
							Submit
						</button>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default SystemUtilities;
