import React, { useEffect, useState } from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import TextArea from "../inputs/textarea";
import Textinput from "../inputs/textinputs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Button from "../buttons/button";
import { manageFaqs } from "../../data/Reducers/FaqReducer";

const AddFaq = ({ handleClose, item }) => {
	let [state, setState] = useState({}),
		{ faq } = useSelector(s => s),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false);

	useEffect(() => {
		if (item) {
			setState({ ...state, ...item });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.question && !state?.answer)
			return toast.info("At least, Question/Answer is required");
		var newState = {};
		if (state?.question) newState.question = state?.question;
		if (state?.answer) newState.answer = state?.answer;
		setLoading(true);

		await dispatch(
			manageFaqs(item?._id ? "put" : "post", newState, item?._id || null)
		);
		setLoading(false);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(faq?.status)) {
			handleClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [faq?.status]);

	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-1/3 tw-mx-auto tw-p-8 tw-bg-[#FDFDFF] tw-rounded-xl">
					<div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
						<AiFillLeftCircle
							className="tw-cursor-pointer"
							size={20}
							onClick={handleClose}
						/>
						<h2 className="tw-text-base work tw-text-main tw-font-medium">
							{item ? "Update" : "Create"} new faq
						</h2>
					</div>
					<form action="" className="tw-mt-6">
						<div className="tw-space-y-4">
							<Textinput
								label={"Question"}
								placeholder={"Lorep ipsum"}
								name={"question"}
								value={state?.question}
								onChange={textChange}
							/>
							<TextArea
								placeholder={"Answer Body"}
								label={"Answer Body"}
								name={"answer"}
								value={state?.answer}
								onChange={textChange}
							/>
						</div>
						<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
							<Button
								loading={loading}
								onClick={handleSubmit}
								width="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white"
								title={item ? "Update Faq" : "Create Faq"}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default AddFaq;
