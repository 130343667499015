import React from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import Textinput from "../inputs/textinputs";
import TextArea from "../inputs/textarea";

const AddBadge = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer>
        <div className="lg:tw-w-1/3 tw-mx-auto tw-p-8 tw-h-[500px] tw-overflow-y-scroll noScroll tw-bg-[#FDFDFF] tw-rounded-xl">
          <div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
            <AiFillLeftCircle
              className="tw-cursor-pointer"
              size={20}
              onClick={handleClose}
            />
            <h2 className="tw-text-base work tw-text-main tw-font-medium">
              Create new badge
            </h2>
          </div>
          <form action="" className="tw-mt-5">
            <div className="tw-space-y-4">
              <Textinput label={"Badge Name"} placeholder={"Debut"} />
              <Textinput label={"Reward"} placeholder={"10 coins"} />
              <Textinput label={"Counter"} placeholder={"1"} />
              <Textinput
                label={"Choose Icon"}
                placeholder={"Debut"}
                type={"file"}
              />
              <TextArea label={"Note"} placeholder={"Write note"} />
            </div>
            <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
              <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
                Create Slider
              </button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default AddBadge;
