import React, { useEffect, useState } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Img from "../../../assets/questiontable.svg";
// import QuestionsTable from "../../../components/tables/questions-table";
import { useDispatch, useSelector } from "react-redux";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../../components/buttons/button";
import QuizZoneTable from "../../../components/tables/quiz-zone-table";
import { manageQuizZone } from "../../../data/Reducers/QuizZoneReducer";

const Questions = () => {
	const Headers = [
		"Image",
		"Question",
		"Category",
		"Sub-Category",
		"Answer",
		"Timeline",
		"Actions",
	];
	const tableData = [
		{
			img: Img,
			quiz_id: "25",
			category: "History",
			sub_category: "Gemeral Knowledge",
			total: "60",
			timeline: "100secs",
		},
		{
			img: Img,
			quiz_id: "25",
			category: "History",
			sub_category: "Gemeral Knowledge",
			total: "60",
			timeline: "100secs",
		},
		{
			img: Img,
			quiz_id: "25",
			category: "History",
			sub_category: "Gemeral Knowledge",
			total: "60",
			timeline: "100secs",
		},
		{
			img: Img,
			quiz_id: "25",
			category: "History",
			sub_category: "Gemeral Knowledge",
			total: "60",
			timeline: "100secs",
		},
		{
			img: Img,
			quiz_id: "25",
			category: "History",
			sub_category: "Gemeral Knowledge",
			total: "60",
			timeline: "100secs",
		},
	];

	let { quizZone } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(quizZone?.data);
	}, [quizZone?.data]);

	useEffect(() => {
		dispatch(manageQuizZone("get", null));
	}, [dispatch]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !quizZone?.data) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageQuizZone("get", datum));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"View Questions"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Manage Questions
				</h2>
				<div className="tw-mt-10">
					<QuizZoneTable data={currentItems || tableData} headers={Headers} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Questions;
