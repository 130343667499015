import React from "react";


const SelectInput = ({
	children,
	label,
	name,
	value,
	onChange,
	...otherProps
}) => {
	return (
		<div>
			<h3 className="tw-text-sm segoe tw-text-main tw-text-opacity-90">
				{label}
			</h3>
			<select
				value={value}
				name={name}
				onChange={onChange}
				className="tw-border tw-text-xs tw-font-normal tw-text-main tw-text-opacity-80 tw-border-[#ccc] tw-rounded-md tw-h-10 tw-mt-2 tw-w-full tw-px-4 tw-capitalize"
				id=""
				{...otherProps}>
				{children}
			</select>
		</div>
	);
};

export default SelectInput;
