import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import SelectInput from "../../../components/inputs/selectinputs";
import TextArea from "../../../components/inputs/textarea";
import Textinput from "../../../components/inputs/textinputs";
import { manageContestQuestions } from "../../../data/Reducers/ContestReducer";
import { manageFunAndLearnQuestion } from "../../../data/Reducers/FunNLearnReducer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Button from "../../../components/buttons/button";

const CreateFun = () => (
	<FunQuestion
		type="funAndLearn"
		title={"Fun And Learn"}
		subTitle="Create Fun And Learn"
	/>
);

export const FunQuestion = ({ type, title, subTitle }) => {
	let { funAndLearn, contest, language, category } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {
			title: "",
			description: "",
		},
		[state, setState] = useState(init),
		[updater, setUpdater] = useState(null),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		{ page } = useParams(),
		location = useLocation();

	useEffect(() => {
		if (location?.state) {
			setUpdater(location?.state);
			let newD = {},
				st = location?.state;
			// console.log({ st });
			if (st?.title) newD.title = st?.title;
			if (st?.description) newD.description = st?.description;
			if (st?.language) newD.language = st?.language?._id || st?.language;
			if (st?.category) newD.category = st?.category?._id || st?.category;
			if (st?.subCategory)
				newD.subCategory = st?.subCategory?._id || st?.subCategory;
			setState({ ...state, ...newD });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.title) return toast.info("Title is required");

		if (!state?.description) return toast.info("Description is required");

		let newState = {};

		if (state?.title) newState.title = state?.title;
		if (state?.description) newState.description = state?.description;
		// if (state?.language) newState.language = state?.language;
		if (state?.category) newState.category = state?.category;
		if (state?.subCategory) newState.subCategory = state?.subCategory;

		setLoading(true);
		if (type === "contest")
			await dispatch(
				manageContestQuestions(
					updater ? "put" : "post",
					newState,
					"types",
					updater?._id || null
				)
			);
		if (type === "funAndLearn")
			await dispatch(
				manageFunAndLearnQuestion(
					updater ? "put" : "post",
					newState,
					"types",
					updater?._id || null
				)
			);
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		navigate(`/${page}`);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(funAndLearn?.status)) {
			reset();
		}
		if (["added", "updated"]?.includes(contest?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funAndLearn?.status, contest?.status]);

	return (
		<div>
			<MainHeader text={title || "Create Questions"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					{subTitle || "Create Questions"}
				</h2>
				<form action="" className="tw-mt-8">
					<h3 className="tw-text-lg tw-text-main tw-font-normal segoe">
						Create Questions
					</h3>
					<div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
						<SelectInput
							label={"Choose Language"}
							onChange={textChange}
							value={state?.language}
							name={"language"}>
							<option value="" className="">
								Select Language
							</option>
							{language?.all?.docs?.map((it, i) => (
								<option key={i} value={it?._id}>
									{it?.name}
								</option>
							))}
						</SelectInput>
						<SelectInput
							value={state?.category}
							name="category"
							onChange={textChange}
							label={"Choose a Category"}>
							{" "}
							<option value="">Select a Category</option>
							{category?.all?.docs
								?.filter(it => !it?.parentCategory && type === it?.type)
								?.map((it, i) => (
									<option key={i} value={it?._id}>
										{it?.name}
									</option>
								))}
						</SelectInput>
						{state?.category && (
							<SelectInput
								value={state?.subCategory}
								name="subCategory"
								onChange={textChange}
								label={"Choose Sub Category"}>
								{" "}
								<option value="">Select a Sub Category</option>
								{category?.all?.docs
									?.filter(
										it =>
											it?.parentCategory &&
											state?.category === it?.parentCategory &&
											it?.type === type
									)
									?.map((it, i) => (
										<option key={i} value={it?._id}>
											{it?.name}
										</option>
									))}
							</SelectInput>
						)}
					</div>
					<div className="tw-mt-8">
						<Textinput
							label={"Title"}
							placeholder={"Title"}
							name="title"
							onChange={textChange}
							value={state?.title}
						/>
					</div>
					<div className="tw-mt-8">
						<TextArea
							label={"Description"}
							placeholder={"Description"}
							name="description"
							onChange={textChange}
							value={state?.description}
						/>
					</div>
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<Button
							loading={loading}
							onClick={handleSubmit}
							width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
							title={updater ? "Update" : "Submit"}
						/>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default CreateFun;
