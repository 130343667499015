import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextArea = ({
	name,
	label,
	placeholder,
	value,
	onChange,
	setState,
	state,
	...rest
}) => {
	return (
		<div>
			<h3 className="tw-text-sm segoe tw-text-main tw-text-opacity-90">
				{label}
			</h3>
			{rest?.usecase === "editor" ? (
				<ReactQuill
					theme="snow"
					value={value}
					onChange={e => setState({ ...state, [name]: e })}
				/>
			) : (
				<textarea
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					id=""
					cols="30"
					rows="10"
					value={value}
					className="tw-h-28 tw-w-full placeholder:tw-pt-4 placeholder:tw-pl-6 tw-mt-4 tw-bg-white tw-rounded-xl tw-border tw-border-[#ccc] tw-text-xs tw-font-normal tw-text-mainp tw-p-2"
					{...rest}
				/>
			)}
		</div>
	);
};

export default TextArea;
