import { createSlice } from "@reduxjs/toolkit";
import { DeleteData, EditData } from "./UserReducer";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	all: null,
	types: null,
	allTypes: null,
};

export const funAndLearnQuestionSlice = createSlice({
	name: "funAndLearnQuestion",
	initialState,
	reducers: {
		getSearchFunAndLearnQuestion: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetFunAndLearnQuestionSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getFunAndLearnQuestion: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAllFunAndLearnQuestion: (state, { payload }) => {
			state.all = payload?.data || payload;
		},
		getFunAndLearnTypes: (state, { payload }) => {
			state.types = payload?.data || payload;
		},
		getAllFunAndLearnTypes: (state, { payload }) => {
			state.allTypes = payload?.data || payload;
		},
		addFunAndLearnQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
				totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs ? 1 + state?.all?.totalDocs : 1,
			};
			state.status = "added";
		},
		addFunAndLearnBatch: (state, { payload }) => {
			let data = payload?.data || payload;
			state.data = {
				...state?.data,
				docs: state?.data?.docs ? [...data, ...state?.data?.docs] : [...data],
				totalDocs: state?.data?.totalDocs
					? data?.length + state?.data?.totalDocs
					: data?.length,
			};
			state.all = {
				...state?.all,
				docs: state?.all?.docs ? [...data, ...state?.all?.docs] : [data],
				totalDocs: state?.all?.totalDocs
					? data?.length + state?.all?.totalDocs
					: data?.length,
			};
			state.status = "added";
		},
		addFunAndLearnTypes: (state, { payload }) => {
			let data = payload?.data || payload;
			state.types = {
				...state?.types,
				docs: state?.types?.docs ? [data, ...state?.types?.docs] : [data],
				totalDocs: state?.types?.totalDocs ? 1 + state?.types?.totalDocs : 1,
			};
			state.status = "added";
		},
		deleteFunAndLearnQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.data = {
				...state?.data,
				docs: DeleteData(state?.data?.docs, data),
				totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
			};
		},
		deleteFunAndLearnTypes: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "deleted";
			state.types = {
				...state?.types,
				docs: DeleteData(state?.types?.docs, data),
				totalDocs: state?.types?.totalDocs ? state?.types?.totalDocs - 1 : 0,
			};
			state.all = {
				...state?.all,
				docs: DeleteData(state?.all?.docs, data),
				totalDocs: state?.all?.totalDocs ? state?.all?.totalDocs - 1 : 0,
			};
		},
		updateFunAndLearnQuestion: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.data = { ...state?.data, docs: EditData(state?.data?.docs, data) };
			state.all = { ...state?.all, docs: EditData(state?.all?.docs, data) };
		},
		updateFunAndLearnTypes: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.types = {
				...state?.types,
				docs: EditData(state?.types?.docs, data),
			};
		},
		funAndLearnQuestionFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutFunAndLearnQuestion: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
			state.allTypes = null;
			state.types = null;
		},
	},
});
export const {
	funAndLearnQuestionFail,
	getOrg,
	getFunAndLearnQuestion,
	deleteFunAndLearnQuestion,
	addFunAndLearnQuestion,
	getSearchFunAndLearnQuestion,
	getSearch,
	resetFunAndLearnQuestionSearch,
	updateFunAndLearnQuestion,
	logoutFunAndLearnQuestion,
	getAllFunAndLearnQuestion,
	getAllFunAndLearnTypes,
	getFunAndLearnTypes,
	addFunAndLearnTypes,
	updateFunAndLearnTypes,
	deleteFunAndLearnTypes,
	addFunAndLearnBatch
} = funAndLearnQuestionSlice.actions;

export const manageFunAndLearnQuestion = (type, data, third, id) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (!third) {
			if (type === "get") {
				res = await axios.get(
					`/api/v1/funAndLearnQuestion?_populate=category&_populate=subCategory&_populate=image&_populate=language&_populate=typeID${
						data?.limit ? `&_limit=${data?.limit}` : ""
					}`
				);
				dispatch(getFunAndLearnQuestion(res?.data));
				// if (!data) {
				// 	let res2 = await axios.get(
				// 		`/api/v1/funAndLearnQuestion?_populate=category&_populate=subCategory&_populate=image&_populate=language&_populate=typeID&_limit=0`
				// 	);
				// 	dispatch(getAllFunAndLearnQuestion(res2?.data));
				// }
			}
			if (type === "post") {
				res = await axios.post(`/api/v1/funAndLearnQuestion`, { ...data });
				let res2 = await axios.get(
					`/api/v1/funAndLearnQuestion/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language&_populate=typeID`
				);
				dispatch(addFunAndLearnQuestion(res2?.data));
			}
			if (type === "batch") {
				res = await axios.post(`/api/v1/funAndLearnQuestion/batch`, { ...data });

				dispatch(addFunAndLearnBatch(res?.data));
			}
			if (type === "put") {
				let newData = data;
				delete newData?._id;
				delete newData?.id;
				res = await axios.put(
					`/api/v1/funAndLearnQuestion/${data?._id || data?.id || id}`,
					{
						...newData,
					}
				);
				let res2 = await axios.get(
					`/api/v1/funAndLearnQuestion/${res?.data?.data?._id}?_populate=category&_populate=subCategory&_populate=image&_populate=language&_populate=typeID`
				);
				dispatch(updateFunAndLearnQuestion(res2?.data));
			}
			if (type === "delete") {
				res = await axios.delete(
					`/api/v1/funAndLearnQuestion/${data?._id}`
				);
				dispatch(deleteFunAndLearnQuestion(data));
			}
		} else {
			if (type === "get") {
				res = await axios.get(
					`/api/v1/funAndLearn?_populate=category&_populate=subCategory${
						data?.limit ? `&_limit=${data?.limit}` : ""
					}`
				);
				dispatch(getFunAndLearnTypes(res?.data));
				// if (!data) {
				// 	let res2 = await axios.get(
				// 		`/api/v1/funAndLearn?_populate=category&_populate=subCategory&_limit=0`
				// 	);
				// 	dispatch(getAllFunAndLearnTypes(res2?.data));
				// }
			}
			if (type === "post") {
				res = await axios.post(`/api/v1/funAndLearn`, { ...data });
				let res2 = await axios.get(
					`/api/v1/funAndLearn/${res?.data?.data?._id}?_populate=category&_populate=subCategory`
				);
				dispatch(addFunAndLearnTypes(res2?.data));
			}
			if (type === "put") {
				let newData = data;
				delete newData?._id;
				delete newData?.id;
				res = await axios.put(
					`/api/v1/funAndLearn/${data?._id || data?.id || id}`,
					{
						...newData,
					}
				);
				let res2 = await axios.get(
					`/api/v1/funAndLearn/${res?.data?.data?._id}?_populate=category&_populate=subCategory`
				);
				dispatch(updateFunAndLearnTypes(res2?.data));
			}
			if (type === "delete") {
				res = await axios.delete(`/api/v1/funAndLearn/${data?._id}`);
				dispatch(deleteFunAndLearnTypes(data));
			}
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(funAndLearnQuestionFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(funAndLearnQuestionFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
