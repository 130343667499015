import React from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
// import SelectInputs from '../inputs/selectinputs'
import SelectInput from "../inputs/selectinputs";

const AddNewUsers = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer>
        <div className="lg:tw-w-1/2 tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg">
          <div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
            <AiFillLeftCircle
              className="tw-cursor-pointer"
              size={20}
              onClick={handleClose}
            />
            <h2 className="tw-text-base work tw-text-main tw-font-medium">
              Create new user
            </h2>
          </div>
          <form action="" className="tw-mt-5">
            <div className="tw-space-y-4">
              <SelectInput label={"User ID"}>
                <option value="1">1</option>
                <option value="1">2</option>
                <option value="1">3</option>
              </SelectInput>
              <SelectInput label={"Choose role"}>
                <option value="1">1</option>
                <option value="1">2</option>
                <option value="1">3</option>s
              </SelectInput>
            </div>
            <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
              <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
                Create User
              </button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default AddNewUsers;
