import { createSlice } from "@reduxjs/toolkit";
import { clearErrors, returnErrors } from "./ErrorReducer";
import axios from "axios";
import { toast } from "react-toastify";

let initialState = {
	data: null,
	search: "",
	mainSearch: null,
	isFound: null,
	status: "",
	daily: null,
	monthly: null,
	admins: null,
};

export const playerSlice = createSlice({
	name: "player",
	initialState,
	reducers: {
		getSearchPlayer: (state, { payload }) => {
			state.mainSearch = payload?.data || payload || state?.mainSearch;
			state.isFound = true;
		},
		getSearch: (state, { payload }) => {
			state.search = payload?.search || payload;
		},
		resetPlayerSearch: state => {
			state.search = "";
			state.mainSearch = null;
			state.isFound = false;
		},
		getPlayer: (state, { payload }) => {
			state.data = payload?.data || payload;
		},
		getAdmin: (state, { payload }) => {
			state.admins = payload?.data || payload;
		},
		addAdmin: (state, { payload }) => {
			state.admins = {
				...state?.admins,
				docs: state?.admins?.docs
					? [payload?.data || payload, ...state?.admins?.docs]
					: [payload?.data || payload],
				totalDocs: state?.admins?.totalDocs ? 1 + state?.admins?.totalDocs : 1,
			};
		},
		getDailyPlayer: (state, { payload }) => {
			state.daily = payload?.data || payload;
		},
		getMonthlyPlayer: (state, { payload }) => {
			state.monthly = payload?.data || payload;
		},
		playerFail: state => {
			state.status = "";
			state.isFound = false;
		},
		logoutPlayer: state => {
			state.data = null;
			state.search = "";
			state.mainSearch = null;
			state.isFound = null;
			state.all = null;
		},
	},
});

export const {
	playerFail,
	getOrg,
	getPlayer,
	getSearchPlayer,
	getSearch,
	resetPlayerSearch,
	logoutPlayer,
	getDailyPlayer,
	getMonthlyPlayer,
	getAdmin,
	addAdmin,
} = playerSlice.actions;

export const managePlayer = (type, data) => async dispatch => {
	dispatch(clearErrors());
	try {
		let res;

		if (type === "get") {
			res = await axios.get(
				`/api/v1/user${data?.admin ? `/admin` : ""}?_populate=profile${
					data?.limit ? `&_limit=${data?.limit}` : ""
				}`
			);
			dispatch(data?.admin ? getAdmin(res?.data) : getPlayer(res?.data));
		}
		if (type !== "get") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(playerFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(playerFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
