import React from "react";
import { useState } from "react";
import Line from "../../../assets/more - vertical.svg";
import { useNavigate } from "react-router-dom";

const ReportTable = ({ headers, data }) => {
  const [modal, SetModal] = useState(false),
    navigate = useNavigate(),
    [active, SetActive] = useState(null);
  const handleModal = (i) => {
    SetActive(i);
    SetModal(!modal);
  };
  return (
    <div className="tw-overflow-x-auto">
      <table className="tw-min-w-full tw-table-auto">
        <thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
          <tr>
            {headers.map((item, i) => (
              <th
                key={i}
                className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3"
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="tw-divide-y-4">
          {data.map((item, i) => (
            <tr
              style={{
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                marginBottom: "10px !important",
              }}
              key={i}
              className="work tw-bg-white tw-h-12 tw-mt-8"
            >
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.quiz_id}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.name}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.date}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.question}
              </td>
              <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
                {item?.message}
              </td>
              <td className="tw-relative">
                <img
                  onClick={() => handleModal(i)}
                  src={Line}
                  alt=""
                  className="tw-mx-auto tw-cursor-pointer"
                />
                {active === i && modal && (
                  <div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
                    <h6
                      onClick={() => navigate("/quiz-zone/edit-question")}
                      className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor"
                    >
                      EDIT report
                    </h6>
                    <h6 className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
                      delete report
                    </h6>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
