import React from "react";
import Icon from "../../assets/check.svg";
import { ModalContainer } from "./modalcontainer";
import { AiFillCloseCircle } from "react-icons/ai";

const DeletedModal = ({ handleClose, text }) => {
  return (
    <div>
      <ModalContainer>
        <div className="tw-w-60 tw-p-6 tw-rounded-lg tw-bg-white tw-shadow-lg">
          <AiFillCloseCircle
            size={20}
            className="tw-cursor-pointer"
            onClick={handleClose}
          />
          <img src={Icon} alt="" className="tw-mx-auto" />
          <p className="tw-text-base tw-pt-2 tw-text-center work tw-font-normal tw-text-main">
            {text} deleted <br /> successfully!
          </p>
        </div>
      </ModalContainer>
    </div>
  );
};

export default DeletedModal;
