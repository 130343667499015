import React, { useEffect } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import { useState } from "react";
import LanguageTable from "../../../components/tables/language-table";
import AddLanguage from "../../../components/modals/add-language";
import { useSelector, useDispatch } from "react-redux";
import { LoadingLoader } from "../../../components/buttons/button";
import { manageLanguage } from "../../../data/Reducers/LanguagesReducer";

const LanguageSettings = () => {
	const [modal, setModal] = useState(),
		handleClose = () => {
			setModal("");
		};
	const Headers = ["ID", "Language", "Actions"];
	const tableData = [
		{
			id: "25",
			language: "English",
			enable: true,
		},
		{
			id: "25",
			language: "English",
			enable: true,
		},
		{
			id: "25",
			language: "English",
			enable: true,
		},
		{
			id: "25",
			language: "English",
			enable: true,
		},
		{
			id: "25",
			language: "English",
			enable: false,
		},
		{
			id: "25",
			language: "English",
			enable: true,
		},
	];

	let { language } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(language?.data);
	}, [language?.data]);

	useEffect(() => {
		dispatch(manageLanguage("get", null));
	}, [dispatch]);

	if (!data && !language?.data) return <LoadingLoader />;

	return (
		<div>
			<MainHeader text={"Languages"} />
			<MainContainer>
				<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-y-0 tw-spacey-5 tw-justify-between tw-items-center">
					<h2 className="work tw-text-main tw-font-normal tw-text-xl">
						Manage Languages
					</h2>
					<button
						onClick={() => setModal("add-new-language")}
						className="tw-h-11 tw-w-40 tw-rounded btngradient tw-text-base tw-font-normal tw-text-white work">
						Add new language
					</button>
				</div>
				<div className="tw-mt-10">
					<LanguageTable data={data?.docs || tableData} headers={Headers} />
				</div>
			</MainContainer>
			{modal === "add-new-language" && (
				<AddLanguage handleClose={handleClose} />
			)}
		</div>
	);
};

export default LanguageSettings;
