import React from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillCloseCircle } from "react-icons/ai";
import Line from "../../assets/more - vertical.svg";
import SelectInput from "../inputs/selectinputs";
import Textinput from "../inputs/textinputs";
import ModalBtn from "../buttons/modalbtn";

const CreateSubCategory = ({ handleClose }) => {
  return (
    <div>
      <ModalContainer>
        <div className="lg:tw-w-1/3 tw-mx-auto myShadow tw-bg-white tw-rounded-xl tw-p-8">
          <AiFillCloseCircle
            size={20}
            className="tw-cursor-pointer"
            onClick={handleClose}
          />
          <div className="tw-flex tw-justify-between tw-mt-4">
            <h2 className="segoe tw-text-xl tw-font-bold tw-text-text-main">
              Create Sub Category
            </h2>
            {/* <img src={Line} alt="line" className="tw-cursor-pointer" /> */}
          </div>
          <form action="" className="tw-mt-6">
            <div className="tw-space-y-3">
              <SelectInput label={"Choose a category"} name={"category"}>
                <option value="english">English</option>
                <option value="french">French</option>
                <option value="english">English</option>
              </SelectInput>
              <SelectInput
                label={"Choose a Sub-Category"}
                name={"sub-category"}
              >
                <option value="english">English</option>
                <option value="french">French</option>
                <option value="english">English</option>
              </SelectInput>
              <Textinput
                type={"text"}
                name={"sub-category-name"}
                placeholder={"Sub-Category Name"}
                label={"Sub-Category Name"}
              />
              <Textinput
                type={"file"}
                name={"sub-category-image"}
                placeholder={"Sub-Category Name"}
                label={"Choose Image"}
              />
              <div className="tw-mt-6">
                <ModalBtn text={"Create"} />
              </div>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default CreateSubCategory;
