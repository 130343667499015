import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "./ErrorReducer";
import { EditData } from "./UserReducer";

let initialState = {
	settings: null,
	configuration: null,
	status: "",
	quizSettings: null,
};

export const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		getSettings: (state, { payload }) => {
			state.settings = payload?.data || payload;
		},
		updateSettings: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.settings = {
				...state?.settings,
				docs: EditData(state?.settings?.docs, data),
			};
		},
		getQuizSettings: (state, { payload }) => {
			state.quizSettings = payload?.data || payload;
		},
		updateQuizSettings: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.quizSettings = {
				...state?.quizSettings,
				docs: EditData(state?.quizSettings?.docs, data),
			};
		},
		getConfig: (state, { payload }) => {
			state.configuration = payload?.data || payload;
		},
		updateConfig: (state, { payload }) => {
			let data = payload?.data || payload;
			state.status = "updated";
			state.configuration = {
				...state?.configuration,
				docs: EditData(state?.configuration?.docs, data),
			};
		},
		settingsFail: state => {
			state.status = "";
		},
	},
});

export const {
	getConfig,
	getSettings,
	settingsFail,
	updateConfig,
	updateSettings,
	getQuizSettings,
	updateQuizSettings,
} = settingsSlice.actions;

export const manageSettings = (type, data, usecase, id) => async dispatch => {
	try {
		let res;

		if (type === "get") {
			res = await axios.get(`/api/v1/staticSettings`);
			dispatch(getSettings(res?.data));
			let res3 = await axios.get(`/api/v1/systemConfig`);
			dispatch(getConfig(res3?.data));
			let res4 = await axios.get(`/api/v1/quizSettings?_populate=icon`);
			dispatch(getQuizSettings(res4?.data));
		}
		if (type === "put") {
			let newData = data;
			delete newData?._id;
			delete newData?.id;
			if (usecase === "staticSettings") {
				res = await axios.post(`/api/v1/staticSettings`, {
					...newData,
				});
				dispatch(updateSettings(res?.data));
			}
			if (usecase === "systemConfig") {
				res = await axios.put(
					`/api/v1/systemConfig/${data?._id || data?.id || id}`,
					{
						...newData,
					}
				);
				dispatch(updateConfig(res?.data));
			}
			if (usecase === "quizSettings") {
				res = await axios.put(
					`/api/v1/quizSettings/${data?._id || data?.id || id}`,
					{
						...newData,
					}
				);
				dispatch(updateQuizSettings(res?.data));
			}
		}
		if (type === "put") {
			toast.success(res?.data?.message);
			setTimeout(() => {
				dispatch(settingsFail());
			}, 1000);
		}
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		dispatch(settingsFail());
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
	}
};
