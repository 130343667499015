import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import TextInputs from "../../../components/inputs/textinputs";
import TextArea from "../../../components/inputs/textarea";

const PaymentsSettings = () => {
  const Inputs = [
    {
      label: "Coins equivalent",
      placeholder: "10 coins",
    },
    {
      label: "Amount equivalent",
      placeholder: "$1",
    },
    {
      label: "Minimum amount redeemable",
      placeholder: "$10",
    },
    {
      label: "Amount equivalent",
      placeholder: "$1",
    },
  ];
  return (
    <div>
      <MainHeader text="Payments Settings" />
      <MainContainer>
        <div className="tw-flex tw-gap-4 tw-items-center">
          <h2 className="work tw-text-main tw-font-normal tw-text-xl">
            Payments
          </h2>
          <div className="tw-flex tw-gap-2 tw-items-cent">
            <input
              type="checkbox"
              name="disable"
              className="tw-w-5 tw-h-5"
              id=""
            />
            <h6 className="tw-text-sm tw-text-black nunito tw-font-normal">
              Disable
            </h6>
          </div>
        </div>
        <div className="tw-mt-10">
          <form action="">
            <div className="lg:tw-w-3/4 tw-grid md:tw-grid-cols-2 tw-gap-5">
              {Inputs.map((item, i) => (
                <div key={i} className="">
                  <TextInputs
                    label={item.label}
                    placeholder={item.placeholder}
                    type={"text"}
                  />
                </div>
              ))}
            </div>
            <div className="tw-mt-6">
              <TextArea label="Message" placeholder={"Write message here"} />
            </div>
            <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-10">
              <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
                Submit
              </button>
            </div>
          </form>
        </div>
      </MainContainer>
    </div>
  );
};

export default PaymentsSettings;
