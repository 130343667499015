import React, { useEffect } from "react";
import MainHeader from "../../../components/mainheader/";
import MainContainer from "../../../components/maincontainer";
import SliderTable from "../../../components/tables/sliders-table";
import { useState } from "react";
import AddSlider from "../../../components/modals/add-slider";
import { useDispatch, useSelector } from "react-redux";
import { manageSliders } from "../../../data/Reducers/SliderReducer";
import { LoadingLoader } from "../../../components/buttons/button";

const Sliders = () => {
	const [modal, setModal] = useState(""),
		handleClose = () => {
			setModal("");
		};
	const Headers = [
		"image",
		// "ID",
		"language",
		"title",
		"description",
		"actions",
	];
	const tableData = [
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
		{
			id: "25",
			language: "English",
			title: "Lorem ipsum",
			description: "Microsoft",
		},
	];

	let { slider } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		setData(slider?.data);
	}, [slider?.data]);

	useEffect(() => {
		dispatch(manageSliders("get", null));
	}, [dispatch]);

	if (!data && !slider?.data) return <LoadingLoader />;

	return (
		<div>
			<MainHeader text={"Sliders"} />
			<MainContainer>
				<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-y-0 tw-spacey-5 tw-justify-between tw-items-center">
					<h2 className="work tw-text-main tw-font-normal tw-text-xl">
						Manage Sliders
					</h2>
					<button
						onClick={() => setModal("add-new-slider")}
						className="tw-h-11 tw-w-40 tw-rounded btngradient tw-text-base tw-font-normal tw-text-white work">
						Add new slider
					</button>
				</div>
				<div className="tw-mt-10">
					<SliderTable data={data?.docs || tableData} headers={Headers} />
				</div>
			</MainContainer>
			{modal === "add-new-slider" && <AddSlider handleClose={handleClose} />}
		</div>
	);
};

export default Sliders;
