import React, { useState, useEffect, useContext } from "react";
import MainHeader from "../../components/mainheader";
import Total from "../../assets/totalico.svg";
import Question from "../../assets/questionico.svg";
import Subico from "../../assets/subico.svg";
import Fun from "../../assets/funico.svg";
import Guess from "../../assets/guessico.svg";
import Manage from "../../assets/manageico.svg";
import Contest from "../../assets/contest.svg";
import AreaChart from "../../components/charts/areachart";
import BarChart from "../../components/charts/barchart";
// import CreateSubCategory from "../../components/modals/create-sub-category";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../components/maincontainer";
import { useSelector } from "react-redux";
import { LoadingLoader } from "../../components/buttons/button";
import { MainUsers } from "../users";
import { GlobalState } from "../../data/Context";
// import StatusModal from "../../components/modals/status-modal";

const Dashboard = () => {
	const [total, setTotal] = useState(0),
		navigate = useNavigate();

	let {
			category,
			contest,
			guessTheWord,
			funAndLearn,
			audioQuiz,
			mathQuiz,
			quizZone,
			dailyQuiz,
			exam,
			player,
		} = useSelector(s => s),
		{ numberWithCommas } = useContext(GlobalState);

	useEffect(() => {
		setTotal(
			(funAndLearn?.data?.totalDocs || 0) +
				(contest?.data?.totalDocs || 0) +
				(audioQuiz?.data?.totalDocs || 0) +
				(mathQuiz?.data?.totalDocs || 0) +
				(quizZone?.data?.totalDocs || 0) +
				(dailyQuiz?.data?.totalDocs || 0) +
				(exam?.data?.totalDocs || 0) +
				(guessTheWord?.data?.totalDocs || 0)
		);
	}, [
		contest,
		guessTheWord,
		funAndLearn,
		audioQuiz,
		mathQuiz,
		quizZone,
		dailyQuiz,
		exam,
	]);

	const Arr = [
		{
			name: "manage Categories",
			img: Manage,
			value: numberWithCommas(category?.data?.totalDocs || 0),
			action: () => navigate("/category"),
		},
		{
			name: "Categories",
			img: Manage,
			value: numberWithCommas(
				category?.all?.docs?.filter(it => !it?.parentCategory)?.length ||
					category?.data?.docs?.filter(it => !it?.parentCategory)?.length ||
					0
			),
			action: () => navigate("/category", { state: "category" }),
		},
		{
			name: "sub Categories",
			img: Subico,
			value: numberWithCommas(
				category?.all?.docs?.filter(it => it?.parentCategory)?.length ||
					category?.data?.docs?.filter(it => it?.parentCategory)?.length ||
					0
			),
			action: () => navigate("/category", { state: "sub category" }),
		},
		{
			name: "total users",
			img: Total,
			value: numberWithCommas(player?.data?.totalDocs || 0),
			action: () => navigate("/users"),
		},
		{
			name: "questions",
			img: Question,
			value: numberWithCommas(total || 0),
			action: () => navigate("/overview/questions"),
		},
		{
			name: "Contest",
			img: Contest,
			value: numberWithCommas(contest?.data?.totalDocs || 0),
			action: () => navigate("/contest"),
		},
		{
			name: "fun n learn",
			img: Fun,
			value: numberWithCommas(funAndLearn?.data?.totalDocs || 0),
			action: () => navigate("/fun"),
		},
		{
			name: "guess the word",
			img: Guess,
			value: numberWithCommas(guessTheWord?.data?.totalDocs || 0),
			action: () => navigate("/guess-the-word"),
		},
	];
	if (!category?.data) return <LoadingLoader />;

	return (
		<div className="">
			<MainHeader text={"Overview"} />
			<MainContainer>
				<div className="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-5">
					{Arr.map((item, i) => (
						<div
							onClick={item.action}
							key={i}
							className="myShadow2 tw-cursor-pointer tw-bg-white tw-h-24 tw-gap-3 tw-rounded-md tw-pl-3 tw-flex tw-items-center">
							<img src={item.img} alt={item.name} className="" />
							<div>
								<h2 className="sansation tw-text-main tw-capitalize tw-text-base tw-font-bold">
									{item.name}
								</h2>
								<span className="tw-text-[15px] tw-font-normal tw-text-main sansation">
									{item.value}
								</span>
							</div>
						</div>
					))}
				</div>
				<Engagements />
				<TableSection />
			</MainContainer>
		</div>
	);
};
const Engagements = () => {
	return (
		<section>
			<div className="tw-mt-10">
				<h1 className="tw-font-medium mulish tw-text-lg tw-text-graay">
					Engagement Activities
				</h1>
				<div className="tw-flex lg:tw-flex-row tw-flex-col tw-gap-4 tw-mt-10">
					<div className="lg:tw-w-[65%] tw-bg-white tw-p-3 tw-rounded-xl">
						<h4 className="tw-text-sm mulish tw-font-normal tw-text-main">
							Users Registration
						</h4>
						<AreaChart />
					</div>
					<div className="lg:tw-w-[35%] tw-p-3 tw-bg-white tw-rounded-xl">
						<BarChart />
					</div>
				</div>
			</div>
		</section>
	);
};

const TableSection = () => {
	return <MainUsers hide={"hide"} />;
};
export default Dashboard;
