import React from "react";
import Search from "../../assets/search.svg";
import Notification from "../../assets/notification.svg";
import Profile from "../../assets/profile.svg";
import { useSelector } from "react-redux";

const MainHeader = ({ text }) => {
	let { auth } = useSelector(s => s);
	return (
		<div className="myShadow md:tw-px-10 tw-px-3 tw-flex tw-gap-5 tw-justify-between tw-items-center tw-bg-white tw-py-6">
			<h1 className="tw-font-bold tw-text-graay md:tw-text-2xl poppins tw-tracking-[0.3px]">
				{text}
			</h1>
			<div className="tw-flex tw-gap-5 tw-items-center">
				<img src={Search} alt="" className="" />
				<img src={Notification} alt="" className="" />
				<div className="tw-flex tw-items-center tw-gap-2">
					<div>
						<h2 className="md:tw-text-base tw-text-xs mulish tw-font-semibold tw-text-graay tw-capitalize">
							{auth?.user?.firstName} {auth?.user?.lastName}
						</h2>
						<span className="tw-text-xs mulish tw-font-semibold tw-text-secondary tw-uppercase">
							{auth?.user?.type}
						</span>
					</div>
					<img
						src={auth?.user?.avatar || Profile}
						alt=""
						className="lg:tw-h-full tw-h-8"
					/>
				</div>
			</div>
		</div>
	);
};

export default MainHeader;
