import React, { useState, useEffect } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import SelectInput from "../../components/inputs/selectinputs";
import LeaderboardTable from "../../components/tables/leaderboard-table";
import { manageLeaderboard } from "../../data/Reducers/LeaderboardReducer";
import { LoadingLoader } from "../../components/buttons/button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LeaderBoard = () => {
	const Headers = [
		"Name",
		"last Updated",
		"date created",
		"email",
		"score",
		"rank",
	];
	// const tableData = [
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// 	{
	// 		id: "25",
	// 		name: "C . Name",
	// 		last_updated: "26/12/2008",
	// 		date_created: "26/12/2008",
	// 		email: "user@example.com",
	// 		score: "50",
	// 		rank: "1",
	// 	},
	// ];

	let [type, setType] = useState("all");

	let { leaderboard, auth } = useSelector(s => s),
		[state, setState] = useState(null),
		dispatch = useDispatch(),
		navigate = useNavigate();

	useEffect(() => {
		setState(
			type === "monthly"
				? leaderboard?.monthly
				: type === "daily"
				? leaderboard?.daily
				: leaderboard?.data
		);
	}, [leaderboard?.data, type, leaderboard?.daily, leaderboard?.monthly]);

	useEffect(() => {
		dispatch(manageLeaderboard("get", { type }));
	}, [dispatch, type]);

	useEffect(() => {
		if (!auth?.isAuth) navigate(1);
	}, [auth?.isAuth, navigate]);

	if (!state && !leaderboard?.data) return <LoadingLoader />;
	// console.log({ state });
	return (
		<div>
			<MainHeader text={"Leaderboard"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Leaderboard Activity
				</h2>
				<div className="tw-mt-5 tw-w-80">
					<SelectInput
						value={type}
						onChange={e => setType(e?.target?.value)}
						label={"Choose Category"}>
						<option value="">All Time</option>
						<option value="daily">Daily</option>
						<option value="monthly">Monthly</option>
					</SelectInput>
				</div>
				<div className="tw-mt-10">
					<LeaderboardTable data={state} headers={Headers} />
				</div>
			</MainContainer>
		</div>
	);
};

export default LeaderBoard;
