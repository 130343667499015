import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";
import Logo from "../Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Logout from "../../assets/sidebar-icons/logout.svg";
import { logout } from "../../data/Reducers/UserReducer";
import { AiOutlineMenu } from "react-icons/ai";
import Setting from "../../assets/sidebar-icons/settings.svg";
import SettingActive from "../../assets/sidebar-icons/settings-active.svg";
import { useState } from "react";

const Sidebar = ({ children }) => {
  const [settings, setSettings] = useState(false);
  const { nav, toggleNav, auth, sidebarList, SettingList } =
    useContext(GlobalState);
  const navigate = useNavigate(),
    location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const handleSettings = () => {
    setSettings(!settings);
  };
  return (
		<section>
			{auth.isAuth && (
				<>
					<button
						type="button"
						className="tw-inline-flex tw-items-center tw-p-2 tw-mt-2 tw-ml-3 tw-text-sm tw-text-gray-500 tw-rounded-lg sm:tw-hidden hover:tw-bg-gray-100 focus:tw-outline-none tw-overflow-visible focus:tw-ring-2 focus:tw-ring-gray-200"
						onClick={toggleNav}>
						<span className="tw-sr-only">Open sidebar</span>
						{/* <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg> */}
						<AiOutlineMenu size={20} />
					</button>
					<div
						className={`tw-fixed md:tw-hidden tw-top-0 tw-left-0 tw-z-40 tw-w-full tw-h-screen tw-transition-transform tw-bg-black tw-opacity-10 ${
							nav
								? "tw-translate-x-0"
								: "-tw-translate-x-full md:tw-translate-x-0"
						}`}
						onClick={toggleNav}></div>
					<aside
						className={`tw-fixed tw-top-0 tw-left-0 tw-z-40 tw-w-56 maingradient tw-h-screen tw-transition-transform ${
							nav ? "translate-x-0" : "-tw-translate-x-full md:tw-translate-x-0"
						}`}>
						<div className="tw-h-full tw-relative tw-py-4 tw-overflow-y-scroll noScroll">
							<div className="tw-flex tw-px-4 tw-justify-center tw-mb-8 tw-mt-4 tw-bg-white">
								<Logo />
							</div>
							<div className="tw-mt-10">
								{sidebarList.map((item, i) => (
									<div
										onClick={() => navigate(item.url)}
										key={i}
										className={`tw-w-full tw-cursor-pointer tw-h-12 tw-flex tw-pl-8 tw-gap-3 tw-items-center ${
											currentPath === item.url || currentPath.includes(item.url)
												? "tw-bg-white"
												: "tw-bg-transparent"
										}`}>
										{currentPath === item.url ||
										currentPath.includes(item.url) ? (
											<img src={item.activeImg} alt={item.name} className="" />
										) : (
											<img src={item.img} alt="" className="" />
										)}

										<h4
											className={`tw-text-base tw-font-normal tw-capitalize mulish ${
												currentPath === item.url ||
												currentPath.includes(item.url)
													? "tw-text-secondary"
													: "tw-text-white"
											}`}>
											{item.name}
										</h4>
									</div>
								))}
								<div
									onClick={handleSettings}
									className={`tw-w-full tw-relative tw-cursor-pointer tw-h-12 tw-flex tw-pl-8 tw-gap-3 tw-items-center ${
										currentPath === "/settings" ||
										currentPath.includes("/settings")
											? "tw-bg-white"
											: "tw-bg-transparent"
									}`}>
									{currentPath === "/settings" ||
									currentPath.includes("/settings") ? (
										<img src={SettingActive} alt={"settings"} className="" />
									) : (
										<img src={Setting} alt="" className="" />
									)}

									<h4
										className={`tw-text-base tw-font-normal tw-capitalize mulish ${
											currentPath === "/settings" ||
											currentPath.includes("/settings")
												? "tw-text-secondary"
												: "tw-text-white"
										}`}>
										settings
									</h4>
									{settings && <SettingsModal SettingList={SettingList} />}
								</div>
								<div
									onClick={handleLogout}
									className={`tw-w-full tw-cursor-pointer tw-h-12 tw-flex tw-pl-8 tw-gap-3 tw-items-center tw-bg-transparent`}>
									<img src={Logout} alt="" className="" />

									<h4
										className={`tw-text-base tw-font-normal tw-capitalize mulish tw-text-white`}>
										Logout
									</h4>
								</div>
							</div>
						</div>
					</aside>
				</>
			)}

			<div className={`${auth?.isAuth ? "sm:tw-ml-56" : ""}`}>
				<div className={`${auth?.isAuth ? "" : ""}`}>{children}</div>
			</div>
		</section>
	);
};
const SettingsModal = ({ SettingList }) => {
  const navigate = useNavigate();
  return (
    <div className="tw-absolute tw-py-8 tw-z-[1000] tw-w-64 tw-bg-opacity-30 tw-backdrop-blur-md tw-top-10 tw-left-4 maingradient">
      {SettingList.map((item, i) => (
        <div
          key={i}
          onClick={() => navigate(`/settings${item.url}`)}
          className="tw-w-full tw-cursor-pointer tw-h-12 tw-flex tw-pl-8 tw-gap-3 tw-items-center tw-bg-transparent"
        >
          <img src={Setting} alt="" className="" />
          <h4 className="tw-text-base tw-font-normal tw-capitalize mulish tw-text-white">
            {item.name}
          </h4>
        </div>
      ))}
    </div>
  );
};
export default Sidebar;
