import React, { useState, useEffect } from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import Textinput from "../../../components/inputs/textinputs";
import TextArea from "../../../components/inputs/textarea";
// import { CreateQuestions } from "../../quiz-zone/create-questions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/buttons/button";
import moment from "moment";
import { manageContestQuestions } from "../../../data/Reducers/ContestReducer";
import { returnErrors } from "../../../data/Reducers/ErrorReducer";
import axios from "axios";

const CreateContest = () => (
	<ContestQuestion type="contest" title={"Contest"} subTitle="Create Contest" />
);

export const ContestQuestion = () => {
	let { contest } = useSelector(s => s),
		[loading, setLoading] = useState(false),
		init = {
			name: "",
			entryFee: "",
		},
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		},
		navigate = useNavigate(),
		{ page } = useParams(),
		[image, setImage] = useState(null),
		[updater, setUpdater] = useState(null),
		location = useLocation(),
		[mapData, setMapData] = useState(null);

	let handleChangeImage = e => {
		const file = e.target.files[0];
		let err = "";

		if (!file) return (err = `File, ${file?.name} does not exist`);
		if (!file.type.includes("image"))
			return (err = `File, ${file?.name} format not supported`);

		if (err) {
			return toast.error(err);
		} else {
			setImage(file);
		}
	};

	useEffect(() => {
		if (location?.state) {
			setUpdater(location?.state);
			let newD = {},
				st = location?.state;
			console.log({ st });
			if (st?.name) newD.name = st?.name;
			if (st?.description) newD.description = st?.description;
			if (st?.entryFee) newD.entryFee = st?.entryFee;
			if (st?.topUsers) newD.topUsers = st?.topUsers;
			if (st?.startDate)
				newD.startDate = moment(st?.startDate).format("YYYY-MM-DD hh:mm");
			if (st?.endDate)
				newD.endDate = moment(st?.endDate).format("YYYY-MM-DD hh:mm");
			for (let w = 0; w < Number(st?.winnersPrize?.length); w++) {
				newD[`winnerPrize${w + 1}`] = st?.winnersPrize?.[w];
			}
			setState({ ...state, ...newD });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.state]);

	useEffect(() => {
		if (state?.topUsers) {
			let newArr = [];
			for (let i = 1; i <= Number(state?.topUsers); i++) {
				newArr?.push(i);
			}
			setMapData(newArr);
		} else {
			setMapData(null);
		}
	}, [state?.topUsers]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.name) return toast.info("Name is required");

		var newState = {};
		if (state?.name) newState.name = state?.name;
		if (state?.entryFee) newState.entryFee = Number(state?.entryFee);
		if (state?.topUsers) {
			newState.topUsers = Number(state?.topUsers);
			let winnerPrize = [];
			for (let w = 1; w <= Number(state?.topUsers); w++) {
				winnerPrize?.push(Number(state?.[`winnerPrize${w}`]));
			}
			newState.winnersPrize = winnerPrize;
		}
		if (state?.startDate) newState.startDate = state?.startDate;
		if (state?.endDate) newState.endDate = state?.endDate;
		if (state?.description) newState.description = state?.description;

		setLoading(true);
		if (image)
			try {
				let resImg = await axios.post(
					`/api/v1/file`,
					{ image },
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					}
				);

				newState.image = resImg?.data?.data?.filesId?.files?.[0];
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				if (newState?.image || newState?.audioFile) {
					await axios.delete(`/api/v1/file/${newState?.image}`, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});
				}

				let error = err.response?.data?.error;
				if (error) {
					setLoading(false);
					return dispatch(
						returnErrors({ error, status: err?.response?.status })
					);
				} else {
					setLoading(false);
					return toast.error(err?.response?.data?.message);
				}
			}

		await dispatch(
			manageContestQuestions(
				updater ? "put" : "post",
				newState,
				"types",
				updater?._id || null
			)
		);
		setLoading(false);
	};

	let reset = () => {
		setState(init);
		setImage(null);
		navigate(`/${page}`);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(contest?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contest?.status]);

	return (
		<div>
			<MainHeader text={"Create Contest"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Create Contest
				</h2>
				<form action="" className="tw-mt-10">
					<div className="tw-grid md:tw-grid-cols-2 tw-gap-10">
						<div className="tw-space-y-4">
							<Textinput
								type={"file"}
								label={"Choose image (jpeg, png)"}
								name={"image"}
								accept={"image/*"}
								onChange={handleChangeImage}
							/>
							<Textinput
								label={"Start Date"}
								type={"datetime-local"}
								name="startDate"
								value={state?.startDate}
								onChange={textChange}
								min={moment().format("YYYY-MM-DD hh:mm")}
							/>
							<Textinput
								label={"Entry Fee"}
								type={"number"}
								placeholder={"100"}
								name="entryFee"
								value={state?.entryFee}
								onChange={textChange}
							/>
						</div>
						<div className="tw-space-y-4">
							<Textinput
								label={"Contest Name"}
								type={"text"}
								placeholder={"Enter contest name"}
								name="name"
								value={state?.name}
								onChange={textChange}
							/>
							<Textinput
								label={"End Date"}
								type={"datetime-local"}
								name="endDate"
								value={state?.endDate}
								onChange={textChange}
								min={moment().format("YYYY-MM-DD hh:mm")}
							/>
							<Textinput
								label={"Top Users"}
								type={"number"}
								placeholder={"Top Users"}
								name="topUsers"
								value={state?.topUsers}
								onChange={textChange}
								min={1}
							/>
						</div>
					</div>
					<div className="tw-grid md:tw-grid-cols-4 tw-gap-10 tw-mt-5">
						{mapData?.map((it, i) => (
							<Textinput
								label={`Winner Prize ${it}`}
								type={"number"}
								placeholder={`Winner Prize ${it}`}
								name={`winnerPrize${it}`}
								value={state?.[`winnerPrize${it}`]}
								onChange={textChange}
								key={i}
							/>
						))}
					</div>
					<div className="tw-mt-6">
						<TextArea
							label={"Descriptions"}
							name="description"
							value={state?.description}
							onChange={textChange}
						/>
					</div>
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<Button
							loading={loading}
							onClick={handleSubmit}
							width="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
							title={updater ? "Update" : "Submit"}
						/>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default CreateContest;
