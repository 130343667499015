import React from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import UserContestTable from "../../components/tables/user-contes-table";

const ContestUsers = () => {
  const Headers = [
    "User ID",
    "Name",
    "Date",
    "questions",
    "updated",
    "correct ans",
    "score",
    "rank",
  ];
  const tableData = [
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      questions: "10",
      updated: "25/12/2023",
      correct_ans: "10",
      score: "10",
      rank: "2",
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      questions: "10",
      updated: "25/12/2023",
      correct_ans: "10",
      score: "10",
      rank: "2",
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      questions: "10",
      updated: "25/12/2023",
      correct_ans: "10",
      score: "10",
      rank: "2",
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      questions: "10",
      updated: "25/12/2023",
      correct_ans: "10",
      score: "10",
      rank: "2",
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      questions: "10",
      updated: "25/12/2023",
      correct_ans: "10",
      score: "10",
      rank: "2",
    },
  ];
  return (
    <div>
      <MainHeader text={"Contest Users"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          All Users
        </h2>
        <div className="tw-mt-10">
          <UserContestTable data={tableData} headers={Headers} />
        </div>
      </MainContainer>
    </div>
  );
};

export default ContestUsers;
