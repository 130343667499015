import React from "react";
import { ModalContainer } from "../../../components/modals/modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import Textinput from "../../../components/inputs/textinputs";
import { useState } from "react";
import { useEffect } from "react";

const WelcomeBonus = () => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(true);
  }, [setModal]);
  const handleClose = () => {
    setModal("");
  };
  return (
    <div>
      {modal && (
        <ModalContainer>
          <div className="lg:tw-w-1/3 tw-mx-auto tw-p-8 tw-bg-[#FDFDFF] tw-rounded-xl">
            <div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
              <AiFillLeftCircle
                className="tw-cursor-pointer"
                size={20}
                onClick={handleClose}
              />
              <h2 className="tw-text-base work tw-text-main tw-font-medium">
                Create new slider
              </h2>
            </div>
            <form action="" className="tw-mt-10">
              <Textinput
                type="text"
                placeholder={"50 coins"}
                label={"Set bonus"}
              />
              <div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
                <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
                  Set Bonus
                </button>
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
    </div>
  );
};

export default WelcomeBonus;
