import React from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import SelectInput from "../../components/inputs/selectinputs";
import { useState } from "react";
import Img from "../../assets/totalico.svg";
import { AiFillUpCircle, AiFillDownCircle } from "react-icons/ai";

const CategoryOrder = () => {
  const [category, setCategory] = useState([
    { name: "General Knowledge", id: 1 },
    { name: "General Knowledge", id: 2 },
    { name: "General Knowledge", id: 3 },
    { name: "General Knowledge", id: 4 },
    { name: "General Knowledge", id: 5 },
  ]);
  const OrderCategory = (i, direction) => {
    const newOrder = [...category],
      MovedCategory = newOrder.splice(i, 1)[0],
      newIndex = direction === "up" ? i - 1 : i + 1;
    newOrder.splice(newIndex, 0, MovedCategory);
    setCategory(newOrder);
    console.log(category);
  };
  return (
    <div>
      <MainHeader text={"Category Order"} />
      <MainContainer>
        <MainCategory category={category} handleOrder={OrderCategory} />
        <SubCategory category={category} handleOrder={OrderCategory} />
      </MainContainer>
    </div>
  );
};
const MainCategory = ({ category, handleOrder }) => {
  return (
    <div>
      <h2 className="tw-text-xl tw-text-main tw-font-normal work">
        Main Category
      </h2>
      <div className="tw-mt-5 tw-w-80">
        <SelectInput label={"Choose Category"}>
          <option value="monthly">Monthly</option>
          <option value="monthly">Monthly</option>
          <option value="monthly">Monthly</option>
        </SelectInput>
      </div>
      <div className="tw-mt-10 tw-space-y-4">
        {category.map((item, i) => (
          <div
            className="tw-flex tw-justify-between tw-p-2 tw-bg-white myShadow2 tw-rounded-md"
            key={item.id}
          >
            <div className="tw-flex tw-items-center tw-gap-4">
              <h6 className="tw-text-main segoe tw-text-[15px] tw-font-normal">
                {item.id}
              </h6>
              <img src={Img} alt="" className="tw-h-8d" />
              <h6 className="tw-text-main segoe tw-text-sm tw-font-normal">
                {item.name}
              </h6>
            </div>
            <div className="tw-flex tw-items-center tw-gap-1">
              <div
                className={`${
                  i === 0 ? "tw-cursor-not-allowed" : "tw-cursor-pointer"
                }`}
                onClick={() => handleOrder(i, "up")}
              >
                <AiFillUpCircle size={20} />
              </div>
              <div
                className={`${
                  i === category.length - 1
                    ? "tw-cursor-not-allowed"
                    : "tw-cursor-pointer"
                }`}
                onClick={() => handleOrder(i, "down")}
              >
                {" "}
                <AiFillDownCircle size={20} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SubCategory = ({ category, handleOrder }) => {
  return (
    <div className="tw-mt-20">
      <h2 className="tw-text-xl tw-text-main tw-font-normal work">
        Sub Category
      </h2>
      <div className="tw-mt-5 tw-w-80">
        <SelectInput label={"Choose Category"}>
          <option value="monthly">Monthly</option>
          <option value="monthly">Monthly</option>
          <option value="monthly">Monthly</option>
        </SelectInput>
      </div>
      <div className="tw-mt-10 tw-space-y-4">
        {category.map((item, i) => (
          <div
            className="tw-flex tw-justify-between tw-p-2 tw-bg-white myShadow2 tw-rounded-md"
            key={item.id}
          >
            <div className="tw-flex tw-items-center tw-gap-4">
              <h6 className="tw-text-main segoe tw-text-[15px] tw-font-normal">
                {item.id}
              </h6>
              <img src={Img} alt="" className="tw-h-8d" />
              <h6 className="tw-text-main segoe tw-text-sm tw-font-normal">
                {item.name}
              </h6>
            </div>
            <div className="tw-flex tw-items-center tw-gap-1">
              <div
                className={`${
                  i === 0 ? "tw-cursor-not-allowed" : "tw-cursor-pointer"
                }`}
                onClick={() => handleOrder(i, "up")}
              >
                <AiFillUpCircle size={20} />
              </div>
              <div
                className={`${
                  i === category.length - 1
                    ? "tw-cursor-not-allowed"
                    : "tw-cursor-pointer"
                }`}
                onClick={() => handleOrder(i, "down")}
              >
                {" "}
                <AiFillDownCircle size={20} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default CategoryOrder;
