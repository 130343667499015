import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import ReportTable from "../../../components/tables/report-table";

const QuestionReport = () => {
  const Headers = ["quiz ID", "Name", "Date", "question", "message", "actions"];
  const tableData = [
    {
      quiz_id: "25",
      name: "C . Name",
      date: "26/12/2008",
      question: "Lorep ipsum",
      message: "Done",
    },
    {
      quiz_id: "25",
      name: "C . Name",
      date: "26/12/2008",
      question: "Lorep ipsum",
      message: "Done",
    },
    {
      quiz_id: "25",
      name: "C . Name",
      date: "26/12/2008",
      question: "Lorep ipsum",
      message: "Done",
    },
    {
      quiz_id: "25",
      name: "C . Name",
      date: "26/12/2008",
      question: "Lorep ipsum",
      message: "Done",
    },
    {
      quiz_id: "25",
      name: "C . Name",
      date: "26/12/2008",
      question: "Lorep ipsum",
      message: "Done",
    },
  ];
  return (
    <div>
      <MainHeader text={"Report"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-bold work">
          All Reports
        </h2>
        <div className="tw-mt-12">
          <ReportTable data={tableData} headers={Headers} />
        </div>
      </MainContainer>
    </div>
  );
};

export default QuestionReport;
