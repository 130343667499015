import React, { useEffect } from "react";
import { useState } from "react";
import Line from "../../../assets/more - vertical.svg";
import Img from "../../../assets/tableimg.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDelete from "../../modals/confirm-delete";
import { manageExamQuestion } from "../../../data/Reducers/ExamReducer";
import DeletedModal from "../../modals/deleted-modal";
import StatusModal from "../../modals/status-modal";

const ExamModuleTable = ({ data, headers }) => {
	const [modal, SetModal] = useState(false),
		[actionModal, setActionModal] = useState(""),
		[active, SetActive] = useState(null);
	const handleModal = i => {
			SetActive(i);
			SetModal(!modal);
		},
		handleCloseModal = () => {
			setActionModal("");
		};

	let [datum, setDatum] = useState(null);

	let [loading, setLoading] = useState(null),
		dispatch = useDispatch(),
		{ exam } = useSelector(s => s);

	useEffect(() => {
		if (["deleted"]?.includes(exam?.status)) {
			setActionModal("deleted-modal");
			setDatum(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exam?.status]);

	return (
		<div className="tw-overflow-x-scroll noScroll">
			<table className="tw-min-w-full tw-table-auto">
				<thead className="tw-w-full tw-h-10 tw-rounded-t-3xl tw-bg-[#E7E7F6]">
					<tr>
						{headers.map((item, i) => (
							<th
								key={i}
								className="tw-text-sm first-letter:tw-uppercase tw-text-[#838080] work tw-px-3">
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="tw-divide-y-4">
					{data.map((item, i) => (
						<tr
							style={{
								boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
								marginBottom: "10px !important",
							}}
							key={i}
							className="work tw-bg-white tw-mt-8">
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{i + 1}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								<img
									src={item?.image?.url || Img}
									alt=""
									className="tw-h-8 tw-mx-auto"
								/>
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.key}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{moment(item?.date).format("DD/MM/YYYY")}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.title}
							</td>
							<td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.duration}
							</td>
							{/* <td className="tw-text-main tw-font-normal work tw-text-sm tw-text-center">
								{item?.questions}
							</td> */}
							<td className="tw-flex tw-justify-center tw-py-2">
								<button
									className={`tw-h-8 tw-rounded-lg tw-w-16 tw-text-xs tw-font-normal work ${
										item?.status === "active"
											? "tw-bg-[#E5FFEB] tw-text-[#07A420]"
											: "tw-bg-red-400 tw-text-white"
									} `}>
									{item?.status === "active" ? "Active" : "Inactive"}
								</button>
							</td>
							<td className="tw-relative">
								<img
									onClick={() => handleModal(i)}
									src={Line}
									alt=""
									className="tw-mx-auto tw-cursor-pointer"
								/>
								{active === i && modal && (
									<div className="tw-absolute tw-shadow-lg tw-z-[1000] tw-top-8 tw-p-6 tw-right-4  tw-bg-white tw-space-y-2 k2d">
										<h6 className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											EDIT Exam
										</h6>
										<h6
											onClick={() => {
												setDatum(item);
												setActionModal("delete-modal");
											}}
											className="tw-text-sm tw-cursor-pointer tw-whitespace-nowrap tw-font-semibold tw-uppercase textColor">
											delete exam
										</h6>
									</div>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{actionModal === "delete-modal" && (
				<ConfirmDelete
					text={"Exam Type"}
					loading={loading}
					handleClose={handleCloseModal}
					handleNext={async e => {
						e?.preventDefault();
						if (!datum) return;
						setLoading(true);
						await dispatch(manageExamQuestion("delete", datum, "types"));
						setLoading(false);
					}}
				/>
			)}
			{actionModal === "deleted-modal" && (
				<DeletedModal text={"Exam Type"} handleClose={handleCloseModal} />
			)}
			{actionModal === "status-modal" && (
				<StatusModal handleClose={handleCloseModal} />
			)}
		</div>
	);
};

export default ExamModuleTable;
