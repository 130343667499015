import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice } from "./ErrorReducer";
import { categorySlice } from "./CategoryReducer";
import { quizZoneSlice } from "./QuizZoneReducer";
import { dailyQuizSlice } from "./DailyQuizReducer";
import { audioQuizSlice } from "./AudioQuizReducer";
import { funAndLearnQuestionSlice } from "./FunNLearnReducer";
import { contestQuestionsSlice } from "./ContestReducer";
import { guessTheWordSlice } from "./GuessTheWordReducer";
import { mathQuizSlice } from "./MathQuizReducer";
import { languageSlice } from "./LanguagesReducer";
import { leaderboardSlice } from "./LeaderboardReducer";
import { settingsSlice } from "./SettingsReducer";
import { examQuestionSlice } from "./ExamReducer";
import { badgesSlice } from "./BadgeReducer";
import { questionsSlice } from "./QuestionBankReducer";
import { playerSlice } from "./PlayerReducer";
import { slidersSlice } from "./SliderReducer";
import { faqSlice } from "./FaqReducer";

const rootReducer = combineReducers({
	auth: UserReducer,
	error: errorSlice.reducer,
	category: categorySlice.reducer,
	quizZone: quizZoneSlice.reducer,
	dailyQuiz: dailyQuizSlice.reducer,
	audioQuiz: audioQuizSlice.reducer,
	funAndLearn: funAndLearnQuestionSlice.reducer,
	contest: contestQuestionsSlice.reducer,
	guessTheWord: guessTheWordSlice.reducer,
	mathQuiz: mathQuizSlice.reducer,
	language: languageSlice.reducer,
	leaderboard: leaderboardSlice.reducer,
	settings: settingsSlice.reducer,
	exam: examQuestionSlice.reducer,
	badges: badgesSlice.reducer,
	questions: questionsSlice.reducer,
	player: playerSlice.reducer,
	slider: slidersSlice.reducer,
	faq: faqSlice.reducer,
});

export default rootReducer;
