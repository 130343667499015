import React from "react";

const Textinput = ({
	label,
	type,
	value,
	name,
	placeholder,
	onChange,
	...rest
}) => {
	return (
		<div>
			<h3 className="tw-text-sm segoe tw-text-main tw-text-opacity-90 tw-capitalize">
				{label}
			</h3>
			<input
				type={type}
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={onChange}
				className="tw-border tw-text-xs tw-font-normal tw-text-main tw-text-opacity-80 tw-border-[#ccc] tw-rounded-md tw-h-10 tw-mt-2 tw-w-full tw-px-4 segoe"
				{...rest}
			/>
		</div>
	);
};

export default Textinput;
