import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import TextArea from "../../../components/inputs/textarea";
import SelectInput from "../../../components/inputs/selectinputs";
import Textinput from "../../../components/inputs/textinputs";
import { CreateQuestions } from "../../quiz-zone/create-questions";

const CreateAudioQuestion = () => (
	<CreateQuestions
		type="audioQuiz"
		title={"Audio Quiz"}
		subTitle="Create Audio Quiz"
	/>
);

export const GuessQuestion = () => {
	return (
		<div>
			<MainHeader text={"Create Audio Question"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Create Math Quiz
				</h2>
				<form action="" className="tw-mt-8">
					<div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
						<SelectInput label={"Choose Language"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
						<SelectInput label={"Choose Category"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
						<SelectInput label={"Choose Sub-Category"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
					</div>
					<div className="tw-mt-8">
						<TextArea label={"Question"} placeholder={"Write question"} />
					</div>
					<div className="tw-mt-6 tw-grid md:tw-grid-cols-2 tw-gap-10">
						<div className="tw-space-y-4">
							<Textinput
								type={"file"}
								label={"Choose Audio (mp3)"}
								name={"audio"}
							/>
							<Textinput
								type={"text"}
								label={"Option A"}
								name={"option_a"}
								placeholder={"Option A"}
							/>
							<Textinput
								type={"text"}
								label={"Option C"}
								name={"option_c"}
								placeholder={"Option C"}
							/>
							<SelectInput label={"Right Answer"} name={"language"}>
								<option value="english" className="">
									Option A
								</option>
								<option value="english" className="">
									Option A
								</option>
								<option value="english" className="">
									Option A
								</option>
							</SelectInput>
						</div>
						<div className="tw-space-y-4">
							<Textinput
								type={"text"}
								label={"Audio Link"}
								name={"audio_link"}
								placeholder={"Audio Link"}
							/>
							<Textinput
								type={"text"}
								label={"Option B"}
								name={"option_b"}
								placeholder={"Option B"}
							/>
							<Textinput
								type={"text"}
								label={"Option D"}
								name={"option_d"}
								placeholder={"Option D"}
							/>
							<SelectInput label={"Choose Level"} name={"language"}>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
							</SelectInput>
						</div>
					</div>
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<button className="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white">
							Submit
						</button>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default CreateAudioQuestion;
