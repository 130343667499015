/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
// import { ErrorPage } from "./components";
import { GlobalState } from "./data/Context";
// import { LoadingLoader } from "./components/buttons/button";
import { clearErrors } from "./data/Reducers/ErrorReducer";
import { useDispatch } from "react-redux";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { auth, error } = useContext(GlobalState);
	const { page, id, step } = useParams();
	const escape2 = [
			"questions",
			"create-question",
			"create-exam",
			"question-report",
			"edit-question",
			"user-statistics",
			"import-questions",
			"create-questions",
			"create-fun",
			"create-guess-the-word",
			"create-contest",
			"edit-guess-the-word",
			"edit-fun",
			"edit-audio",
			"edit-contest",
			"distribute-prize",
			"sliders",
			"welcome-bonus",
			"icons-settings",
			"payment-settings",
			"badge-settings",
			"language-settings",
			"system-utilities",
			"users-rights",
			"about-us",
			"contact-us",
			"privacy-policy",
			"terms",
			"howtoplay",
			"faq",
		],
		navigate = useNavigate(),
		location = useLocation(),
		dispatch = useDispatch();

	useEffect(() => {
		if (!auth?.isAuth) {
			if (!error?.errorText) {
				if (page !== "login") {
					navigate("/", { state: page });
				}
				dispatch(clearErrors());
			}
		}
		if (auth?.isAuth) {
			if (["register", "login", "create-account"]?.includes(page)) {
				navigate(location?.state ? `/${location?.state}` : "/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate, location?.state]);

	// if (auth?.token && auth?.loading) return <LoadingLoader />;

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "overview" && escape2.includes(id)) ||
			(page === "math-quiz" && escape2.includes(id)) ||
			(page === "exam-module" && escape2.includes(id)) ||
			(page === "audio-question" && escape2.includes(id)) ||
			(page === "quiz-zone" && escape2.includes(id)) ||
			(page === "question-bank" && escape2.includes(id)) ||
			(page === "daily-quiz" && escape2.includes(id)) ||
			(page === "users" && escape2.includes(id)) ||
			(page === "admin" && escape2.includes(id)) ||
			(page === "fun" && escape2.includes(id)) ||
			(page === "guess-the-word" && escape2.includes(id)) ||
			(page === "contest" && escape2.includes(id)) ||
			(page === "settings" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}

	return GeneratePage(pageName, auth?.isAuth ? "pages" : "screens");
	// return generatePage(pageName, "pages");
};

export default PageRender;
