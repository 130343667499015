import React, { useEffect, useState } from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamModuleTable from "../../components/tables/exam-module-table";
import manage from "../../assets/manageico.svg";
import Import from "../../assets/import.svg";
import Report from "../../assets/reports.svg";
import Create from "../../assets/create.svg";
import View from "../../assets/view.svg";
import { useDispatch, useSelector } from "react-redux";
import { manageExamQuestion } from "../../data/Reducers/ExamReducer";
import {
	LoadMore,
	LoadingLoader,
	MainPaginate,
} from "../../components/buttons/button";

const ExamModule = () => {
	const navigate = useNavigate();
	const Arr = [
		{
			title: "Create Exam",
			img: manage,
			bg: "#FFF7F7",
			action: () => navigate("/exam-module/create-exam"),
		},
		{
			title: "Create Questions",
			img: Create,
			bg: "#FFF2F9",
			action: () => navigate("/exam-module/create-question"),
		},
		{
			title: "View Questions",
			img: View,
			bg: "#FFFFF7;",
			action: () => navigate("/exam-module/questions"),
		},
		{
			title: "Import Questions",
			img: Import,
			bg: "#F7F8FF",
			action: () => navigate("/exam-module/import-questions"),
		},
		{
			title: "Question report",
			img: Report,
			bg: "#F4FFF1",
			action: () => navigate("/exam-module/question-report"),
		},
	];

	const Headers = [
		"ID",
		"image",
		"Key",
		"date",
		"title",
		"duration",
		// "question",
		"status",
		"action",
	];
	const tableData = [
		{
			id: "25",
			key: "0000",
			language: "English",
			date: "02/02/2023",
			title: "Lorem ipsum",
			duration: "10mins",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			key: "0000",
			language: "English",
			date: "02/02/2023",
			title: "Lorem ipsum",
			duration: "10mins",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			key: "0000",
			language: "English",
			date: "02/02/2023",
			title: "Lorem ipsum",
			duration: "10mins",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			key: "0000",
			language: "English",
			date: "02/02/2023",
			title: "Lorem ipsum",
			duration: "10mins",
			questions: "20",
			active: true,
		},
		{
			id: "25",
			key: "0000",
			language: "English",
			date: "02/02/2023",
			title: "Lorem ipsum",
			duration: "10mins",
			questions: "20",
			active: true,
		},
	];

	let { exam } = useSelector(s => s),
		[data, setData] = useState(null),
		dispatch = useDispatch();

	useEffect(() => {
		dispatch(manageExamQuestion("get", null, "types"));
	}, [dispatch]);

	useEffect(() => {
		setData(exam?.types);
	}, [exam?.types]);

	let [range] = useState(10),
		[page, setPage] = useState(1),
		[loading, setLoading] = useState(null);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	if (!data && !exam?.types) return <LoadingLoader />;

	const currentItems = data?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(data?.docs?.length / range);

	const handlePageClick = event => {
			const newOffset = (event.selected * range) % data?.docs?.length;
			setItemOffset(newOffset);
			setPage(1 + event?.selected);
		},
		handleLoadMore = async () => {
			setLoading("loadmore");
			let datum = { limit: data?.limit * data?.nextPage };
			await dispatch(manageExamQuestion("get", datum, "types"));
			setLoading(false);
		};

	return (
		<div>
			<MainHeader text={"Exam Module"} />
			<MainContainer>
				<div className="tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-6">
					{Arr.map((item, i) => (
						<div
							key={i}
							onClick={item.action}
							style={{
								background: item.bg,
							}}
							className={`myShadow2 tw-rounded-md tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-h-28`}>
							<div>
								<img src={item?.img} alt={item?.title} className="tw-mx-auto" />
								<h4 className="tw-text-base sansation tw-mt-2 tw-font-bold tw-text-center tw-text-main tw-capitalize">
									{item.title}
								</h4>
							</div>
						</div>
					))}
				</div>
				<div className="tw-flex tw-justify-between tw-items-center">
					<div>
						<h2 className="tw-text-xl tw-text-main tw-font-normal work">
							Manage Exam Modules
						</h2>
					</div>
					{/* <button
						onClick={() => navigate("/exam-module/create-exam")}
						className="tw-h-12 tw-w-36 btngradient tw-rounded-md  work tw-text-base tw-text-white tw-font-normal">
						Create Exam
					</button> */}
				</div>
				<div className="tw-mt-10">
					<ExamModuleTable headers={Headers} data={currentItems || tableData} />
					<div className="tw-mt-4 tw-flex tw-justify-center">
						<LoadMore
							next={page === pageCount && data?.hasNextPage}
							loading={loading === "loadmore"}
							handleLoadMore={handleLoadMore}
						/>
					</div>
					<div className="tw-mt-4 tw-flex tw-justify-center">
						{/* <Pagination /> */}
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default ExamModule;
