import React from "react";
import MainHeader from "../../../components/mainheader";
import MainContainer from "../../../components/maincontainer";
import SelectInput from "../../../components/inputs/selectinputs";
import Textinput from "../../../components/inputs/textinputs";
import TextArea from "../../../components/inputs/textarea";
import { CreateQuestions } from "../../quiz-zone/create-questions";

const CreateMathQuestion = () => (
	<CreateQuestions
		type="mathQuiz"
		title={"Math Quiz"}
		subTitle="Create Math Quiz"
	/>
);

export const MathQuizQuestion = () => {
	return (
		<div>
			<MainHeader text={"Math Quiz"} />
			<MainContainer>
				<h2 className="tw-text-xl tw-text-main tw-font-normal work">
					Create Math Quiz
				</h2>
				<form action="" className="tw-mt-8">
					<h3 className="tw-text-lg tw-text-main tw-font-normal segoe">
						Create Questions
					</h3>
					<div className="tw-grid tw-mt-6 lg:tw-grid-cols-3 tw-gap-8">
						<SelectInput label={"Choose Language"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
						<SelectInput label={"Choose Category"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
						<SelectInput label={"Choose Sub-Category"} name={"language"}>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
							<option value="english" className="">
								English
							</option>
						</SelectInput>
					</div>
					<div className="tw-mt-8">
						<TextArea label={"Question"} placeholder={"Write question"} />
					</div>
					<div className="tw-mt-6 tw-grid md:tw-grid-cols-2 tw-gap-10">
						<div className="tw-space-y-4">
							<SelectInput label={"Question Type"} name={"type"}>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
							</SelectInput>
							<Textinput
								type={"text"}
								label={"Option A"}
								name={"option_a"}
								placeholder={"Type in option a"}
							/>
							<Textinput
								type={"text"}
								label={"Option C"}
								name={"option_c"}
								placeholder={"Type in option c"}
							/>
						</div>
						<div className="tw-space-y-4">
							<SelectInput label={"Right Answer"} name={"right_answer"}>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
								<option value="english" className="">
									English
								</option>
							</SelectInput>
							<Textinput
								type={"text"}
								label={"Option B"}
								name={"option_a"}
								placeholder={"Type in option b"}
							/>
							<Textinput
								type={"text"}
								label={"Option D"}
								name={"option_d"}
								placeholder={"Type in option d"}
							/>
						</div>
					</div>
					<div className="tw-w-full tw-mt-10 tw-flex tw-justify-center">
						<button className="tw-w-72 btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-sm tw-text-white">
							Submit
						</button>
					</div>
				</form>
			</MainContainer>
		</div>
	);
};

export default CreateMathQuestion;
