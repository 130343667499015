import React, { useEffect, useState } from "react";
import { ModalContainer } from "./modalcontainer";
import { AiFillLeftCircle } from "react-icons/ai";
import SelectInput from "../inputs/selectinputs";
import languages from "../../data/language.json";
import { useDispatch, useSelector } from "react-redux";
import { manageLanguage } from "../../data/Reducers/LanguagesReducer";
import { toast } from "react-toastify";
import Button from "../buttons/button";

const AddLanguage = ({ handleClose }) => {
	let { language } = useSelector(s => s),
		init = { name: "" },
		[loading, setLoading] = useState(null),
		[state, setState] = useState(init),
		dispatch = useDispatch(),
		textChange = e => {
			let { name, value } = e?.target;
			setState({ ...state, [name]: value });
		};

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.name) return toast.info("Langauge is required");
		setLoading(true);
		await dispatch(manageLanguage("post", state));
		setLoading(false);
	};

	let reset = () => {
		setState(init);
	};

	useEffect(() => {
		if (["added", "updated"]?.includes(language?.status)) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language?.status]);

	return (
		<div>
			<ModalContainer>
				<div className="lg:tw-w-1/2 tw-shadow-xl tw-rounded-lg tw-p-6 tw-bg-white">
					<div className="tw-flex tw-gap-5 tw-border-b tw-pb-4 tw-border-black tw-items-center">
						<AiFillLeftCircle
							className="tw-cursor-pointer"
							size={20}
							onClick={handleClose}
						/>
						<h2 className="tw-text-base work tw-text-main tw-font-medium">
							Create New Language
						</h2>
					</div>
					<form action="" className="tw-mt-6">
						<SelectInput
							value={state?.name}
							name="name"
							onChange={textChange}
							label={"Choose Language"}>
							{" "}
							<option value="">Select a Language</option>
							{languages?.map((it, i) => (
								<option key={i} value={it?.name}>
									{it?.name}
								</option>
							))}
						</SelectInput>
						<div className="tw-flex tw-gap-4 tw-justify-center tw-items-center tw-mt-4">
							{/* <button className="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white">
								Create Language
							</button> */}
							<Button
								loading={loading}
								onClick={handleSubmit}
								width="tw-w-56 tw-h-8 tw-border btngradient tw-rounded-lg tw-font-semibold tw-text-sm sourse tw-text-white"
								title={"Create Language"}
							/>
						</div>
					</form>
				</div>
			</ModalContainer>
		</div>
	);
};

export default AddLanguage;
