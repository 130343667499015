import React from "react";
import MainHeader from "../../components/mainheader";
import MainContainer from "../../components/maincontainer";
import SelectInput from "../../components/inputs/selectinputs";
import PaymentTable from "../../components/tables/payment-table";

const Payment = () => {
  const Headers = [
    "ID",
    "Name",
    "Date",
    "payment",
    "amount",
    "details",
    "coins used",
    "Status",
    "Actions",
  ];
  const tableData = [
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      payment: "Paypal",
      amount: "$50",
      details: "Lorep ipsum",
      coins_used: "50 coins",
      active: true,
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      payment: "Paypal",
      amount: "$50",
      details: "Lorep ipsum",
      coins_used: "50 coins",
      active: true,
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      payment: "Paypal",
      amount: "$50",
      details: "Lorep ipsum",
      coins_used: "50 coins",
      active: true,
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      payment: "Paypal",
      amount: "$50",
      details: "Lorep ipsum",
      coins_used: "50 coins",
      active: true,
    },
    {
      id: "25",
      name: "C . Name",
      date: "26/12/2008",
      payment: "Paypal",
      amount: "$50",
      details: "Lorep ipsum",
      coins_used: "50 coins",
      active: true,
    },
  ];
  return (
    <div>
      <MainHeader text={"Payment"} />
      <MainContainer>
        <h2 className="tw-text-xl tw-text-main tw-font-normal work">
          Payments
        </h2>
        <div className="tw-mt-5 tw-w-80">
          <SelectInput label={"Choose Category"}>
            <option value="monthly">Monthly</option>
            <option value="monthly">Monthly</option>
            <option value="monthly">Monthly</option>
          </SelectInput>
        </div>
        <div className="tw-mt-10">
          <PaymentTable data={tableData} headers={Headers} />
        </div>
      </MainContainer>
    </div>
  );
};

export default Payment;
