// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/Poppins-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets//fonts/WorkSans-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../src/assets/fonts/ClashDisplay-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../src/assets/fonts/Nunito-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../src/assets/fonts/Akira.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../src/assets/fonts/Sansation_Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../src/assets/fonts/Segoe UI.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../src/assets/fonts/Mulish-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../src/assets/fonts/Krub-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./assets/fonts/Lato-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./assets/fonts/K2D.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Poppins;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: Work Sans;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: Clash;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: Nunito;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
  font-family: Akira;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
  font-family: Sansation;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
  font-family: Segoe UI;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
@font-face {
  font-family: Mulish;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}
@font-face {
  font-family: Krub;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}
@font-face {
  font-family: Lato;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}
@font-face {
  font-family: K2D;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
}
`, "",{"version":3,"sources":["webpack://./src/typography.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,4CAAmD;AACrD;AACA;EACE,sBAAsB;EACtB,4CAA0D;AAC5D;AACA;EACE,kBAAkB;EAClB,4CAAwD;AAC1D;AACA;EACE,mBAAmB;EACnB,4CAAkD;AACpD;AACA;EACE,kBAAkB;EAClB,4CAAyC;AAC3C;AACA;EACE,sBAAsB;EACtB,4CAAqD;AACvD;AACA;EACE,qBAAqB;EACrB,4CAA6C;AAC/C;AACA;EACE,mBAAmB;EACnB,4CAA4D;AAC9D;AACA;EACE,iBAAiB;EACjB,4CAAiD;AACnD;AACA;EACE,iBAAiB;EACjB,4CAA2C;AAC7C;AACA;EACE,gBAAgB;EAChB,6CAAkC;AACpC","sourcesContent":["@font-face {\n  font-family: Poppins;\n  src: url(\"../src/assets/fonts/Poppins-Regular.otf\");\n}\n@font-face {\n  font-family: Work Sans;\n  src: url(\"./assets//fonts/WorkSans-VariableFont_wght.ttf\");\n}\n@font-face {\n  font-family: Clash;\n  src: url(\"../src/assets/fonts/ClashDisplay-Regular.otf\");\n}\n@font-face {\n  font-family: Nunito;\n  src: url(\"../src/assets/fonts/Nunito-Regular.ttf\");\n}\n@font-face {\n  font-family: Akira;\n  src: url(\"../src/assets/fonts/Akira.otf\");\n}\n@font-face {\n  font-family: Sansation;\n  src: url(\"../src/assets/fonts/Sansation_Regular.ttf\");\n}\n@font-face {\n  font-family: Segoe UI;\n  src: url(\"../src/assets/fonts/Segoe\\ UI.ttf\");\n}\n@font-face {\n  font-family: Mulish;\n  src: url(\"../src/assets/fonts/Mulish-VariableFont_wght.ttf\");\n}\n@font-face {\n  font-family: Krub;\n  src: url(\"../src/assets/fonts/Krub-Regular.woff\");\n}\n@font-face {\n  font-family: Lato;\n  src: url(\"./assets/fonts/Lato-Regular.ttf\");\n}\n@font-face {\n  font-family: K2D;\n  src: url(\"./assets/fonts/K2D.ttf\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
