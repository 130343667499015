/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import Dashboardactive from "../assets/sidebar-icons/dashboard-active.svg";
import Dashboard from "../assets/sidebar-icons/dashboard.svg";
import QuizZone from "../assets/sidebar-icons/quiz-zone.svg";
import Dailyquiz from "../assets/sidebar-icons/daily-quiz.svg";
import DailyquizActive from "../assets/sidebar-icons/daily-quiz-active.svg";
import Matchquiz from "../assets/sidebar-icons/math-quiz.svg";
import MathQuizActive from "../assets/sidebar-icons/math-quiz-active.svg";
import ExanModeule from "../assets/sidebar-icons/exam-module.svg";
import ExamModuleActive from "../assets/sidebar-icons/exam-module-active.svg";
import Guess from "../assets/sidebar-icons/guess-word.svg";
import GuessActive from "../assets/sidebar-icons/guess-active.svg";
import Fun from "../assets/sidebar-icons/fun.svg";
import FunActive from "../assets/sidebar-icons/fun-active.svg";
import QuizActive from "../assets/sidebar-icons/quiz-active.svg";
import Audio from "../assets/sidebar-icons/audio.svg";
import AudioActive from "../assets/sidebar-icons/audio-active.svg";
import Contest from "../assets/sidebar-icons/contest.svg";
import ContestActive from "../assets/sidebar-icons/contest-active.svg";
import Payment from "../assets/sidebar-icons/payments.svg";
import PaymentActive from "../assets/sidebar-icons/payment-active.svg";
import Users from "../assets/sidebar-icons/users.svg";
import UsersActive from "../assets/sidebar-icons/user-active.svg";
import Activity from "../assets/sidebar-icons/activities.svg";
import ActivityActive from "../assets/sidebar-icons/activity-active.svg";
import Leader from "../assets/sidebar-icons/leaderboard.svg";
import LeaderActive from "../assets/sidebar-icons/leaderboard-active.svg";
import Notification from "../assets/sidebar-icons/notification.svg";
import NotificationActive from "../assets/sidebar-icons/notification-active.svg";
// import Setting from "../assets/settings.svg";

// import Logout from "../assets/sidebar-icons/logout.svg";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
  const [nav, setNav] = useState(false);
  let handleCapitalize = (word) => {
    let splitter = word.trim().split(" ");
    let firstCap = splitter[0].split("");
    let remaining = splitter.slice(1, splitter.length).join(" ");

    let firstCapOne = firstCap[0].toUpperCase();
    let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

    let joinFirst = `${firstCapOne}${firstCapTwo}`;

    return `${joinFirst} ${remaining}`;
  };

  let numberWithCommas = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
  };

	let convertCamelCase = str => {
		const result = str.replace(/([A-Z])/g, " $1");
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

		return finalResult;
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let { auth } = useSelector(state => state);

	let SettingList = [
		{
			name: "Sliders",
			url: "/sliders",
			modal: false,
		},
		{
			name: "welcome bonus",
			url: "/welcome-bonus",
			modal: true,
			modal_name: "welcome-bonus",
		},
		{
			name: "icons settings",
			url: "/icons-settings",
			modal: false,
		},
		{
			name: "payment settings",
			url: "/payment-settings",
			modal: false,
		},
		{
			name: "badge settings",
			url: "/badge-settings",
			modal: false,
		},
		{
			name: "language settings",
			url: "/language-settings",
			modal: false,
		},
		{
			name: "system utilities",
			url: "/system-utilities",
			modal: false,
		},
		{
			name: "users rights",
			url: "/users-rights",
			modal: false,
		},
		{
			name: "About us",
			url: "/about-us",
			modal: false,
		},
		{
			name: "contact us",
			url: "/contact-us",
			modal: false,
		},
		{
			name: "privacy policy",
			url: "/privacy-policy",
			modal: false,
		},
		{
			name: "how to play",
			url: "/howtoplay",
			modal: false,
		},
		{
			name: "terms",
			url: "/terms",
			modal: false,
		},
		{
			name: "Faq",
			url: "/faq",
			modal: false,
		},
	];

	let sidebarList = [
		{
			name: "Overview",
			url: "/overview",
			img: Dashboard,
			activeImg: Dashboardactive,
		},
		{
			name: "Quiz Zone",
			url: "/quiz-zone",
			img: QuizZone,
			activeImg: QuizActive,
		},
		{
			name: "Daily Quiz",
			url: "/daily-quiz",
			img: Dailyquiz,
			activeImg: DailyquizActive,
		},
		{
			name: "Maths Quiz",
			url: "/math-quiz",
			img: Matchquiz,
			activeImg: MathQuizActive,
		},
		{
			name: "Exam Module",
			url: "/exam-module",
			img: ExanModeule,
			activeImg: ExamModuleActive,
		},
		{
			name: "guess the word",
			url: "/guess-the-word",
			img: Guess,
			activeImg: GuessActive,
		},
		{
			name: "fun n learn",
			url: "/fun",
			img: Fun,
			activeImg: FunActive,
		},
		{
			name: "Audio question",
			url: "/audio-question",
			img: Audio,
			activeImg: AudioActive,
		},
		{
			name: "Contests",
			url: "/contest",
			img: Contest,
			activeImg: ContestActive,
		},
		{
			name: "Question Bank",
			url: "/question-bank",
			img: Dailyquiz,
			activeImg: DailyquizActive,
		},
		{
			name: "payments",
			url: "/payment",
			img: Payment,
			activeImg: PaymentActive,
		},

		{
			name: "Users",
			url: "/users",
			img: Users,
			activeImg: UsersActive,
		},
		{
			name: "activities",
			url: "/activities",
			img: Activity,
			activeImg: ActivityActive,
		},
		{
			name: "leaderboard",
			url: "/leaderboard",
			img: Leader,
			activeImg: LeaderActive,
		},
		{
			name: "Notifications",
			url: "/notifications",
			img: Notification,
			activeImg: NotificationActive,
		},

		// {
		//   name: "settings",
		//   url: "/settings",
		//   img: Setting,
		//   activeImg: "",
		// },
		// {
		//   name: "Logout",
		//   img: Logout,
		//   activeImg: "",
		// },
	];

	if (auth?.user?.createdBy?.type !== "admin")
		sidebarList = [
			...sidebarList,
			{
				name: "Admins",
				url: "/admin",
				img: Users,
				activeImg: UsersActive,
			},
		];

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth,
		nav,
		toggleNav,
		SettingList,
		convertCamelCase,
	};

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
