import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";

import Index from "./pages/index";
import Sidebar from "./components/sidebar/sidebar";

import { useSelector } from "react-redux";

import Home from "./screens/home";

const Routers = () => {
	let { auth } = useSelector(state => state);

	return (
		<>
			<ToastContainer position="bottom-center" />
			<Sidebar>
				<Routes>
					<Route path="/" element={auth?.isAuth ? <Index /> : <Home />} />
					<Route path="/:page" element={<PageRender />} />
					<Route path="/:page/:id" element={<PageRender />} />
					<Route path="/:page/:id/:step" element={<PageRender />} />
				</Routes>
			</Sidebar>
		</>
	);
};

export default Routers;
