import React from "react";

const ModalBtn = ({ text, onClick }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className="tw-w-full btngradient tw-rounded-md tw-h-9 tw-font-semibold segoe tw-text-base tw-text-white"
      >
        {text}
      </button>
    </div>
  );
};

export default ModalBtn;
