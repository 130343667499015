import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Settings = () => {
//   let navigate = useNavigate();

//   useEffect(() => {
//     navigate("/settin");
//   }, [navigate]);
  return <></>;
};

export default Settings;
