import React, { useEffect } from "react";
import { MainUsers } from "../users";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Admin = () => {
	let { auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (auth?.user?.createdBy?.type === "admin") navigate(-1);
	}, [auth?.user?.createdBy?.type, navigate]);

	return <MainUsers admin={{ name: "Admin", url: "/admin" }} />;
};

export default Admin;
